import SceneObject from '../SceneObject/SceneObject';
import PlaybackQueue from './PlaybackQueue/PlaybackQueue';
import initModel from './lib/initModel';
import Skeleton from './lib/Skeleton/Skeleton';

export default class Character extends SceneObject {
  /**
   * Character class which extends SceneObject class. Has a skeleton which is configured with
   * sgcomAnimationNodes, a playbackQueue which contains and processes animation data,
   * a virtual audio player to handle audio and audio animation synchronization code.
   * @param {Object} params Character constructor params.
   * @param {Object} params.sceneObjectParams Object passed to SceneObject base class.
   * @param {Object} params.sgcomAnimationNodes Object passed to skeleton for configuration.
   * @param {Object} params.playbackQueueParams Object passed to playbackQueue constructor.
   * @param {Object} params.logger Global logger.
   */
  constructor({ sceneObjectParams, sgcomAnimationNodes, playbackQueueParams, logger }) {
    super(sceneObjectParams);

    this.sgcomAnimationNodes = sgcomAnimationNodes || [];

    this.playbackQueue = new PlaybackQueue({ ...playbackQueueParams, logger });

    this.logger = logger;
  }

  playbackQueue = null;

  skeleton = null;

  logger = null;

  async init() {
    const { model } = this;

    initModel(model);

    await this.playbackQueue.init();
  }

  async initSkeleton({ canvas, activeCamera }) {
    const { model, sgcomAnimationNodes, logger } = this;

    this.skeleton = new Skeleton({
      scene: model.scene,
      canvas,
      activeCamera,
      animationNodes: sgcomAnimationNodes,
      logger,
    });

    await this.skeleton.init();
  }

  setModelLookAt(params) {
    this.skeleton.setModelLookAt(params);
  }

  updateAnimation(frameTime) {
    const { playbackQueue, skeleton, animations } = this;

    // Get next sgcom animation data to play.
    // Returns null if no new sgcom animation available for the current frame.
    const animationData = playbackQueue.getAnimationData();

    // Reset skeleton to previous model animation keyframe bone positions.
    // This is a fix for handling bone position conflicts with sgcom data.
    skeleton.resetTargetJointsToCachedPose();

    // Update model built in animation to current keyframe.
    animations.update(frameTime);

    // Save current bone positions without sgcom skeleton position overwrites.
    skeleton.cacheTargetJoints();

    // Update skeleton with sgcom animations if applicable for the current frame.
    if (animationData) {
      skeleton.update(animationData);
    }
  }
}
