// This is the js equivalent of thee SG_AnimationNode struct

class AnimationNode {
  constructor() {
    this.name = '';
    this.type = ''; // Can be JOINT or BLENDSHAPE
    this.channelNames = []; // Array of strings
    this.channelValues = {}; // Float32Array
    this.morphTargetNameArray = []; // Array of strings. Only valid for BLENDSHAPE
  }

  clone() {
    const other = new this.constructor();
    other.name = this.name;
    other.type = this.type;
    other.channelNames = Array.from(this.channelNames);
    other.channelValues = Array.from(this.channelValues);
    other.morphTargetNameArray = Array.from(this.morphTargetNameArray);

    return other;
  }
}

export default AnimationNode;
