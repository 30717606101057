/*
  * The minimum audio level permitted.
*/
const MIN_AUDIO_LEVEL = -127;

/*
* The maximum audio level permitted.
*/
const MAX_AUDIO_LEVEL = 0;

/**
 * convert RMS to DB
 * @param {int} intRMS Audio RMS value from server
 * @return {int} convert to -DB + 127
 */
export default function convertRmsToDb(intRMS) {
  let db = -127;
  const rms = intRMS / 32768; // normalized rms
  if (rms > 0) {
    /*
    * The "zero" reference level is the overload level,
    * which corresponds to 1.0 in this calculation, because
    * the samples are normalized in calculating the RMS.
    */
    db = 20 * Math.log10(rms);
    /*
    * Ensure that the calculated level is within the minimum
    * and maximum range permitted.
    */
    if (db < MIN_AUDIO_LEVEL + 40) {
      db = MIN_AUDIO_LEVEL;
    } else if (db > MAX_AUDIO_LEVEL) {
      db = MAX_AUDIO_LEVEL;
    }
  } else {
    db = MIN_AUDIO_LEVEL;
  }

  return parseInt(Math.round(db), 10) + 127;
}
