import ModuleCommands from './ModuleCommands';

/**
 * Create RRMS api related modules
 * @param {object} moduleArgs Contain module params.
 * @param {string} moduleArgs.data Contains module related data.
 * @param {object} moduleArgs.params Params to pass to modules.
 * @returns {object} Contains modules.
 */
export default function createApiModules({ data, ...params }) {
  return {
    commands: new ModuleCommands({
      data: data.commands,
      ...params,
    }),
  };
}
