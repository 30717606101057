/* eslint-disable spellcheck/spell-checker */
import logo from '../../static/images/svgs/logo.svg?raw';

const MAX_WIDTH = 300;

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
};

/**
 * Rapport Logo
 *
 * Handle svg logo on click and visibility
 * within the scene
 */
class RapportLogo {
  constructor(params) {
    this.logo = params.logo;
    this.container = params.container;

    this.init();
  }

  init() {
    const handleClick = () => window.open('https://www.rapport.cloud/', '_blank');

    // handle custom callBack on click or by default on click open a new tab with rapport.cloud
    this.logo.onclick = handleClick;
    this.logo.innerHTML = logo;
    this.hide();
  }

  show() {
    this.visible = true;
    this.logo.style.display = 'block';
    this.resize();
  }

  hide() {
    this.visible = false;
    this.logo.style.display = 'none';
  }

  /**
   * Manage logo responsiveness based on the container size
   */
  resize() {
    const shouldResize = this.container && this.visible;
    if (shouldResize) {
      const { clientWidth } = this.container;
      const { sm, md, lg } = breakpoints;
      let width = 0;
      switch (true) {
        case clientWidth <= sm:
          width = clientWidth * 0.45;
          break;
        case clientWidth <= md || clientWidth < lg: {
          width = clientWidth * 0.4 <= MAX_WIDTH ? clientWidth * 0.4 : MAX_WIDTH;
          break;
        }
        case clientWidth >= lg: {
          width = clientWidth * 0.3 <= MAX_WIDTH ? clientWidth * 0.3 : MAX_WIDTH;
          break;
        }
        default:
      }

      // set height and width to account for margins
      const height = width / 5.12 + 8;
      this.logo.style.height = `${height}px`;
      this.logo.style.width = `${height * 5.12}px`;
    }
  }
}

export default RapportLogo;
