export default class UpxsAnimationController {
  /**
   * Contains methods for the integrator to initiate UPXS animation requests.
   * Instantiated with animation clip name list provided by CPI.
   * Callback onAnimationRequest is called with animation request.
   * @param {Object} params Contain animation controller parameters, and callback.
   * @param {Array} params.clipNames List of animation clip names.
   * @param {Function} params.onAnimationRequest Callback called with animation request.
   */
  constructor(params) {
    Object.assign(this, params);
  }

  clipNames = [];

  onAnimationRequest = null;

  get() {
    return this.clipNames;
  }

  play(name, loop = false, speed = 1) {
    this.emitAnimationRequest({
      type: 'animation',
      method: 'play',
      args: {
        name,
        loop,
        speed,
      },
    });
  }

  emitAnimationRequest(animationRequest) {
    this.onAnimationRequest(animationRequest);
  }
}
