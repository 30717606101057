import SignalingConnection from './lib/SignalingConnection';
import RtcConnection from './lib/RtcConnection';

export default class NetworkUpxs {
  /**
   * Create Signaling and RTC connection for Unreal Pixel Streaming.
   * Signaling connection is for SDP and ICE candidate exchange between RWV and
   * Unreal pixel streamer.
   * RTC connection is for one direction video and audio transport from Unreal pixel streamer.
   * Callback onStreams is called with "streams" object containing "audioStream" and "videoStream".
   * Callback onError is called with "error" object.
   * Error has "type" prop with enum of "signaling" or "rtc".
   * @param {Object} params Contain signaling server parameters, and callbacks.
   * @param {Object} params.signalingParams Signaling server parameters containing url and jwt.
   * @param {string} params.signalingParams.url Signaling server url.
   * @param {string} params.signalingParams.jwt Signaling server jwt.
   * @param {Function} params.onStreams Callback called with streams object.
   * @param {Function} params.onError Callback called with error object.
   * @param {Function} params.onWarn Callback called with warn object.
   */
  constructor(params) {
    Object.assign(this, params);

    this.connect();
  }

  connected = false;

  signalingConnection = null;

  rtcConnection = null;

  connect() {
    this.createSignalingConnection();
    this.createRtcConnection();
  }

  // Signaling connection handlers
  createSignalingConnection() {
    const { url, jwt } = this.signalingParams;

    this.signalingConnection = new SignalingConnection({
      url,
      jwt,
      onMessage: (e) => this.handleSignalingMessage(e),
      onError: (error) => {
        const { connected } = this;

        // Do not emit errors after the session start.
        if (!connected) {
          this.emitError(error);
        } else {
          this.emitWarn(error);
        }
      },
    });
  }

  handleSignalingMessage(e) {
    const msg = JSON.parse(e.data);

    if (msg.type === 'offer') {
      this.addOffer(msg);
    } else if (msg.type === 'iceCandidate') {
      this.addCandidate(msg.candidate);
    }
  }

  emitError(error) {
    this.onError(error);
  }

  emitWarn(warn) {
    this.onWarn(warn);
  }

  // RTC connection handlers
  createRtcConnection() {
    this.rtcConnection = new RtcConnection({
      onCandidate: (candidate) => this.sendCandidate(candidate),
      onStreams: (streams) => {
        this.connected = true;
        this.emitStreams(streams);
      },
      onAnswer: (answer) => this.sendAnswer(answer),
      onError: (error) => this.emitError(error),
    });
  }

  emitStreams(streams) {
    this.onStreams(streams);
  }

  addOffer(offer) {
    this.rtcConnection.addOffer(offer);
  }

  addCandidate(candidate) {
    this.rtcConnection.addCandidate(candidate);
  }

  sendCandidate(candidate) {
    this.signalingConnection.send({
      type: 'iceCandidate',
      candidate,
    });
  }

  sendAnswer(answer) {
    this.signalingConnection.send(answer);
  }

  disconnect() {
    const { signalingConnection, rtcConnection } = this;

    if (signalingConnection) {
      signalingConnection.disconnect();
    }

    if (rtcConnection) {
      rtcConnection.disconnect();
    }
  }
}
