export default class PushToTalk {
  /**
   * Register push to talk key and check mouse position. The cursor has to be within the
   * Rapport scene to allow push to talk.
   *
   * @param {Object} params Contain ptt parameters.
   * @param {Element} params.container DOM container element where the scene is.
   * @param {string} params.pttKey Keyboard key which activates push to talk.
   * @param {Function} params.onToggle Callback called when ptt toggled.
   */
  constructor(params) {
    Object.assign(this, params);

    this.addEventListeners();
  }

  enabled = false;

  mousePosX = null;

  mousePosY = null;

  containerRestriction = true;

  addEventListeners() {
    globalThis.addEventListener('keydown', this);
    globalThis.addEventListener('keyup', this);
    globalThis.addEventListener('mousemove', this);
  }

  handleEvent(e) {
    switch (e.type) {
      case 'keydown': {
        this.toggle(e, false);
        break;
      }

      case 'keyup': {
        this.toggle(e, true);
        break;
      }

      case 'mousemove': {
        this.mousePosX = e.clientX;
        this.mousePosY = e.clientY;
        break;
      }

      default: {
        break;
      }
    }
  }

  toggle(e, value) {
    const { code } = e;
    const {
      enabled,
      key,
      container,
      containerRestriction,
      mousePosX,
      mousePosY,
    } = this;

    if (!enabled) {
      return;
    }

    if (code !== key) {
      return;
    }

    const pointedElement = globalThis.document.elementFromPoint(mousePosX, mousePosY);

    // Toggle off works outside of the container too.
    if (containerRestriction && (pointedElement !== container && !value)) {
      return;
    }

    e.preventDefault();

    this.onToggle(value);
  }

  dispose() {
    globalThis.removeEventListener('keydown', this);
    globalThis.removeEventListener('keyup', this);
    globalThis.removeEventListener('mousemove', this);
  }
}
