import * as THREE from 'three';
import RapportStatus from '../../RapportStatus';
import LoggingLevel from '../../LoggingLevel';
import mapErrorCode from '../../mapErrorCode';
import ErrorCode from '../../ErrorCode';
import getBoundingBox from './helpers/getBoundingBox';
import getLightCameraViewBox from '../../common/getLightCameraViewBox';
import getBoundingBoxCorners from '../../common/computeBoundingBoxCorners';
import rotateAboutPoint from '../../common/rotateAboutPoint';

// Crud Lighting API

const xAxis = new THREE.Vector3(1, 0, 0);
const yAxis = new THREE.Vector3(0, 1, 0);
const degToRadians = Math.PI / 180;

class LightsFactory {
  // private variables
  #scene;

  #boundingBox;

  #lights;

  #helpers;

  #boundingBoxCenter;

  #boundingBoxCorners;

  #target;

  #defaultPosition;

  logger = null;

  constructor(scene, lights, logger) {
    this.#scene = scene;
    this.#boundingBox = getBoundingBox(scene);
    this.#lights = lights;
    this.logger = logger;
    this.#helpers = {};

    this.#boundingBoxCenter = new THREE.Vector3();
    this.#boundingBox.getCenter(this.#boundingBoxCenter);

    // model doesn't move. Construct bounding box corners.
    this.#boundingBoxCorners = getBoundingBoxCorners(this.#boundingBox);

    const boundingBoxWidth = this.#boundingBox.max.x - this.#boundingBox.min.x;
    const boundingBoxHeight = this.#boundingBox.max.y - this.#boundingBox.min.y;
    const boundingBoxDepth = this.#boundingBox.max.z - this.#boundingBox.min.z;

    // maximum dimension dimension of bounding box
    const boundingBoxMaxDimension = Math.max(boundingBoxWidth, boundingBoxHeight, boundingBoxDepth);

    // directional lights point at center of bounding box
    this.#target = new THREE.Object3D();
    this.#target.position.set(
      this.#boundingBoxCenter.x,
      this.#boundingBoxCenter.y,
      this.#boundingBoxCenter.z,
    );
    this.#scene.add(this.#target);

    // default position, 2 x boundingBoxMaxDimension in front of model bounding box center
    this.#defaultPosition = new THREE.Vector3(
      this.#boundingBoxCenter.x,
      this.#boundingBoxCenter.y,
      this.#boundingBox.max.z + 2 * boundingBoxMaxDimension,
    );
  }

  /**
   * create a light
   * @param {Object} params
   * params.type: 'AmbientLight' || 'DirectionalLight' || 'SpotLight' || 'HemisphereLight'
   * params.color: {THREE.color} // for 'AmbientLight' || 'DirectionalLight' || 'SpotLight'
   * params.intensity: {Number} // all lights
   * params.rotation: {x, y} // for DirectionalLight & SpotLight, x [0: 360], y [-90: 90]
   * params.distance: { Number } // Three.js SpotLight distance
   * params.angle: { Number } // Three.js SpotLight angle
   * params.penumbra: { Number } Three.js SpotLight penumbra
   * params.decay: { Number } // Three.js SpotLight decay
   * params.bias: { Number } // for DirectionalLight & SpotLight, Three.js shadow bias
   * params.shadows: {Boolean} // for DirectionalLight & SpotLight, if light casts shadows
   * params.shadowMapSize: {Width, Height} // for Directional light & SpotLight Width and Height
   * must be power of 2up to WebGLRenderer.capabilities.maxTextureSize
   * eg 124 || 256 || 512 || 1024 || 2048 || 4096
   * debug: enable debugging of DirectionalLight, SpotLight and HemisphereLight
   * skyColor: {THREE.color} of top half light of HemisphereLight
   * groundColor: {THREE.color} of bottom half light of HemisphereLight
   * @returns {Light} handle to light
   */
  create(params) {
    const { logger } = this;

    if (params.type === 'AmbientLight') {
      try {
        const ambientLight = new THREE.AmbientLight(params.color, params.intensity);
        ambientLight.name = `AmbientLight-${ambientLight.uuid}`;
        this.#lights.push(ambientLight);
        this.#scene.add(ambientLight);
        return { ...ambientLight };
      } catch (error) {
        logger.error(new Error('LightsFactory | Failed to create AmbientLight.', { cause: error }));
        this.#throwError(ErrorCode.FAILURE_LIGHTS_CREATE);
      }
    }

    if (params.type === 'DirectionalLight') {
      try {
        const directionalLight = new THREE.DirectionalLight('white', 1);
        directionalLight.name = `DirectionalLight-${directionalLight.uuid}`;
        // default light position in front of model
        directionalLight.position.set(
          this.#defaultPosition.x,
          this.#defaultPosition.y,
          this.#defaultPosition.z,
        );

        directionalLight.target = this.#target;

        // THREE default mapSize 512, which is grainy, needs to be power of 2
        // demo https://sbcode.net/threejs/directional-light-shadow/
        // https://threejs.org/docs/?q=mapsize#api/en/lights/shadows/LightShadow.mapSize
        directionalLight.shadow.mapSize.width = 1024;
        directionalLight.shadow.mapSize.height = 1024;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 500;

        if (params.bias !== undefined) {
          directionalLight.shadow.bias = params.bias;
        }

        this.#lights.push(directionalLight);
        this.#scene.add(directionalLight);

        const directionalHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
        this.#helpers[directionalLight.uuid] = directionalHelper;
        this.#scene.add(this.#helpers[directionalLight.uuid]);
        // hide object if debug is not true
        this.update(
          directionalLight,
          {
            color: params.color,
            intensity: params.intensity,
            rotation: params.rotation,
            // shadows true unless set to false
            shadows: params.shadows,
            shadowMapSize: params.shadowMapSize,
            debug: params.debug,
          },
        );
        return { ...directionalLight };
      } catch (error) {
        logger.error(new Error('LightsFactory | Failed to create DirectionalLight.', { cause: error }));
        this.#throwError(ErrorCode.FAILURE_LIGHTS_CREATE);
      }
    }

    if (params.type === 'SpotLight') {
      try {
        const spotLight = new THREE.SpotLight('white');
        spotLight.name = `SpotLight-${spotLight.uuid}`;
        spotLight.position.set(
          this.#defaultPosition.x,
          this.#defaultPosition.y,
          this.#defaultPosition.z,
        );

        spotLight.target = this.#target;

        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;
        spotLight.shadow.camera.near = 0.1;
        spotLight.shadow.camera.far = 500;

        if (params.bias !== undefined) {
          spotLight.shadow.bias = params.bias;
        }

        this.#lights.push(spotLight);
        this.#scene.add(spotLight);

        const spotLightHelper = new THREE.SpotLightHelper(spotLight);
        this.#helpers[spotLight.uuid] = spotLightHelper;
        this.#scene.add(this.#helpers[spotLight.uuid]);
        this.update(
          spotLight,
          {
            color: params.color,
            intensity: params.intensity,
            distance: params.distance,
            angle: params.angle,
            penumbra: params.penumbra,
            decay: params.decay,
            rotation: params.rotation,
            shadows: params.shadows,
            shadowMapSize: params.shadowMapSize,
            debug: params.debug,
          },
        );
        return { ...spotLight };
      } catch (error) {
        logger.error(new Error('LightsFactory | Failed to create SpotLight.', { cause: error }));
        this.#throwError(ErrorCode.FAILURE_LIGHTS_CREATE);
      }
    }

    if (params.type === 'HemisphereLight') {
      try {
        const hemisphereLight = new THREE.HemisphereLight('white', 'white', 1);
        hemisphereLight.name = `HemisphereLight-${hemisphereLight.uuid}`;

        this.#lights.push(hemisphereLight);
        this.#scene.add(hemisphereLight);

        const hemisphereHelper = new THREE.HemisphereLightHelper(hemisphereLight);
        this.#helpers[hemisphereLight.uuid] = hemisphereHelper;
        this.#scene.add(this.#helpers[hemisphereLight.uuid]);
        // hide object if debug is not true
        this.update(
          hemisphereLight,
          {
            skyColor: params.skyColor,
            groundColor: params.groundColor,
            intensity: params.intensity,
            debug: params.debug,
          },
        );
        return { ...hemisphereLight };
      } catch (error) {
        logger.error(new Error('LightsFactory | Failed to create HemisphereLight.', { cause: error }));
        this.#throwError(ErrorCode.FAILURE_LIGHTS_CREATE);
      }
    }
    return null;
  }

  /**
   * Read Lights
   * @returns [list of lights]
   */
  read() {
    return [...this.#lights]; // copy of lights
  }

  /**
   * update a light
   * @param {Light} lightObj to be updated
   * @param {Object} params to update light with
   * params.color: {THREE.color} // for 'AmbientLight' || 'DirectionalLight' || 'SpotLight'
   * params.intensity: {Number} // all lights
   * params.rotation: {x, y} // for DirectionalLight & SpotLight, x [0: 360], y [-90: 90]
   * params.distance: { Number } // Three.js SpotLight distance
   * params.angle: { Number } // Three.js SpotLight angle
   * params.penumbra: { Number } Three.js SpotLight penumbra
   * params.decay: { Number } // Three.js SpotLight decay
   * params.shadows: {Boolean} // for DirectionalLight & SpotLight, if light casts shadows
   * params.shadowMapSize: {Width, Height} // for Directional light & SpotLight Width and Height
   * must be power of 2up to WebGLRenderer.capabilities.maxTextureSize
   * eg 124 || 256 || 512 || 1024 || 2048 || 4096
   * debug: enable debugging of DirectionalLight, SpotLight and HemisphereLight
   * skyColor: {THREE.color} of top half light of HemisphereLight
   * groundColor: {THREE.color} of bottom half light of HemisphereLight
   */
  update(lightObj, params) {
    const { logger } = this;

    // lightObj = index of light or light Object to be updated
    // note index could change if an light created before gets deleted
    const index = this.#lights.map((x) => x.uuid).indexOf(lightObj.uuid);
    try {
      if (this.#lights[index]) {
        const light = this.#lights[index];
        if (params.intensity) {
          light.intensity = params.intensity;
        }
        if (params.color) {
          light.color = new THREE.Color(params.color);
        }
        if (params.skyColor) {
          // hemisphereLight.color is skyColor
          light.color = new THREE.Color(params.skyColor);
        }
        if (params.groundColor && light.type === 'HemisphereLight') {
          light.groundColor = new THREE.Color(params.groundColor);
        }
        if (params.rotation) {
          this.#updateLightRotation(index, params.rotation);
        }
        if (params.shadowMapSize) {
          light.shadow.mapSize.width = params.shadowMapSize;
          light.shadow.mapSize.height = params.shadowMapSize;
        }
        if (params.distance) {
          light.distance = params.distance;
        }
        if (params.angle) {
          light.angle = params.angle;
        }
        if (params.penumbra) {
          light.penumbra = params.penumbra;
        }
        if (params.decay) {
          light.decay = params.decay;
        }

        this.#updateEnableShadow(index, !!(params.shadows !== false));
        // update light camera and update helper
        if (this.#helpers[light.uuid]) {
          this.#helpers[light.uuid].visible = !!(params.debug === true && (params.shadows === true || light.type === 'HemisphereLight'));
          this.#helpers[light.uuid].update();
        }
      }
    } catch (error) {
      logger.error(new Error('LightsFactory | Failed to update light.', { cause: error }));
      this.#throwError(ErrorCode.FAILURE_LIGHTS_UPDATE);
    }
  }

  /**
   * Delete all lights, or delete the light object provided based on its internal uuid
   * @param {Light} lightObj to be deleted, if no light given all scene lights deleted
   */
  delete(lightObj = null) {
    const { logger } = this;

    if (lightObj === null) {
      const lights = [...this.#lights];
      lights.forEach((element) => {
        this.delete(element);
      });
      return;
    }

    const index = this.#lights.map((x) => x.uuid).indexOf(lightObj.uuid);

    try {
      if (this.#lights[index]) {
        const light = this.#lights[index];
        delete this.#helpers[light.uuid];
        const sceneLight = this.#scene.getObjectByName(light.name);
        sceneLight.removeFromParent();
        this.#lights.splice(index, 1);
      } else {
        this.#throwError(ErrorCode.FAILURE_LIGHTS_DELETE);
      }
    } catch (error) {
      logger.error(new Error('LightsFactory | Failed to delete light.', { cause: error }));
      this.#throwError(ErrorCode.FAILURE_LIGHTS_DELETE);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #throwError(errorCode) {
    const errorMessage = new RapportStatus(LoggingLevel.ERROR, mapErrorCode(errorCode));
    throw errorMessage.toAPIDispatchObj().msg;
  }

  // update the defaultPosition of a light based on a rotation about x and y axis
  // index - to light to have rotation applied
  // rotation {x, y} - rotations for each axis in degrees
  #updateLightRotation(index, rotation) {
    const light = this.#lights[index];
    light.position.set(
      this.#defaultPosition.x,
      this.#defaultPosition.y,
      this.#defaultPosition.z,
    );
    if (rotation.x) {
      rotateAboutPoint(light, this.#boundingBoxCenter, xAxis, rotation.x * degToRadians);
    }
    if (rotation.y) {
      rotateAboutPoint(light, this.#boundingBoxCenter, yAxis, rotation.y * degToRadians);
    }

    // create yAxis up plane through center of bounding box and light
    const lightBoxVector = new THREE.Vector3().subVectors(light.position, this.#boundingBoxCenter);
    // plane normal
    const yNormal = new THREE.Vector3().crossVectors(lightBoxVector, yAxis).normalize();
    // plane offset
    const yAxisDot = yNormal.dot(this.#boundingBoxCenter);
    const yPlane = new THREE.Plane(yNormal, -yAxisDot);

    // create perpendicular plane through Light and center of bounding box
    // plane normal
    const xNormal = new THREE.Vector3().crossVectors(lightBoxVector, yPlane.normal).normalize();
    // // plane offset
    const xDot = xNormal.dot(this.#boundingBoxCenter);
    const xPlane = new THREE.Plane(xNormal, -xDot);

    const shadowDimensions = getLightCameraViewBox(xPlane, yPlane, this.#boundingBoxCorners);

    light.shadow.camera.left = shadowDimensions.left;
    light.shadow.camera.right = shadowDimensions.right;
    light.shadow.camera.top = shadowDimensions.top;
    light.shadow.camera.bottom = shadowDimensions.bottom;
    light.shadow.camera.updateProjectionMatrix();
  }

  // enable disable a lights shadows
  // index - to light to have rotation applied
  // bool - to enable disable shadows
  #updateEnableShadow(index, bool) {
    const light = this.#lights[index];
    if (light.type === 'DirectionalLight') {
      light.castShadow = bool;
    }
  }
}

export default LightsFactory;
