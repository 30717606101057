import wrenchSVG from '../../static/images/svgs/wrench.svg?raw';
import Audio from '../audio/Audio';

/**
 * Audio Config UI
 * Handle changing of audio inputs
 */
class AudioConfig {
  /**
   * @param {div} wrench button for opening audio config
   * @param {div} modal div that pops up with audio config
   */
  constructor(logger, wrench, modal, onAudioConfigFormUpdate) {
    this.logger = logger;
    this.wrench = wrench;
    this.modal = modal;
    this.featureFlags = {
      ptt: true,
    };
    this.audioConfigForm = {
      pttEnabled: false,
      pttKey: null,
    };
    this.tempForm = {
      ...this.audioConfigForm,
    };
    this.onAudioConfigFormUpdate = onAudioConfigFormUpdate;
    this.wrench.innerHTML = wrenchSVG;
    this.wrench.style.display = 'none';

    this.audioForm = document.createElement('div');
    this.audioForm.id = 'audioForm';

    this.enableSetPttKey = false;

    this.updateMicCallback = () => {};

    this.modal.innerHTML = `<!-- Modal content -->
    <div class="modal-content">
      <div class="modal-header">
        Audio settings
      </div>

      <div class="modal-body">
        <div id="audioForm">
          <div class="appFormBox">
            <label class="appFormBoxLabel">
              Microphone
            </label>

            <div>
              <label class="inputLabel">
                Device
              </label>

              <select
                id="audioSource"
                class="audioSelect"
              ></select>
            </div>

            <div class="formMargin">
              <label class="inputLabel">
                Volume
              </label>

              <input
                class="input-range"
                id="micGainRange"
                type="range"
                min="0"
                max="2"
                step="0.01"
              >
            </div>

            <div class="formMargin">
              <label class="inputLabel">
                Noise gate threshold
              </label>

              <input
                id="noiseGateRange"
                type="range"
                min="-100"
                max="100"
              >
            </div>

            <div
              id="pttSection"
              class="formMargin"
            >
              <label class="inputLabel">
                Push to talk key
              </label>

              <div>
                <input
                  id="pttEnabledCheckbox"
                  type="checkbox"
                >

                <label
                  for="pttEnabledCheckbox"
                  class="checkboxLabel"
                >
                  Enabled
                </label>
              </div>

              <button id="pttKey"></button>

              <div
                id="pttKeyInputHint"
                class="inputHint"
              >
                Press any key to key bind push to talk. Press 'Escape' to cancel.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button>
          Discard
        </button>

        <button>
          Save
        </button>
      </div>
    </div>`;

    this.switchTrackCallback = null;

    const selects = this.modal.getElementsByTagName('select');
    [this.audioInputSelect] = selects;
    this.selectors = [this.audioInputSelect];

    const buttons = this.modal.getElementsByTagName('button');
    [this.pttKeyButton, this.discard, this.save] = buttons;

    this.micGainRange = this.modal.querySelector('#micGainRange');
    this.noiseGateRange = this.modal.querySelector('#noiseGateRange');
    this.pttEnabledCheckbox = this.modal.querySelector('#pttEnabledCheckbox');
    this.pttSection = this.modal.querySelector('#pttSection');
    this.pttKeyInputHint = this.modal.querySelector('#pttKeyInputHint');

    this.addEventListeners();
  }

  addEventListeners() {
    this.save.addEventListener('click', () => this.saveHandler());
    this.discard.addEventListener('click', () => this.discardHandler());
    this.wrench.addEventListener('click', () => this.wrenchHandler());
    this.pttKeyButton.addEventListener('keydown', (e) => {
      // Disable button click by space key.
      if (e.code === 'Space') {
        e.preventDefault();
      }
    });
    this.pttKeyButton.addEventListener('click', () => {
      this.enableSetPttKey = true;
      this.pttKeyInputHint.style.visibility = 'visible';
    });
    globalThis.addEventListener('keydown', (e) => this.setPttKey(e));
  }

  saveHandler() {
    const { logger } = this;

    const deviceId = this.modal.querySelector('#audioSource').value;

    try {
      this.updateMicCallback(deviceId);
    } catch (error) {
      logger.error(new Error('AudioConfig | Switch audio track error.', { cause: error }));
    }

    Audio.mic.setGain(this.micGainRange.value, 0);
    Audio.mic.noiseGateThreshold = this.noiseGateRange.value;

    this.audioConfigForm = {
      pttEnabled: this.pttEnabledCheckbox.checked,
      ...this.tempForm,
    };

    this.onAudioConfigFormUpdate(this.audioConfigForm);

    this.close();
  }

  discardHandler() {
    this.close();
  }

  wrenchHandler() {
    // Feature flags
    this.pttSection.style.display = this.featureFlags.ptt ? 'block' : 'none';

    // Handles being called several times to update labels. Preserve values.
    const values = this.selectors.map((select) => select.value);
    this.selectors.forEach((select) => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });

    for (let i = 0; i !== Audio.storedInputDeviceList.length; i += 1) {
      const deviceInfo = Audio.storedInputDeviceList[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${this.audioInputSelect.length + 1}`;
        this.audioInputSelect.appendChild(option);
      }
    }

    // indicate option is default
    if (this.audioInputSelect.length === 0) {
      const option = document.createElement('option');
      option.text = 'default';
      option.selected = true;
      option.disabled = true;
      this.audioInputSelect.append(option);
    }

    this.selectors.forEach((select, selectorIndex) => {
      if (
        Array.prototype.slice.call(select.childNodes).some((n) => n.value === values[selectorIndex])
      ) {
        // eslint-disable-next-line no-param-reassign
        select.value = values[selectorIndex];
      }
    });

    // Setup noise gate settings.
    const { gainNode, noiseGateThreshold } = Audio.mic;
    const { micGainRange, noiseGateRange } = this;

    micGainRange.value = gainNode.gain.value;
    noiseGateRange.value = noiseGateThreshold;

    this.updateNoiseGateRange();

    // Setup push to talk key button.
    this.tempForm = {
      pttKey: this.audioConfigForm.pttKey,
    };
    this.pttEnabledCheckbox.checked = this.audioConfigForm.pttEnabled;
    this.pttKeyButton.innerHTML = this.audioConfigForm.pttKey || 'Click to set';

    this.modal.style.display = 'block';
  }

  toggle = () => {
    const wrenchVisible = this.wrench.style.display === 'block';

    if (wrenchVisible) {
      this.wrench.style.display = 'none';
    } else {
      this.wrench.style.display = 'block';
    }
  };

  close() {
    this.modal.style.display = 'none';
    this.wrench.style.display = 'none';
    this.enableSetPttKey = false;
    this.pttKeyInputHint.style.visibility = 'hidden';
    this.tempForm = {
      pttKey: null,
    };
  }

  updateNoiseGateRange() {
    const { noiseGateRange, wrench } = this;

    if (!Audio.mic) {
      return;
    }

    const noiseGateLevel = Audio.mic.beforeGatedLevel;
    const { min: rangeMin, max: rangeMax } = noiseGateRange;
    const range = rangeMax - rangeMin;
    const correctedStartValue = noiseGateLevel - rangeMin;
    const noiseGateRangePercent = Math.round((correctedStartValue * 100) / range);

    noiseGateRange.style = `background: linear-gradient(90deg, var(--input-range-bg-color) ${noiseGateRangePercent}%, var(--input-range-bg-color-secondary) ${noiseGateRangePercent}%);`;

    // End loop of popup is not visible.
    if (wrench.style.display === 'block') {
      requestAnimationFrame(() => this.updateNoiseGateRange());
    }
  }

  setPttKey({ code }) {
    const { enableSetPttKey, tempForm, pttKeyButton } = this;

    this.pttKeyInputHint.style.visibility = 'hidden';

    if (!enableSetPttKey || code === 'Escape') {
      this.enableSetPttKey = false;
      return;
    }

    pttKeyButton.innerHTML = code;
    tempForm.pttKey = code;
    this.enableSetPttKey = false;
  }

  dispose() {
    this.close();
    this.wrench.style.display = 'none';
    this.audioConfigForm = {
      pttKey: null,
    };
  }
}

export default AudioConfig;
