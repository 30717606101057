export default Object.freeze({
  SUCCESS: Symbol('Success'),
  FAILURE_SETUP_SESSION: Symbol('Setup session failed'),
  FAILURE_JOIN_SESSION: Symbol('Join session failed'),
  FAILURE_MAX_ROOM: Symbol('Reached maximum number of active rooms'),
  FAILURE_GET_MODEL: Symbol('Failed to get character model'),
  SESSION_LIMIT_REACHED: Symbol('Session limit reached'),
  SESSION_CONCURRENCY_LIMIT_REACHED: Symbol('Session concurrency limit reached'),
  FAILURE_CREATE_ROOM: Symbol('Failed to create room'),
  FAILURE_JOIN_ROOM: Symbol('Failed to join room'),
  FAILURE_HEARTBEAT: Symbol('Heartbeat failed'),
  FAILURE_GET_ROOM_INVITE: Symbol('Failed to receive room invitation'),
  NO_MIC_PERMISSION: Symbol('PERMISSION DENIED: Please enable microphone for this page.'),
  NO_HEADPHONES: Symbol('Headphones required to avoid acoustic echo'),
  NO_RUNNING_AUDIO_CONTEXT: Symbol('Audio context can be resumed after page interaction'),
  PC_FAILED: Symbol('Peer connection failed'),
  PC_CLOSED: Symbol('Peer connection closed'),
  PC_DISCONNECTED: Symbol('Peer connection disconnected'),
  TIMEOUT: Symbol('RWV Timeout'),
  INACTIVITY: Symbol('RWV Inactivity Timeout'),
  FAILURE_LOGOUT: Symbol('Failed to log out'),
  FAILURE_LEAVE_LOBBY: Symbol('Failed to leave lobby'),
  FAILURE_LEAVE_ROOM: Symbol('Failed to leave room'),
  FAILURE_MUTE_SPEAKERS: Symbol('Mute / un-mute speaker failed'),
  FAILURE_WEBGL_CONTEXT: Symbol('Unable to start WebGL context'),
  FAILURE_START_RENDERING: Symbol('Failed to start rendering'),
  FAILURE_CREATE_WEBGL_PLAYER: Symbol('Failed to create WebGL player'),
  FAILURE_ADD_EFFECTS: Symbol('Unable to add effects to renderer'),
  FAILURE_NOT_CONNECTED: Symbol('Rapport session not started'),
  NOT_A_NUMBER: Symbol('Not a number'),
  NOT_A_STRING: Symbol('Not a string'),
  NOT_A_POSITION: Symbol('Not a position'),
  NOT_A_2D_POSITION: Symbol('Not a 2D position'),
  NOT_A_2D_SIZE: Symbol('Not a 2D size'),
  MAX_TIMEOUT_EXCEEDED: Symbol('Timeout attribute greater than cpi timeout'),
  FAILURE_IAM_ROLE: Symbol('IAM Role not authorized'),
  FAILURE_MALFORMED_SSML: Symbol('SSML is malformed'),
  CPI_UNKNOWN_ERROR: Symbol('An unknown CPI error has occurred'),
  CPI_MAX_SESSION_TIMEOUT: Symbol('CPI has timed out on max session duration'),
  CPI_ROOM_DELETED: Symbol('CPI has deleted the room'),
  FAILURE_BOT_KEY: Symbol('CPI has unauthorized Botkey'),
  CPI_SET_AI_SESSION_FAILURE: Symbol('Unable to set ai session id'),
  NO_WEBRTC_ANSWER: Symbol('No WebRTC answer from CPI'),
  CPI_UNKNOWN_SOURCE_MODULE: Symbol('Unknown SourceCPIM'),
  FAILURE_LIGHTS_CREATE: Symbol('Failed to create light'),
  FAILURE_LIGHTS_READ: Symbol('Failed to read light'),
  FAILURE_LIGHTS_UPDATE: Symbol('Failed to update light'),
  FAILURE_LIGHTS_DELETE: Symbol('Failed to delete light'),
  WASM_CACHING_FAILURE: Symbol('Unable to fetch wasm file'),
  FAILURE_PARSE_STRING: Symbol('Failure to parse string into JSON'),
  FAILURE_SESSION_IN_PROGRESS: Symbol('Failure session in progress'),
  FAILURE_SESSION_DISCONNECTED: Symbol('Failure session has been disconnected'),
  UPXS_SIGNALING_CLOSED: Symbol('UPXS signaling connection closed'),
  UPXS_RTC_CLOSED: Symbol('UPXS RTC connection closed'),
  UPXS_RENDERING_FAILURE: Symbol('UPXS rendering initialization failed'),
  MEETING_SESSION_START_FAILURE: Symbol('Meeting session start failed'),
  MEETING_ROOM_SYNC_FAILURE: Symbol('Meeting room synchronization failed'),
  INVALID_SESSION_TYPE: Symbol('Invalid session type'),
});
