export const RapportSceneStatus = Object.freeze({
  LOADING_START: 'Start',
  LOADING_DONE: 'Connected',
  DISCONNECTED: 'Disconnected',
  CONNECTING_TO_DEV_SERVER: 'connecting to dev server',
  SETTING_UP_SESSION: 'Setting up session',
  CREATING_ROOM: 'Creating room',
  ROOM_CREATED: 'Room created',
  DOWNLOADING_MODEL: 'Downloading model',
  RECEIVE_MODEL: 'Received model',
  CACHED_MODEL: 'Received model from cache',
  RECEIVED_INVITE: 'Received Invite',
  JOINING_ROOM: 'Joining room',
  RESIZE: 'resize',
  TTS_START: 'TTS Start',
  TTS_END: 'TTS End',
  RENDERING: 'Rendering',
  USERS_CHANGED: 'Users changed',
  MESHES_INTERSECTED: 'Meshes intersected',
});

export const isValidSceneStatus = (status) => {
  const isValid = Object.keys(RapportSceneStatus).find((key) => RapportSceneStatus[key] === status);
  if (isValid) return true;
  return false;
};
