import { OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect';

const effectList = {
  outline(renderer) {
    return new OutlineEffect(renderer, {
      defaultThickness: 0.002,
      defaultColor: [0, 0, 0],
      defaultAlpha: 1,
      defaultKeepAlive: true,
    });
  },
  outlineThick(renderer) {
    return new OutlineEffect(renderer, {
      defaultThickness: 0.004,
      defaultColor: [0, 0, 0],
      defaultAlpha: 1,
      defaultKeepAlive: true,
    });
  },
};

/**
 * Add effects defined in the rendererEffects to base renderer.
 * @param {Object} renderer Base renderer.
 * @param {Object[]} rendererEffects Effects to add to base renderer.
 * @param {string} rendererEffects[].name The name of the effect to add.
 * @param {boolean} rendererEffects[].enabled Allow add the effect to renderer.
 * @param {number} [rendererEffects[].priority=0] Order of the effect to be applied to the renderer.
 * @returns {(Object|null)} Renderer with effects or null if rendererEffects has no entries.
 */
export default function addRendererEffects(renderer, rendererEffects) {
  if (!Array.isArray(rendererEffects) || !rendererEffects.length) {
    return null;
  }

  let rendererWithEffects = renderer;

  rendererEffects
    .sort((a, b) => (b.priority || 0) - (a.priority || 0))
    .forEach(({ name, enabled }) => {
      if (enabled) {
        rendererWithEffects = effectList[name](rendererWithEffects);
      }
    });

  return rendererWithEffects;
}
