import Module from './Module';
import MethodNames from '../utils/MethodNames';
import ErrorCode from '../ErrorCode';

export default class AiModule extends Module {
  /**
   * Send text the the AI
   * @param {String} text to be sent to AI
   * @param {String} lang of the text
   */
  sendText(text, lang) {
    if (typeof text !== 'string') {
      this.throwError(ErrorCode.NOT_A_STRING);
    }
    const command = {
      method: MethodNames.SEND_TEXT,
      params: { text, lang },
    };
    this.sendCommand('AI*', command);
  }

  /**
   * get the session id being used by the AI module
   * returns value to aiMessage
   */
  getSessionId() {
    const command = {
      method: MethodNames.GET_AI_SESSION_ID,
      params: [],
    };
    this.sendCommand('AI*', command);
  }

  /**
   * set the ai user ID. Change the AI to another in the same project
   */
  setUserId(userId) {
    if (typeof userId !== 'string') {
      this.throwError(ErrorCode.NOT_A_STRING);
    }
    const command = {
      method: MethodNames.SET_AI_ID,
      params: [userId],
    };
    this.sendCommand('AI*', command);
  }

  /**
   * set the session ID of the AI
   * @param {String} userId of the session
   */
  setSessionId(userId) {
    if (typeof userId !== 'string') {
      this.throwError(ErrorCode.NOT_A_STRING);
    }
    const command = {
      method: MethodNames.SET_AI_SESSION_ID,
      params: [userId],
    };
    this.sendCommand('AI*', command);
  }
}
