import Module from './Module';

export default class VoiceAnalyticsModule extends Module {
  trigger({ method, params = {} }) {
    const command = {
      method,
      params,
    };

    this.sendCommand('VoiceAnalytics', command);
  }
}
