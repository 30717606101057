import sleep from '../../utils/sleep';

const maxRetries = 4;

/**
 * Send requests to RRMS. Retry failed requests maxRetries amount of times.
 * There is an exponential delay between retries.
 * @param {object} params Contains network request information
 * @param {string} params.baseUrl Network request base URL.
 * @param {string} params.path Resource path.
 * @param {string} params.method Request method.
 * @param {string} params.authToken Request authorization header.
 * @param {object} params.data Request body.
 * @throws {AbortError} Rejects if requests fails more then maxRetries amount of times.
 * @returns {Promise<Response>} Resolves to Response Object.
 */
export default async function request({
  baseUrl,
  path,
  method,
  authToken,
  data,
}) {
  let retryCount = 0;
  let retryDelay;
  const fallbackRetryDelay = 500;
  const maxRetryDelay = 30000;
  const requestUrl = new URL(path, baseUrl).href;
  let response;
  let isJsonRes;

  while (retryCount <= maxRetries) {
    try {
      response = await fetch(requestUrl, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
      });

      isJsonRes = response.headers.get('content-type')?.includes('application/json');

      // Successful request.
      if (response.ok) {
        break;
      }

      // Error response.
      const { errorCode, retryAfterMs, retry } = await response.json();

      const error = new Error(errorCode);
      error.code = errorCode;

      retryDelay ??= retryAfterMs;

      if (!retry) {
        retryCount = maxRetries;
      }

      throw error;
    } catch (error) {
      // No response or error response handling.
      if (retryCount === maxRetries) {
        throw error;
      }

      retryDelay ??= fallbackRetryDelay;
      retryDelay = Math.min(retryDelay, maxRetryDelay);

      await sleep(retryDelay);

      retryDelay *= 2;
      retryCount += 1;
    }
  }

  return isJsonRes ? response.json() : response.text();
}
