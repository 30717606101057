import * as THREE from 'three';

function setupDefaultRenderer(rendererSetupHook, baseRendererParams, logger) {
  const defaultBaseRendererParams = {
    antialias: true,
    alpha: true,
  };

  Object.assign(defaultBaseRendererParams, baseRendererParams);

  const renderer = new THREE.WebGLRenderer(defaultBaseRendererParams);

  // Log max renderer dimensions
  const gl = renderer.getContext();

  logger.debug(`Rendering | MAX_RENDERBUFFER_SIZE: ${gl.getParameter(gl.MAX_RENDERBUFFER_SIZE)}`);
  logger.debug(`Rendering | MAX_VIEWPORT_DIMS: ${gl.getParameter(gl.MAX_VIEWPORT_DIMS)}`);
  logger.debug(`Rendering | MAX_TEXTURE_SIZE: ${gl.getParameter(gl.MAX_TEXTURE_SIZE)}`);

  renderer.useLegacyLights = true;
  renderer.setClearColor(0x000000, 0);
  renderer.shadowMap.enabled = true;
  renderer.shadowMapDarkness = 0.5;
  renderer.shadowCameraFov = 23;

  renderer.setPixelRatio(1);

  // Optional hook provided by the integrator to modify renderer config.
  if (rendererSetupHook) {
    rendererSetupHook({ renderer, THREE });
  }

  return renderer;
}

export default setupDefaultRenderer;
