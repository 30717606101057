import ErrorCode from '../ErrorCode';
import Audio from '../audio/Audio';

import loading from '../../static/images/svgs/loading.svg?raw';

import speakerSVG from '../../static/images/svgs/speaker.svg?raw';
import speakerMute from '../../static/images/svgs/speakerMute.svg?raw';
import microphone from '../../static/images/svgs/microphone.svg?raw';
import microphoneMute from '../../static/images/svgs/microphoneMute.svg?raw';

export const MIC_STATE = Object.freeze({
  OFF: 'off',
  ON: 'on',
  VAD_ACTIVE: 'vad active',
  VAD_INACTIVE: 'vad inactive',
  FAILURE: 'failure',
});

const defaultOptions = {
  micControl: false,
  volumeControl: false,
};

/**
 * Handle Audio UI and Event Listeners
 */
class AudioControls {
  constructor(components, params) {
    this.isVisible = false;
    this.ui = {
      container: components.container,
      loading: components.loading,
      mic: {
        on: components.on,
        off: components.off,
        vad: components.vad,
        failed: components.failed,
      },
      speaker: {
        up: components.up,
        mute: components.mute,
      },
      volume: {

        control: components.control,
      },
    };

    this.ui.loading.innerHTML = loading;
    const loadingSVG = this.ui.loading.getElementsByTagName('svg')[0];
    loadingSVG.style.animation = 'rotation';
    loadingSVG.style.transformOrigin = '50 50';
    loadingSVG.style.display = 'inline-block';
    loadingSVG.style.animationDuration = '4s';
    loadingSVG.style.animationIterationCount = 'infinite';
    loadingSVG.style.animationTimingFunction = 'linear';

    this.ui.mic.on.innerHTML = microphone;
    this.ui.mic.off.innerHTML = microphoneMute;

    this.ui.speaker.mute.innerHTML = speakerMute;
    this.ui.speaker.up.innerHTML = speakerSVG;

    this.logger = params.logger;

    const { micControl, volumeControl } = params.options || defaultOptions;

    this.options = {
      micControl,
      volumeControl,
    };

    // User muted the mic. Do not unmute mic until user enables it manually.
    this.micLocked = false;

    this.listenersStarted = false;
    this.rapport = null;
    this.errorCallBack = params.errorCallback;
    this.onStateChange = params.onStateChange;
    this.init();
  }

  logger = null;

  init() {
    this.handleMicOn = this.handleMicOn.bind(this);
    this.handleMicOff = this.handleMicOff.bind(this);
    this.handleVolumeControl = this.handleVolumeControl.bind(this);
    this.handleVolumeUnMute = this.handleVolumeUnMute.bind(this);
    this.handleVolumeMute = this.handleVolumeMute.bind(this);
    this.hide();
  }

  resetParams() {
    this.ui.volume.control.value = 1;
    Audio.setGain(0, 0);
    Audio.setGain(1, 1);
    Audio.previousGainNodeValue = 1;
    this.muteSpeaker(false);
    this.muteMic(true);
    this.handleVolumeControl();
    this.micLocked = false;
    this.hide();
  }

  /**
   * EVENTS HANDLERS
   */

  muteMic(mute = true) {
    const { logger } = this;

    try {
      if (Audio.mic.userEnabled === !mute) {
        return;
      }

      const { mic: { on, off } } = this.ui;

      on.style.display = !mute ? 'block' : 'none';
      off.style.display = !mute ? 'none' : 'block';
      Audio.mic.userEnabled = !mute;
      logger.debug(`AudioControl | Mic muted state changed: ${mute}`);
      this.onStateChange({
        micMuted: mute,
      });
    } catch (error) {
      this.errorCallBack(ErrorCode.FAILURE_NOT_CONNECTED);
    }
  }

  handleMicOn() {
    this.muteMic(false);
    this.micLocked = false;
  }

  handleMicOff() {
    this.muteMic(true);
    this.micLocked = true;
  }

  muteSpeaker(isMute = true) {
    const { logger } = this;

    try {
      if (isMute) {
        this.ui.speaker.mute.style.display = 'block';
        this.ui.speaker.up.style.display = 'none';

        Audio.setGain(0, 0.05);
        this.ui.volume.control.value = 0;

        logger.debug('AudioControl | Speaker state changed: muted');
      } else {
        this.ui.speaker.mute.style.display = 'none';
        this.ui.speaker.up.style.display = 'block';

        this.ui.volume.control.value = Audio.previousGainNodeValue;
        Audio.setGain(Audio.previousGainNodeValue, 0.05);
        logger.debug('AudioControl | Speaker state changed: unmuted');
      }
    } catch (error) {
      this.errorCallBack(ErrorCode.FAILURE_NOT_CONNECTED);
    }
  }

  handleVolumeMute() {
    this.muteSpeaker(true);
  }

  handleVolumeUnMute() {
    this.muteSpeaker(false);
  }

  handleVolumeControl() {
    const { volume: { control } } = this.ui;
    Audio.setGain(control.value, 0);
    Audio.previousGainNodeValue = control.value;

    const shouldMute = Number(control.value) === 0 || false;
    this.muteSpeaker(shouldMute);
  }

  /**
   * Kick off event listeners
   */
  setupListeners() {
    if (!this.listenersStarted) {
      const { mic, volume, speaker } = this.ui;

      this.listenersStarted = true;

      mic.on.addEventListener('click', this.handleMicOff);
      mic.off.addEventListener('click', this.handleMicOn);
      volume.control.addEventListener('input', this.handleVolumeControl);
      speaker.mute.addEventListener('click', this.handleVolumeUnMute);
      speaker.up.addEventListener('click', this.handleVolumeMute);
    }
  }

  /**
   * Remove events
   */
  disposeListeners() {
    if (this.listenersStarted) {
      const { mic, volume, speaker } = this.ui;

      this.listenersStarted = false;

      mic.on.removeEventListener('click', this.handleMicOn);
      mic.off.removeEventListener('click', this.handleMicOff);
      volume.control.removeEventListener('input', this.handleVolumeControl);
      speaker.mute.removeEventListener('click', this.handleVolumeUnMute);
      speaker.up.removeEventListener('click', this.handleVolumeMute);
    }
  }

  /**
   * Update Audio UI options
   * @param {String} param option name
   * @param {Any} value set a new value to the option
   */
  option(param, value) {
    const current = this.options;
    this.options = {
      ...current,
      [param]: value,
    };
  }

  show() {
    this.isVisible = true;

    if (this.options.volumeControl) {
      this.ui.container.style.display = 'inline-block';
      this.ui.speaker.up.style.display = 'block';
      this.ui.volume.control.style.display = 'inline-block';
    }

    if (this.options.micControl) {
      this.ui.container.style.display = 'inline-block';
      this.ui.mic.off.style.display = 'block';
    }

    this.ui.loading.style.display = 'none';

    this.setupListeners();
  }

  hide() {
    this.isVisible = false;

    this.ui.loading.style.display = 'block';

    this.ui.container.style.display = 'inline-block';
    this.ui.volume.control.style.display = 'none';
    this.ui.mic.off.style.display = 'none';
    this.ui.mic.on.style.display = 'none';
    this.ui.speaker.mute.style.display = 'none';
    this.ui.speaker.up.style.display = 'none';

    this.disposeListeners();
  }
}

export default AudioControls;
