import sessionRequest from './lib/sessionRequest';
import ErrorCode from '../ErrorCode';
import { RapportSceneStatus } from '../ui/SceneStatus';

export default class SessionAi {
  /**
   * Create session manager for AI based sessions. Start method creates room and joins to it.
   * Callback onProgress is called with room details object when room is created.
   * @param {object} params Contain session manager params.
   * @param {string} params.baseUrl RRMS api base URL.
   * @param {string} params.projectId Accounts project id.
   * @param {string} params.projectToken Accounts project token.
   * @param {string} params.lobbyZoneId Accounts project lobby zone id.
   * @param {string} params.aiUserId Accounts project ai user id.
   * @param {string} params.offerSdp WebRTC session description SDP offer.
   * @param {Function} params.onProgress Callback called with room details when room is created.
   */
  constructor(params) {
    Object.assign(this, params);
  }

  roomDetails;

  async start() {
    let roomDetails;

    try {
      roomDetails = await this.createRoom();
    } catch (error) {
      if (error.code === 'accounts-api:session_limit_reached') {
        error.code = ErrorCode.SESSION_LIMIT_REACHED;
      } else if (error.code === 'accounts-api:session_concurrency_reached') {
        error.code = ErrorCode.SESSION_CONCURRENCY_LIMIT_REACHED;
      } else {
        error.code = ErrorCode.FAILURE_CREATE_ROOM;
      }

      throw error;
    }

    this.roomDetails = roomDetails;

    this.onProgress({
      status: RapportSceneStatus.ROOM_CREATED,
      roomDetails,
    });

    try {
      const { room_id: roomId, answerSdp } = await this.joinRoom();

      Object.assign(roomDetails, {
        roomId,
        answerSdp,
      });
    } catch (error) {
      // Map generic error message to Rapport error.
      if (error.code === 'rpt:empty_error_code') {
        error.code = ErrorCode.FAILURE_JOIN_ROOM;
      }

      throw error;
    }

    return roomDetails;
  }

  async request({ url, method, authToken, data }) {
    const { baseUrl } = this;
    const path = url;

    return sessionRequest({
      baseUrl,
      path,
      method,
      authToken,
      data,
    });
  }

  async createRoom() {
    const { projectId, projectToken, lobbyZoneId, aiUserId } = this;

    return this.request({
      url: 'user/aisession',
      method: 'POST',
      data: {
        domain_id: projectId,
        domain_token: projectToken,
        lobby_zone_id: lobbyZoneId,
        user_id: aiUserId,
      },
    });
  }

  async joinRoom() {
    const { offerSdp } = this;

    const { id_token: authToken, room_request_id: request_id } = this.roomDetails;

    return this.request({
      url: 'lobby/aisessiongetresult',
      method: 'POST',
      authToken,
      data: {
        request_id,
        offerSdp,
      },
    });
  }

  async triggerCommand({ commandId }) {
    const { roomId, id_token, room_request_id } = this.roomDetails;

    return this.request({
      url: 'user/command',
      method: 'POST',
      data: {
        commandId,
        roomId,
        id_token,
        room_request_id,
      },
    });
  }

  async refresh() {
    const { projectToken } = this;

    return this.request({
      url: 'ai-sessions/refresh',
      method: 'POST',
      authToken: `Bearer ${projectToken}`,
    });
  }
}
