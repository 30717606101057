import * as THREE from 'three';

/**
 * Calculate bounding box of given object3d.
 * @param {object} object3d Object3d to calculate bounding box on.
 * @return {Object} Three.js bounding box object.
 */
export default function getBoundingBox(object3d) {
  object3d.updateMatrixWorld(true);

  return new THREE.Box3().setFromObject(object3d);
}
