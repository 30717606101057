/**
 * Browser Detection handler
 */
class Browser {
  /**
 * class to determine browser
 * @param {String} userAgent navigator.userAgent
 * @param {String} vendor navigator.vendor
 */
  constructor(userAgent, vendor) {
    this.browser = 'UnknownBrowser';
    this.userAgent = userAgent;
    this.vendor = vendor;
    // Opera 8.0+
    // eslint-disable-next-line no-undef
    this.isOpera = (!!window.opr && !!opr.addons)
      || !!window.opera || /(opr)/i.test(userAgent);

    // Firefox 1.0+
    this.isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ '[object HTMLElementConstructor]'
    this.isSafari = /(safari)/i.test(userAgent)
      && /Apple Computer/.test(vendor);
    // Internet Explorer 6-11
    // eslint-disable-next-line spaced-comment
    this.isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    this.isEdge = !this.isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    this.isChrome = /(chrome)/i.test(userAgent);

    // Edge (based on chromium) detection
    this.isEdgeChromium = /(chrome)/i.test(userAgent)
      && /(edg)/i.test(userAgent);

    this.isCriOS = /(crios)/i.test(userAgent);

    if ((this.isChrome || this.isCriOS) && (this.isSafari)) {
      this.isSafari = false;
    }

    if (this.isFirefox) this.browser = 'Firefox';
    if (this.isIE) this.browser = 'IE';
    if (this.isEdge) this.browser = 'Edge';
    if (this.isChrome) this.browser = 'Chrome';
    if (this.isEdgeChromium) this.browser = 'EdgeChromium';
    if (this.isOpera) this.browser = 'Opera';
    if (this.isSafari) this.browser = 'Safari';
    if (this.isCriOS) this.browser = 'ChromeiOS';
  }

  /**
   * get string value of browser
   * @return {string} browser
   */
  getBrowser() {
    return this.browser;
  }
}

const instance = new Browser(navigator.userAgent, navigator.vendor);

export default instance;
