// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { PlayerData } from '../fb/player-data.js';


export enum Payload {
  NONE = 0,
  PlayerData = 1
}

export function unionToPayload(
  type: Payload,
  accessor: (obj:PlayerData) => PlayerData|null
): PlayerData|null {
  switch(Payload[type]) {
    case 'NONE': return null; 
    case 'PlayerData': return accessor(new PlayerData())! as PlayerData;
    default: return null;
  }
}

export function unionListToPayload(
  type: Payload, 
  accessor: (index: number, obj:PlayerData) => PlayerData|null, 
  index: number
): PlayerData|null {
  switch(Payload[type]) {
    case 'NONE': return null; 
    case 'PlayerData': return accessor(index, new PlayerData())! as PlayerData;
    default: return null;
  }
}
