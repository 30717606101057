/**
 * Validate a position object:
 * - check all required axis exist
 * - check type of value
 *
 * @param {Object} position - { x, y }
 * @returns Boolean
 */
const validate2DPosition = (position) => {
  const axisArr = ['x', 'y'];
  const isValid = !!position
      && axisArr.every((axis) => Object.keys(position).find((key) => key === axis))
      && Object.keys(position).every((key) => typeof position[key] === 'number');

  return isValid;
};

export default validate2DPosition;
