import closeIconAsset from '../../static/images/svgs/close.svg?raw';
import ModelErrorHtml from './ModelError.html?raw';

export default class RapportModalError {
  /**
   * Creates error modal element inside the given parent element.
   * @param {Object} params Contain element parameter.
   * @param {Object} params.element Parent element to create modal in.
   */
  constructor({ element }) {
    element.innerHTML = ModelErrorHtml;

    this.modalElement = element.querySelector('#modalError');
    this.contentElement = element.querySelector('#modalErrorContent');
    this.closeIcon = element.querySelector('#modalErrorCloseIcon');

    this.closeIcon.innerHTML = closeIconAsset;

    this.addEventListeners();
  }

  addEventListeners() {
    const { closeIcon } = this;

    closeIcon.addEventListener('click', () => {
      this.visible = false;
    });
  }

  enabled = true;

  modalElement = null;

  contentElement = null;

  closeIcon = null;

  set visible(value) {
    this.modalElement.style.visibility = value ? 'visible' : 'hidden';
  }

  set content(value) {
    this.contentElement.innerHTML = value;
  }
}
