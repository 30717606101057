// index
import AiModule from './AiModule';
import AcModule from './AcModule';
import TtsModule from './TtsModule';
import AsrModule from './AsrModule';
import VadModule from './VadModule';
import VoiceAnalyticsModule from './VoiceAnalyticsModule';
import SentimentAnalysisModule from './SentimentAnalysisModule';

/**
 * Create Modules
 * @param {Function} callback
 * @param {Error} throwError
 * @returns modules
 */
export default function createModules(callback, throwError) {
  return {
    ai: new AiModule(callback, throwError),
    ac: new AcModule(callback, throwError),
    tts: new TtsModule(callback, throwError),
    asr: new AsrModule(callback, throwError),
    vad: new VadModule(callback, throwError),
    va: new VoiceAnalyticsModule(callback, throwError),
    sa: new SentimentAnalysisModule(callback, throwError),
  };
}
