import * as THREE from 'three';
import getBoundingBox from '../../../../rendering/lib/helpers/getBoundingBox';

export default class CameraRpm extends THREE.PerspectiveCamera {
  /**
   * Create camera exclusive for RPM full body characters.
   * Camera position and focus is set to the head.
   * @param {object} params Contain camera parameters.
   * @param {object} params.rpmSkinnedMesh RPM character SkinnedMesh object.
   * @param {number} params.cameraPosYOffset Offset for camera Y pos.
   */
  constructor({
    rpmSkinnedMesh,
    cameraPosYOffset,
  }) {
    const fov = 23;
    const aspect = 1;
    const near = 0.1;
    const far = 500;
    const cameraDistance = 1;

    // Calculate camera Y position by setting it to mesh's topmost position and adding an offset.
    const meshBoundingBox = getBoundingBox(rpmSkinnedMesh);
    const cameraPosY = meshBoundingBox.max.y + cameraPosYOffset;

    super(fov, aspect, near, far);

    this.position.set(0, cameraPosY, cameraDistance);
    this.lookAt(0, cameraPosY, 0);
  }
}
