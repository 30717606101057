/* eslint-disable camelcase */
/* eslint-disable brace-style */
/* eslint-disable require-jsdoc */
import Network from './network/Network';
import NetworkUpxs from './network/upxs/NetworkUpxs';
import RenderingUpxs from './rendering/upxs/RenderingUpxs';
import LookAtUpxs from './common/upxs/LookAtUpxs';
import OrbitControlsUpxs from './common/upxs/OrbitControlsUpxs';
import AnimationControllerUpxs from './players/upxs/AnimationControllerUpxs';
import CanvasCursor from './rendering/CanvasCursor';
import Browser from './utils/browser';
import Audio from './audio/Audio';
import SessionMeeting from './session/SessionMeeting';
import SessionAi from './session/SessionAi';
import FlatbufferUnpacker from './utils/FlatbufferUnpacker';
import PlayerWebgl from './players/webgl/PlayerWebgl';

import { RapportSceneStatus } from './ui/SceneStatus';
import LoggingLevel from './LoggingLevel';
import ErrorCode from './ErrorCode';
import mapErrorCode from './mapErrorCode';

class Rapport {
  /**
   * rapport instance
   * @param {url} baseUrl required && static
   * @param {String} projectId required && static
   * @param {String} projectToken required && static
   * @param {Integer} lobbyZoneId required && static
   * @param {String} aiUserId required
   * @param {String} aiSessionId as defined by integrator
   * @param {Method} statusCallback callback used for error/status reporting
   */
  constructor(
    logger,
    baseUrl,
    projectId,
    projectToken,
    lobbyZoneId,
    aiUserId,
    aiSessionId,
    projectType,
    roomId,
    selfCharacterId,
    selfDisplayName,
    previewModelUrl,
    sceneConfigData,
    baseRendererParams,
    callback,
    statusCallback,
  ) {
    this.logger = logger;
    this.connected = true;
    this.callback = callback;
    this.statusCallback = statusCallback;

    this.baseUrl = baseUrl;
    this.projectId = projectId;
    this.projectToken = projectToken;
    this.lobbyZoneId = lobbyZoneId;

    this.projectType = projectType;
    this.roomId = roomId;
    this.selfCharacterId = selfCharacterId;
    this.selfDisplayName = selfDisplayName;
    this.previewModelUrl = previewModelUrl;
    this.sceneConfigData = sceneConfigData;
    this.baseRendererParams = baseRendererParams;

    // Rendering
    this.players = new Map();
    this.renderingUpxs = null;

    this.lookAtUpxs = null;
    this.orbitControlsUpxs = null;
    this.animationControllerUpxs = null;
    this.canvasCursorUpxs = null;

    this.aiUserId = aiUserId;
    this.aiSessionId = aiSessionId;

    this.meetingClientId = null;
    this.session = null;
    this.network = null;
    this.networkUpxs = null;

    this.aiInterruptable = null;

    this.flatbufferUnpacker = new FlatbufferUnpacker({ logger });
  }

  get rendering() {
    if (this.players.size) {
      const [player] = this.players.values();

      return player.rendering;
    }

    return null;
  }

  resizeRenderers() {
    const { players } = this;

    players.forEach((player) => {
      player.rendering.resizeRenderer();
    });
  }

  /**
   * start rapport connection, returns true if model loaded and
   * ai is creating room
   * @returns {Boolean} true or false on success
   */
  async start(
    container,
    background,
    orbitalControls = true,
    ocZoom = true,
    ocAngle = 45,
    targetFps,
    cameraPosition,
    cameraLookAt,
    forceDefaultCamera,
    rendererSetupHook,
  ) {
    const { logger } = this;

    // Event handlers
    this.container = container;
    this.background = background;
    this.orbitalControls = orbitalControls;
    this.ocZoom = ocZoom;
    this.cameraPosition = cameraPosition;
    this.cameraLookAt = cameraLookAt;
    this.forceDefaultCamera = forceDefaultCamera;
    this.rendererSetupHook = rendererSetupHook;

    this.container.innerHTML = '';

    if (ocAngle > 90) {
      this.ocAngle = 90;
    } else if (ocAngle >= 0.001) {
      this.ocAngle = ocAngle;
    } else {
      this.orbitalControls = false; // this also disables zoom
    }

    this.targetFps = targetFps;

    this.callback(RapportSceneStatus.LOADING_START);
    this.callback(RapportSceneStatus.SETTING_UP_SESSION);

    switch (this.projectType) {
      case 'meeting': {
        try {
          await this.initAudio();

          const offerSdp = await this.initNetwork();

          this.session = new SessionMeeting({
            baseUrl: this.baseUrl,
            projectId: this.projectId,
            projectToken: this.projectToken,
            roomId: this.roomId,
            characterId: this.selfCharacterId,
            displayName: this.selfDisplayName,
            offerSdp,
          });

          const roomDetails = await this.session.start();

          this.meetingClientId = roomDetails.user.clientId;
          this.meetingRoomId = roomDetails.roomId;

          await this.networkConnect(roomDetails.answerSdp);

          return ErrorCode.SUCCESS;
        } catch (error) {
          logger.error(new Error('Session | Failed to start meeting session.', { cause: error }));
          return ErrorCode.MEETING_SESSION_START_FAILURE;
        }
      }

      case 'ai': {
        try {
          await this.initAudio();

          const offerSdp = await this.initNetwork();

          this.session = new SessionAi({
            baseUrl: this.baseUrl,
            projectId: this.projectId,
            projectToken: this.projectToken,
            lobbyZoneId: this.lobbyZoneId,
            aiUserId: this.aiUserId,
            offerSdp,
            onProgress: ({ status, roomDetails }) => {
              // Prevent download character for UPXS session.
              if (!roomDetails.character_id) {
                return;
              }

              if (status === RapportSceneStatus.ROOM_CREATED) {
                const {
                  character_id: characterId,
                  user_status: userStatus,
                  logToken,
                } = roomDetails;

                const {
                  model_file_url: modelFileUrl,
                  model_file_hash: modelFileHash,
                  animationStreamId,
                } = userStatus;

                const { sceneConfigData } = this;

                logger.setLogToken(logToken);

                sceneConfigData.assets.push({
                  id: 'character001',
                  type: 'Character',
                  file: {
                    id: characterId,
                    url: modelFileUrl,
                    hash: modelFileHash,
                  },
                });

                this.setupPlayer({
                  clientId: animationStreamId,
                  sceneConfigData,
                });
              }

              this.callback(status);
            },
          });

          const roomDetails = await this.session.start();

          this.aiInterruptable = roomDetails.user_status.ai_info.ai_interruptable;

          await this.networkConnect(roomDetails.answerSdp);

          this.callback();

          return ErrorCode.SUCCESS;
        } catch (error) {
          const errorMessage = mapErrorCode(error.code).message;

          logger.error(new Error(`Session | ${errorMessage}`, { cause: error }));

          return error.code;
        }
      }

      case 'preview': {
        try {
          await this.setupPreviewMode();
          return ErrorCode.SUCCESS;
        } catch (error) {
          logger.error(new Error('Session | Failed to setup preview mode.', { cause: error }));
          return ErrorCode.FAILURE_CREATE_WEBGL_PLAYER;
        }
      }

      default: {
        return ErrorCode.INVALID_SESSION_TYPE;
      }
    }
  }

  async setupPreviewMode() {
    const { sceneConfigData, previewModelUrl } = this;

    sceneConfigData.assets.push({
      id: 'character001',
      type: 'Character',
      file: {
        id: 'preview',
        url: previewModelUrl,
        hash: Date.now(),
      },
    });

    const player = this.createWebglPlayer({ clientId: 'preview', sceneConfigData });

    await player.init();
    await player.startRendering();
    this.callback(RapportSceneStatus.LOADING_DONE);

    this.players.set('preview', player);
  }

  async setupPlayer({ clientId, sceneConfigData }) {
    const { logger } = this;

    try {
      const player = this.createWebglPlayer({ clientId, sceneConfigData });

      this.players.set(clientId, player);

      await player.init();
    } catch (error) {
      logger.error(new Error('Player | Failed to setup player.', { cause: error }));

      this.statusCallback(LoggingLevel.ERROR, ErrorCode.FAILURE_CREATE_WEBGL_PLAYER);
    }
  }

  async initAudio() {
    const audioStarted = await Audio.resume(this.statusCallback);

    if (audioStarted !== ErrorCode.SUCCESS) {
      const error = new Error('Failed to resume audio context');

      error.code = audioStarted;

      throw error;
    }
  }

  async initNetwork() {
    const { logger, aiSessionId } = this;

    this.network = new Network({
      logger,
      aiSessionId,
      callback: (params) => {
        this.networkCallback(params);
      },
      statusCallback: (...args) => {
        this.statusCallback(...args);
      },
    });

    const offerSdp = await this.network.init();

    return offerSdp;
  }

  async networkConnect(answerSdp) {
    if (!this.connected) {
      return;
    }

    this.callback(RapportSceneStatus.JOINING_ROOM);

    await this.network.connect(answerSdp);
  }

  createWebglPlayer({ clientId, sceneConfigData, selfPlayer, displayName }) {
    const { logger } = this;

    return new PlayerWebgl({
      clientId,

      sceneConfigData,

      renderingParams: {
        scene3D: this.container,
        displayName,
        background: this.background,
        orbitalControls: this.orbitalControls,
        ocZoom: this.ocZoom,
        ocAngle: this.ocAngle,
        targetFps: this.targetFps,
        cameraPosition: this.cameraPosition,
        cameraLookAt: this.cameraLookAt,
        forceDefaultCamera: this.forceDefaultCamera,
        rendererSetupHook: this.rendererSetupHook,
        baseRendererParams: this.baseRendererParams,
        preview: this.projectType === 'preview',
        selfPlayer,
        callback: this.renderingCallback.bind(this),
      },

      audioBufferSourceParams: {
        context: Audio.context,
        outputNode: Audio.gainNode,
        gain: selfPlayer ? 0 : 1,
      },

      logger,

      onCallback: (...args) => {
        this.callback(...args);
      },

      onStatusCallback: (...args) => {
        this.statusCallback(...args);
      },
    });
  }

  async syncMeetingRoomPlayers() {
    const users = await this.session.getUsers();
    const { meetingClientId, players } = this;
    const newPlayers = [];

    users.forEach((user) => {
      const {
        status,
        clientId,
        displayName,
        characterId,
        modelFileUrl,
        modelFileHash,
        animationNodes: sgcomAnimationNodes,
      } = user;

      if (status === 'connecting') {
        return;
      }

      // Do not create players for users with already existing players.
      if (players.has(clientId)) {
        return;
      }

      const newPlayer = this.createWebglPlayer({
        clientId,
        sceneConfigData: {
          assets: [
            {
              id: characterId,
              type: 'Character',
              file: {
                id: characterId,
                url: modelFileUrl,
                hash: modelFileHash,
                sgcomAnimationNodes,
              },
            },
          ],
        },
        selfPlayer: clientId === meetingClientId,
        displayName,
      });

      newPlayers.push(newPlayer);

      this.players.set(clientId, newPlayer);
    });

    // Dispose player of users whose are no longer in the room.
    const clientIdList = users.map((user) => user.clientId);

    players.forEach((player, clientId) => {
      if (!clientIdList.includes(clientId)) {
        player.dispose();
        players.delete(clientId);
      }
    });

    // Temporarily suspend jitter calculation for the players.
    // During renderer initialization jitter spikes if not suspended.
    players.forEach((player) => player.suspendJitter());

    await Promise.all(newPlayers.map((newPlayer) => newPlayer.init()));
    await Promise.all(newPlayers.map((newPlayer) => newPlayer.startRendering()));

    this.resizeRenderers();
  }

  renderingCallback(params) {
    const { logger } = this;

    this.params = params;

    switch (true) {
      case params.eventType !== undefined: {
        this.callback(params);

        break;
      }
      case params.rapportScene !== undefined: {
        // Rendering event is emitted only for AI sessions when the rendering starts.
        // Other session types emits the rendering event according to different logic.
        const isRenderingEvent = params.rapportScene === RapportSceneStatus.RENDERING;

        if (this.meetingClientId && isRenderingEvent) {
          break;
        }

        this.callback(params.rapportScene);

        break;
      }
      default:
        logger.debug(`Rendering | Unknown rendering callback. ${params}`);
    }
  }

  async networkCallback(params) {
    const { logger } = this;

    switch (true) {
      case params.rxPlayer !== undefined: {
        const unpackedFlatbuffer = this.flatbufferUnpacker.unpack(params.rxPlayer);

        if (!unpackedFlatbuffer) {
          break;
        }

        const player = this.players.get(unpackedFlatbuffer.animationStreamId);

        if (!player?.enabled) {
          break;
        }

        player.characters[0].playbackQueue.handleUnpackedFlatbuffer(unpackedFlatbuffer);

        break;
      }
      case params.sgcomAnimationNodes !== undefined: {
        const { animationStreamId, sgcomAnimationNodes } = params;

        try {
          const player = this.players.get(animationStreamId);

          // Wait until character is download and the player is fully ready.
          try {
            await player.ready;
          } catch (error) {
            // Player initialization error is not handled here.
            logger.error(
              new Error(
                'Player | Failed to handle sgcomAnimationNodes: player initialization failed.',
                { cause: error },
              ),
            );
            break;
          }

          player.characters[0].sgcomAnimationNodes = sgcomAnimationNodes;

          await player.startRendering();

          this.callback(RapportSceneStatus.LOADING_DONE);

          // Meeting session only emits rendering event when the session setup is done.
          // At this point its possible there is no active renderer.
          if (this.meetingClientId) {
            this.callback(RapportSceneStatus.RENDERING);
          }
        } catch (error) {
          logger.error(
            new Error(
              'Player | Failed to handle sgcomAnimationNodes: player initialization failed.',
              { cause: error },
            ),
          );
          this.statusCallback(LoggingLevel.ERROR, ErrorCode.FAILURE_START_RENDERING);
        }

        break;
      }
      case params.upxsParams !== undefined: {
        this.initializeUpxs(params.upxsParams);

        break;
      }
      case params.meetingRoomEvent !== undefined: {
        const { meetingRoomEvent } = params;

        if (meetingRoomEvent === 'users-changed') {
          try {
            await this.syncMeetingRoomPlayers();

            this.callback(RapportSceneStatus.USERS_CHANGED);
          } catch (error) {
            logger.error(
              new Error('Session | Failed to sync meeting room players.', { cause: error }),
            );
            this.statusCallback(LoggingLevel.ERROR, ErrorCode.MEETING_ROOM_SYNC_FAILURE);
          }
        }

        break;
      }

      case params.rapportScene !== undefined:
        this.callback(params.rapportScene);
        break;
      case params.tts !== undefined: {
        // UPXS TTS callbacks are not synchronized with character animation.
        if (this.networkUpxs) {
          this.emitTtsCallback(params);
        } else {
          this.syncTtsCallback(params);
        }
        break;
      }
      default:
        logger.debug(`Network | Unknown network callback. ${params}`);
    }
  }

  // Synchronize TTS callback with the character's start/end speaking animation.
  syncTtsCallback({ time, ...params }) {
    const { outputCurrentTimeMs } = Audio;
    const [player] = this.players.values();
    const { playbackQueue } = player.characters[0];
    const playTimeMs = time * 1000;

    const triggerTime = playbackQueue.calculatePlayTime(playTimeMs);
    const delay = Math.max(triggerTime - outputCurrentTimeMs, 0);

    setTimeout(() => {
      this.emitTtsCallback(params);
    }, delay);
  }

  emitTtsCallback(params) {
    const { tts } = params;
    const { aiInterruptable } = this;
    const { mic } = Audio;
    let eventType;

    if (tts === 'start') {
      eventType = RapportSceneStatus.TTS_START;

      if (!aiInterruptable) {
        mic.systemEnabled = false;
      }
    } else if (tts === 'end') {
      eventType = RapportSceneStatus.TTS_END;

      if (!aiInterruptable) {
        mic.systemEnabled = true;
      }
    }

    this.callback({
      eventType,
      ...params,
    });
  }

  /**
   * Initialize network, rendering, and audio subsystems for Unreal Pixel Streaming render mode.
   * Errors can be emitted from subsystems both during initialization and runtime.
   * @param {Object} upxsParams Parameters from CPI.
   * @param {Object} upxsParams.signalingParams Signaling server parameters.
   * @param {string} upxsParams.signalingParams.url Signaling server url.
   * @param {string} upxsParams.signalingParams.jwt Signaling server jwt.
   */
  async initializeUpxs(upxsParams) {
    const { logger } = this;

    try {
      logger.debug('UPXS | Initialization started.');

      const { audioStream, videoStream } = await new Promise((resolve, reject) => {
        const { signalingParams } = upxsParams;

        this.networkUpxs = new NetworkUpxs({
          signalingParams,
          onStreams: resolve,
          onError: (error) => {
            logger.error(
              new Error(`UPXS | UPXS network (${error.type}) error: ${error.message}`, {
                cause: error,
              }),
            );

            reject(error);

            const errorTypes = {
              signaling: ErrorCode.UPXS_SIGNALING_CLOSED,
              rtc: ErrorCode.UPXS_RTC_CLOSED,
            };
            this.statusCallback(LoggingLevel.ERROR, errorTypes[error.type]);
          },
          onWarn: (warn) => {
            logger.warn(`UPXS | UPXS network (${warn.type}) warn: ${warn.message}`);
          },
        });
      });

      logger.debug('UPXS | Initialize upxs network success.');

      const { container } = this;

      await new Promise((resolve, reject) => {
        this.renderingUpxs = new RenderingUpxs({
          container,
          stream: videoStream,
          onFirstFrame: resolve,
          onError: (error) => {
            logger.error(
              new Error(`UPXS | Upxs rendering error: ${error.message}`, { cause: error }),
            );

            reject(error);
            this.statusCallback(LoggingLevel.ERROR, ErrorCode.UPXS_RENDERING_FAILURE);
          },
        });
      });

      Audio.playUpxsStream(audioStream);

      this.lookAtUpxs = new LookAtUpxs({
        container,
        onEyeMove: (eyeMove) => {
          this.network.sendCpiCommand({
            TargetCPIM: 'Unreal',
            method: 'send-unreal',
            params: [eyeMove],
          });
        },
      });

      this.orbitControlsUpxs = new OrbitControlsUpxs({
        enabled: this.orbitalControls,
        container,
        onCameraOrbit: (cameraOrbit) => {
          this.network.sendCpiCommand({
            TargetCPIM: 'Unreal',
            method: 'send-unreal',
            params: [cameraOrbit],
          });
        },
      });

      this.animationControllerUpxs = new AnimationControllerUpxs({
        clipNames: [],
        onAnimationRequest: (animationRequest) => {
          this.network.sendCpiCommand({
            TargetCPIM: 'Unreal',
            method: 'send-unreal',
            params: [JSON.stringify(animationRequest)],
          });
        },
      });

      this.canvasCursorUpxs = new CanvasCursor({
        isEnabled: this.orbitalControls,
        canvas: container,
      });

      logger.debug('UPXS | Initialize upxs rendering success');

      this.callback(RapportSceneStatus.LOADING_DONE);
      this.callback(RapportSceneStatus.RENDERING);
    } catch (error) {
      logger.error(
        new Error(`UPXS | Initialize upxs (${error.type}) failed: ${error.message}`, {
          cause: error,
        }),
      );
    }
  }

  // Refresh running try out session by dispatch old player and creating a new one.
  async sessionRefresh() {
    const { logger } = this;

    try {
      const { modelFileUrl, modelFileHash, animationStreamId } = await this.session.refresh();

      this.disposePlayers();

      const sceneConfigData = {
        assets: [
          {
            id: 'character001',
            type: 'Character',
            file: {
              id: `character${animationStreamId}`,
              url: modelFileUrl,
              hash: modelFileHash,
            },
          },
        ],
      };

      await this.setupPlayer({
        clientId: animationStreamId,
        sceneConfigData,
      });

      this.network.getControlHeader();
    } catch (error) {
      logger.error(new Error('Session | Failed to refresh session', { cause: error }));

      throw error;
    }
  }

  getParams() {
    return {
      session: this.session,
      network: this.network,
    };
  }

  async disconnect() {
    const { logger } = this;

    this.connected = false;

    try {
      if (this.network) {
        this.network.disconnect();
        this.network = null;
      }
    } catch (error) {
      logger.error(new Error('Network | Failed to disconnect network.', { cause: error }));
    }

    if (this.networkUpxs) {
      this.networkUpxs.disconnect();
      this.networkUpxs = null;
    }

    this.disposePlayers();

    if (this.renderingUpxs) {
      this.renderingUpxs.dispose();
      this.renderingUpxs = null;
    }

    if (this.orbitControlsUpxs) {
      this.lookAtUpxs = null;
      this.orbitControlsUpxs.dispose();
      this.orbitControlsUpxs = null;
      this.animationControllerUpxs = null;
      this.canvasCursorUpxs.disposeListeners();
      this.canvasCursorUpxs = null;
    }

    if (this.session) {
      await this.session.dispose?.();
      this.session = null;
    }
  }

  disposePlayers() {
    const { logger } = this;

    try {
      this.players.forEach((player, clientId) => {
        player.dispose();
        this.players.delete(clientId);
      });
    } catch (error) {
      logger.error(new Error('Player | Failed to dispose players.', { cause: error }));
    }
  }
}

export default Rapport;
