export default class RenderingUpxs {
  /**
   * Create rendering subsystem for Unreal Pixel Streaming.
   * A video player will be added to the given container DOM element.
   * The video stream will be automatically played.
   * Callback onFirstFrame is called when the first video frame gets played.
   * Callback onError is called with "error" object.
   * Error has "type" prop with value of "rendering".
   * @param {Object} params Contain rendering parameters, and callbacks.
   * @param {Element} params.container DOM container element where the video player is created.
   * @param {Object} params.stream RTC video stream.
   * @param {Function} params.onFirstFrame Callback called when the first video frame gets played.
   * @param {Function} params.onError Callback called with error object.
   */
  constructor(params) {
    Object.assign(this, params);

    this.createVideoPlayer();
    this.addEventListeners();
    this.render();
  }

  videoPlayer = null;

  createVideoPlayer() {
    const videoPlayer = document.createElement('video');

    videoPlayer.playsInline = true;
    videoPlayer.disablepictureinpicture = true;

    this.videoPlayer = videoPlayer;
  }

  addEventListeners() {
    this.videoPlayer.addEventListener('loadeddata', this, { once: true });
  }

  handleEvent(e) {
    switch (e.type) {
      case 'loadeddata':
        this.emitFirstFrame(e);
        break;

      default:
        break;
    }
  }

  emitFirstFrame() {
    this.onFirstFrame();
  }

  emitError(error) {
    error.type = 'rendering';
    this.onError(error);
  }

  async render() {
    const { videoPlayer, stream, container } = this;

    try {
      videoPlayer.srcObject = stream;
      container.replaceChildren(videoPlayer);

      await videoPlayer.play();
    } catch (error) {
      this.emitError(error);
    }
  }

  dispose() {
    this.container.replaceChildren();
  }
}
