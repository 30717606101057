import {
  AnimationMixer,
  LoopOnce,
  LoopRepeat,
} from 'three';

export default class Animations {
  #scene;

  #clips = []; // animations imported from glb

  // https://threejs.org/docs/#api/en/animation/AnimationMixer
  // The AnimationMixer is a player for animations on a particular
  // object in the scene. When multiple objects in the scene are animated independently,
  // one AnimationMixer may be used for each object.
  #mixer;

  // AnimationActions schedule the performance of the animations which are stored in AnimationClips.
  #actions;

  #prevTime;

  #activeAction;

  #previousAction;

  #speed = 1;

  // #onLoopEnd;
  #animationFinishHandler;

  #onAnimationFinished;

  // duration is fading time in seconds,
  // used for fading out previous action and fading in to active action
  // https://threejs.org/docs/#api/en/animation/AnimationAction.fadeIn
  #duration = 0.5;

  /**
   * Controller for playing glb animations in parallel to SG animation
   * @param {THREE.scene} scene of model to be animated
   * @param {GLB.animation} clips from glb animation
   * @param {function} onAnimationFinished Callback called when an animation play finished.
   */
  constructor({
    scene,
    clips,
    onAnimationFinished,
  }) {
    this.#scene = scene;
    this.#clips = clips || [];
    this.#speed = 1;
    this.#onAnimationFinished = onAnimationFinished;

    // Do we need to handle clips with cameras that resets position?
    // this.#clips.forEach(clip => {
    //   let index = clip.tracks.length - 1;
    //   while (index >= 0) {
    //     if (clip.tracks[index].name.toLowerCase().includes('cam')) {
    //       clip.tracks.splice(index, 1);
    //     }
    //     index -= 1;
    //   }
    // });

    // this.#onLoopEnd = (e) => {};
    this.#animationFinishHandler = () => {
      this.stop();

      this.#onAnimationFinished();
    };

    this.#mixer = new AnimationMixer(scene);
    // this.#mixer.addEventListener('loop', this.#onLoopEnd);
    this.#mixer.addEventListener('finished', this.#animationFinishHandler);

    this.#actions = {};

    // turn each animation clip into an action
    // eg dance, jump, idle
    for (let i = 0; i < this.#clips.length; i += 1) {
      const clip = this.#clips[i];
      const action = this.#mixer.clipAction(clip);
      this.#actions[clip.name] = action;
    }
  }

  /**
   * Play the animation for the given name
   * @param {String} name of animation clip to be played
   * @param {Boolean} loop Should the animation repeat, default false
   * @param {Name} speed speed the animation should be played at, default 1
   */
  play(name, loop = false, speed = 1) {
    if (speed !== undefined) {
      this.#speed = speed;
    }
    this.#previousAction = this.#activeAction;
    this.#activeAction = this.#actions[name];

    if (this.#previousAction !== this.#activeAction) {
      if (this.#previousAction) {
        this.#previousAction.fadeOut(this.#duration);
      }
    }

    if (loop) {
      this.#activeAction.clampWhenFinished = false;
      this.#activeAction.loop = LoopRepeat;
    } else {
      this.#activeAction.clampWhenFinished = true;
      this.#activeAction.loop = LoopOnce;
    }

    this.#activeAction
      .reset()
      .setEffectiveTimeScale(this.#speed)
      .setEffectiveWeight(1)
      .fadeIn(this.#duration)
      .play();
  }

  /**
   * Stop the active animation
   */
  stop() {
    if (!this.#activeAction) {
      return;
    }

    if (!this.#activeAction.paused) {
      this.#activeAction.halt();
    }

    this.#activeAction.fadeOut(this.#duration);
    this.#activeAction = null;
  }

  /**
   * get available animation clips
   * @returns Array of animation names that can be played
   */
  get() {
    return Array.from(this.#clips, (x) => x.name);
  }

  /**
   * Update animation mixer
   * @param {Number} time from request animation frame
   */
  update(time) {
    const dt = (time - this.#prevTime) / 1000;
    if (dt) {
      this.#mixer.update(dt);
    }
    this.#prevTime = time;
  }

  /**
   * set the speed of the animation
   * @param {Number} speed typically 0-2
   */
  setSpeed(speed) {
    this.#speed = speed;
    if (this.#activeAction) {
      this.#activeAction.setEffectiveTimeScale(this.#speed);
    }
  }

  dispose() {
    // dispose mixer, see docs
    // https://threejs.org/docs/#api/en/animation/AnimationMixer.uncacheClip
    if (this.#activeAction) {
      this.#activeAction.stop();
    }

    this.#mixer.stopAllAction();
    for (let i = 0; i < this.#clips.length; i += 1) {
      const clip = this.#clips[i];
      this.#mixer.uncacheClip(clip);
      this.#mixer.uncacheAction(clip);
    }
    this.#mixer.uncacheRoot(this.#scene);

    // this.#mixer.removeEventListener('loop', this.#onLoopEnd);
    this.#mixer.removeEventListener('finished', this.#animationFinishHandler);

    this.#scene = null;
    this.#clips = [];
    this.#actions = null;
    this.#mixer = null;
    this.#prevTime = null;
    this.#activeAction = null;
    this.#previousAction = null;
  }
}
