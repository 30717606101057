import waitUntil from '../utils/waitUntil';
import ErrorCode from '../ErrorCode';
import LoggingLevel from '../LoggingLevel';
import OS from '../utils/os';
import Mic from './lib/Mic';

window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.RTCPeerConnection =
  window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia;

class Audio {
  constructor() {
    this.context = null;
    this.gainNode = null;
    this.previousGainNodeValue = null;

    this.audioUpxsFixElement = null;
    this.mic = null;

    this.micRequired = true;
    this.waitingToConnect = false;

    this.fixAudioContextHandler = null;

    this.mediaStreamDestination = null;

    // media devices
    // started working off of
    // https://www.w3.org/TR/mediacapture-streams/#dom-mediadevices
    this.storedInputDeviceList = [];

    this.localStream = null; // silent track or mic

    this.useCurrentTime = false;
    this.baseLatency = 0;

    if (!Audio.instance) {
      Audio.instance = this;
    }
  }

  logger = null;

  // Time elapsed since context was created in milliseconds.
  get currentTimeMs() {
    return this.context.currentTime * 1000;
  }

  // Current playtime of audio context output in milliseconds including hardware latency.
  get outputCurrentTimeMs() {
    const { useCurrentTime, baseLatency, context } = this;

    if (useCurrentTime) {
      // see ZEN - 1788,  -context.baseLatency -0.02 ~= -30 ms
      const currentTime = context.currentTime - baseLatency - 0.02;

      return currentTime * 1000;
    }

    // Chrome, Firefox
    return context.getOutputTimestamp().contextTime * 1000;
  }

  async getMic(deviceId = 'default') {
    const { mic, logger } = this;

    try {
      await mic.useDevice(deviceId);

      this.setLocalStream(mic.outputStream);

      return ErrorCode.SUCCESS;
    } catch (error) {
      logger.error(new Error('Audio | Failed to get mic stream.', { cause: error }));
    }

    return ErrorCode.NO_MIC_PERMISSION;
  }

  setLocalStream(stream, silentTrack = false) {
    this.localStream = stream;
    if (!silentTrack) {
      this.stopSilentTrack();
    }
  }

  fixAudioContext() {
    this.context.resume();
    if (this.context.state === 'running') {
      document.removeEventListener('touchstart', this.fixAudioContextHandler);
      document.removeEventListener('touchend', this.fixAudioContextHandler);
      document.removeEventListener('click', this.fixAudioContextHandler);
    }
  }

  start(audioUpxsFixElement) {
    // immediately create audio context on sessionRequest called on page click
    if (this.context === null) {
      this.context = new AudioContext({
        latencyHint: 'interactive',
        sampleRate: 48000,
      });
      this.gainNode = this.context.createGain();
      this.setGain(1, 0);
      this.previousGainNodeValue = 1;

      // now only auto-start with no mic-required requires page gesture
      if (this.context.state !== 'running') {
        this.fixAudioContextHandler = () => this.fixAudioContext();
        document.addEventListener('touchstart', this.fixAudioContextHandler);
        document.addEventListener('touchend', this.fixAudioContextHandler);
        document.addEventListener('click', this.fixAudioContextHandler);
      }
    }

    this.mic = new Mic({ context: this.context });
    this.audioUpxsFixElement = audioUpxsFixElement;
    this.storedInputDeviceList = [];
  }

  async resume(statusCallback) {
    let audioContextRetries = 0;
    // call context resume once per second. this works more reliably than gesture
    const tryResume = async () => {
      audioContextRetries += 1;
      if (this.context.state !== 'running') {
        this.context.resume();

        if (audioContextRetries <= 7) {
          statusCallback(LoggingLevel.INFO, ErrorCode.NO_RUNNING_AUDIO_CONTEXT);
        } else if (audioContextRetries <= 25) {
          // wait 7 seconds before triggering gesture warning
          statusCallback(LoggingLevel.WARNING, ErrorCode.NO_RUNNING_AUDIO_CONTEXT);
        } else {
          // disconnect after 25 seconds. Room about to be deleted
          statusCallback(LoggingLevel.ERROR, ErrorCode.NO_RUNNING_AUDIO_CONTEXT);
        }
      }
    };

    const resumed = setInterval(tryResume, 1000);

    await tryResume();

    this.waitingToConnect = true;
    await waitUntil(() => this.context.state === 'running' || this.waitingToConnect === false);
    clearInterval(resumed);

    if (this.context.state !== 'running') {
      return ErrorCode.NO_RUNNING_AUDIO_CONTEXT;
    }

    this.gainNode.connect(this.context.destination);

    if (typeof this.context.getOutputTimestamp === 'undefined' || OS.isApple()) {
      this.useCurrentTime = true;
    }
    if (this.context.baseLatency) {
      this.baseLatency = this.context.baseLatency;
    }

    return ErrorCode.SUCCESS;
  }

  createSilentTrack() {
    // potential alternative
    // https://github.com/edoudou/create-silent-audio/blob/master/index.js
    // silent because we apply gain node of 0; VAD on CPI does not detect.
    this.oscillator = this.context.createOscillator();
    this.oscillatorGainNode = this.context.createGain();
    this.oscillatorGainNode.gain.value = 0;

    this.oscillator.connect(this.oscillatorGainNode);

    this.silentMic = this.context.createMediaStreamDestination();
    this.oscillatorGainNode.connect(this.silentMic);
    this.oscillator.start();
    this.setLocalStream(this.silentMic.stream, true);
  }

  stopSilentTrack() {
    if (this.oscillator) {
      this.oscillator.stop();
    }
  }

  playUpxsStream(stream) {
    const { context, gainNode } = this;

    const source = context.createMediaStreamSource(stream);

    // Fix needed for Chrome to have sound output from UPXS stream.
    // UPXS audio stream needs to be connected with a muted audio element
    // in addition to the main gainNode.
    // https://issues.chromium.org/issues/40094084
    this.audioUpxsFixElement.srcObject = stream;

    source.connect(gainNode);
  }

  // Ramp down and pause output audio. This fixes iOS stuck audio and popping.
  async pauseAudioOut() {
    this.setGain(0, 0.3);

    await new Promise((resolve) => {
      setTimeout(resolve, 350);
    });
  }

  // Always use this method to change gain. Setting the gain directly will cause audio issues.
  setGain(value, timeConstant) {
    const { gainNode, context } = this;

    gainNode.gain.setTargetAtTime(value, context.currentTime, timeConstant);
  }

  async dispose() {
    const { logger } = this;

    this.waitingToConnect = false;

    this.mic?.stop();
    this.mic = null;
    this.localStream = null;

    // potential these haven't been removed in Safari Edge case
    document.removeEventListener('touchstart', this.fixAudioContextHandler);
    document.removeEventListener('touchend', this.fixAudioContextHandler);
    document.removeEventListener('click', this.fixAudioContextHandler);

    this.storedInputDeviceList = [];

    await this.pauseAudioOut();

    this.stopSilentTrack();

    try {
      if (this.context) {
        this.context.suspend();
      }
    } catch (error) {
      logger.warn(`Audio | Unable to suspend audio context. ${error}`);
    }
  }
}

const instance = new Audio();

export default instance;
