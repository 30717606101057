/**
 * Operating system detection handler
 */
class OS {
  /**
   * class to determine browser Operating System
   * @param {String} appVersion navigator.appVersion
   * @param {String} userAgent navigator.userAgent
   * @param {String} platform navigator.platform
   */
  constructor(appVersion, userAgent, platform) {
    this.OSName = 'UnknownOS';
    this.appVersion = appVersion;
    this.isWindows = appVersion.indexOf('Win') !== -1;
    this.isMac = appVersion.indexOf('Mac') !== -1;
    this.isUnix = appVersion.indexOf('X11') !== -1;
    this.isLinux = appVersion.indexOf('Linux') !== -1;
    this.isAndroid = /(android)/i.test(userAgent);
    this.isIOS = !!platform && /iPad|iPhone|iPod/.test(platform);

    if (this.isWindows) this.OSName = 'Windows';
    if (this.isMac) this.OSName = 'MacOS';
    if (this.isUnix) this.OSName = 'UNIX';
    if (this.isLinux) this.OSName = 'Linux';
    if (this.isAndroid) this.OSName = 'Android';
    if (this.isIOS) this.OSName = 'iOS';
  }

  /**
   * get Operating System
   * @return {string}
   */
  getOS() {
    return this.OSName;
  }

  /**
   * is Operating system iOS
   * @return {Boolean}
   */
  isIOS() {
    return this.isIOS;
  }

  /**
   * is Operating system Android
   * @return {Boolean}
   */
  isAndroid() {
    return this.isAndroid;
  }

  /**
   * is Operating system Mac
   * @return {Boolean}
   */
  isMac() {
    return this.isMac;
  }

  /**
   * is Apple Operating system
   * @return {Boolean}
   */
  isApple() {
    return (this.isMac || this.isIOS);
  }
}

const instance = new OS(navigator.appVersion, navigator.userAgent, navigator.platform);

export default instance;
