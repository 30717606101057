import * as THREE from 'three';
import convertToRadians from '../../../../utils/angle';
import getBoundingBox from '../../../../rendering/lib/helpers/getBoundingBox';

export default class CameraDefault extends THREE.PerspectiveCamera {
  constructor({ scene }) {
    const fov = 23;
    const aspect = 1;
    const near = 0.1;
    const far = 500;
    const heightFactor = 0.7;
    const boundingBox = getBoundingBox(scene);
    const height = heightFactor * (boundingBox.max.y - boundingBox.min.y) + boundingBox.min.y;
    const distance =
      boundingBox.max.z + (boundingBox.max.y - boundingBox.min.y) /
      Math.sin(convertToRadians(fov)) / 1.2;

    super(fov, aspect, near, far);

    this.position.set(0, height, distance);
    this.lookAt(0, height, 0);
  }
}
