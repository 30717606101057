import * as THREE from 'three';

import cacheFile from './lib/cacheFile';
import loadGlb from './lib/loadGlb';
import Character from './Character/Character';
import SceneObject from './SceneObject/SceneObject';
import CameraRpm from './camera/CameraRpm';
import CameraDefault from './camera/CameraDefault';

async function loadFiles(assets) {
  const files = assets.map(({ file }) => file);

  // Start file download and put it into cache.
  files.forEach((file) => {
    /* eslint-disable-next-line no-param-reassign */
    file.cacheRequest = cacheFile(file);
  });

  // Wait all files to be put into cache.
  for (const file of files) {
    const { characterModelCacheUrl } = await file.cacheRequest;

    file.cacheUrl = characterModelCacheUrl;
  }

  // Start loading files with loader.
  files.forEach((file) => {
    /* eslint-disable-next-line no-param-reassign */
    file.loader = loadGlb(file.cacheUrl);
  });

  // Wait all files to be put into cache.
  for (const asset of assets) {
    asset.model = await asset.file.loader;
    asset.scene = asset.model.scene;
  }
}

function applyProperties(object, props) {
  if (!props) {
    return;
  }

  // Apply properties to glb scene.
  for (const [key, value] of Object.entries(props)) {
    Object.assign(object[key], value);
  }
}

async function parseCharacters({
  assets,
  playbackQueueParams,
  sceneObjectParams,
  logger,
}) {
  const characterAssets = assets.filter(({ type }) => type === 'Character');

  await loadFiles(characterAssets);

  const characters = characterAssets.map((asset) => {
    const {
      scene,
      transform,
      id,
      model,
      file,
    } = asset;

    applyProperties(scene, transform);

    Object.assign(sceneObjectParams, {
      id,
      model,
      file,
    });

    return new Character({
      sceneObjectParams,
      sgcomAnimationNodes: asset.file.sgcomAnimationNodes,
      playbackQueueParams,
      logger,
    });
  });

  const characterInitPromises = characters.map((character) => character.init());

  await Promise.all(characterInitPromises);

  return characters;
}

async function parseSceneObjects({
  assets,
  sceneObjectParams,
}) {
  const sceneObjects = assets.filter(({ type }) => type === 'SceneObject');

  await loadFiles(sceneObjects);

  return sceneObjects.map((asset) => {
    const {
      scene,
      transform,
      id,
      model,
      file,
    } = asset;

    // Standardize object structure.
    model.lights ??= [];

    applyProperties(scene, transform);

    Object.assign(sceneObjectParams, {
      id,
      model,
      file,
    });

    return new SceneObject(sceneObjectParams);
  });
}

function parseCharacterPlaceholders(assets) {
  return assets.filter(({ type }) => type === 'CharacterPlaceholder');
}

function parseCameras({ assets, characters, sceneObjects }) {
  // Create default camera.
  let defaultCamera;
  const rpmCharacter = characters.find(({ model }) => model.isRpmModel);

  if (rpmCharacter) {
    const {
      isRpmFullBody,
      rpmSkinnedMeshAvatar,
      rpmSkinnedMeshHead,
    } = rpmCharacter.model;

    if (isRpmFullBody) {
      if (rpmSkinnedMeshAvatar) {
        // Older full body RPM characters have only one skinned mesh.
        defaultCamera = new CameraRpm({
          rpmSkinnedMesh: rpmSkinnedMeshAvatar,
          cameraPosYOffset: -0.2,
        });
      } else if (rpmSkinnedMeshHead) {
        // Newer full Body RPM models have a head skinned mesh.
        defaultCamera = new CameraRpm({
          rpmSkinnedMesh: rpmSkinnedMeshHead,
          cameraPosYOffset: -0.15,
        });
      }
    }
  } else {
    defaultCamera = new CameraDefault(characters[0].model);
  }

  // Parse cameras from sceneConfig.
  const perspectiveCameraAssets =
    assets
      .filter(({ type }) => type === 'PerspectiveCamera')
      .map((cameraAsset) => {
        const {
          fov,
          aspect,
          near,
          far,
          position,
          lookAt,
        } = cameraAsset;
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

        camera.position.set(position.x, position.y, position.z);
        camera.lookAt(lookAt.x, lookAt.y, lookAt.z);

        return camera;
      });

  // Get cameras from character and sceneObjects.
  const sceneObjectCameras =
    [...characters, ...sceneObjects]
      .map(({ model }) => model.cameras)
      .flat();

  const cameras = [defaultCamera, ...perspectiveCameraAssets, ...sceneObjectCameras];

  return cameras;
}

function parseLights({ assets, characters, sceneObjects }) {
  const lightTypes = ['AmbientLight', 'DirectionalLight', 'SpotLight', 'HemisphereLight'];
  const lights = assets.filter(({ type }) => lightTypes.includes(type));
  const sceneObjectLights =
    [...characters, ...sceneObjects]
      .find(({ model }) => model.lights.length);

  if (lights.length || sceneObjectLights) {
    return lights;
  }

  // Add default lights.
  lights.push({
    type: 'AmbientLight',
    intensity: 0.8,
    color: 0xffffff,
  });

  lights.push({
    type: 'DirectionalLight',
    shadows: true,
    intensity: 5,
    color: 0xffffff,
    rotation: { x: -45, y: 172 },
  });

  const keyLight = {
    type: 'DirectionalLight',
    shadows: true,
    intensity: 2.5,
    color: 0xffffff,
    rotation: { x: -8, y: 25 },
  };

  const characterRpmBeard = characters.find(({ model }) => model.rpmBeard);

  if (characterRpmBeard) {
    // RPM characters with beard uses specific shadow bias value.
    const rpmBeardShadowBias = -0.000003;

    keyLight.bias = rpmBeardShadowBias;
  }

  lights.push(keyLight);

  return lights;
}

/**
 * Parse scene config, glb assets, character placeholders, lights, and create custom camera.
 * @param {Object} params Scene config object.
 * @param {Object} params.sceneConfigData Scene config data object.
 * @param {Object} params.sceneObjectParams Params passed to sceneObject constructors.
 * @param {Object} params.playbackQueueParams Params passed to character constructors.
 * @returns {Object} Scene config assets object.
 */
export default async function parseSceneConfig({
  sceneConfigData,
  sceneObjectParams,
  playbackQueueParams,
  logger,
}) {
  const { assets } = sceneConfigData;

  const characters = await parseCharacters({
    assets,
    sceneObjectParams,
    playbackQueueParams,
    logger,
  });
  const sceneObjects = await parseSceneObjects({
    assets,
    sceneObjectParams,
  });
  const characterPlaceholders = parseCharacterPlaceholders(assets);
  const cameras = parseCameras({
    assets,
    characters,
    sceneObjects,
  });
  const lights = parseLights({
    assets,
    characters,
    sceneObjects,
  });

  return {
    characters,
    sceneObjects,
    characterPlaceholders,
    cameras,
    lights,
  };
}
