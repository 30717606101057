// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { flatbuffers } from 'flatbuffers';

import { Payload, unionToPayload, unionListToPayload } from '../fb/payload.js';


export class Packet {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Packet {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPacket(bb:flatbuffers.ByteBuffer, obj?:Packet):Packet {
  return (obj || new Packet()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPacket(bb:flatbuffers.ByteBuffer, obj?:Packet):Packet {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Packet()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

payloadType():Payload {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : Payload.NONE;
}

payload<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

static startPacket(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addPayloadType(builder:flatbuffers.Builder, payloadType:Payload) {
  builder.addFieldInt8(0, payloadType, Payload.NONE);
}

static addPayload(builder:flatbuffers.Builder, payloadOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, payloadOffset, 0);
}

static endPacket(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishPacketBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedPacketBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createPacket(builder:flatbuffers.Builder, payloadType:Payload, payloadOffset:flatbuffers.Offset):flatbuffers.Offset {
  Packet.startPacket(builder);
  Packet.addPayloadType(builder, payloadType);
  Packet.addPayload(builder, payloadOffset);
  return Packet.endPacket(builder);
}
}
