/* eslint-disable camelcase */
import JSZip from 'jszip';

/**
 * caching of models on character ID and hash
 * model download url lasts ~20 minutes.
 * model hash is crc on glb file
 */
class Cache {
  /**
   * class for caching models
   */
  constructor() {
    this.cacheAvailable = 'caches' in window.self;
    window.URL = window.URL || window.webkitURL;
    if (this.cacheAvailable) {
      this.cache = 'sg-cache';
    }

    if (!Cache.instance) {
      Cache.instance = this;
    }
    // Initialize object
    return Cache.instance;
  }

  logger = null;

  /**
   * get character from cache API
   * from characterID and Hash
   * @param {string} chr_id character id
   * @param {string} chr_file_hash hash code
   * @return gltf+json/gltf-binary file
   */
  async getCIDHash(chr_id, chr_file_hash) {
    const { logger } = this;

    const cIDHash = `${chr_id}-${chr_file_hash}`;
    let cache;
    let request;
    let openCache;
    let blobUrl = false;

    try {
      cache = await caches.open(this.cache);
    } catch (error) {
      logger.error(new Error('Cache | Unable to open cache API.', { cause: error }));

      return false;
    }

    try {
      request = new Request(cIDHash);
    } catch (error) {
      logger.error(new Error('Cache | Unable to create cache request.', { cause: error }));

      return false;
    }

    try {
      openCache = await cache.match(request);
    } catch (error) {
      logger.error(new Error('Cache | Unable to match cache request.', { cause: error }));

      return false;
    }

    if (openCache) {
      const zip = openCache.url.includes('.zip');
      let modelFile = null;
      if (zip) {
        try {
          const data = await openCache.arrayBuffer();
          const zipData = await JSZip.loadAsync(data);
          Object.entries(zipData.files).forEach(async ([key]) => {
            if (key.includes('.glb') || key.includes('.json') || key.includes('.gltf')) {
              modelFile = key;
            }
          });
          const blob = await zipData.file(modelFile).async('blob');
          const glb = modelFile.includes('.glb');
          const gltf = modelFile.includes('.gltf');
          const json = modelFile.includes('.json');
          if (glb) {
            const glbBlob = new Blob([blob], { type: 'model/gltf-binary' });
            blobUrl = window.URL.createObjectURL(glbBlob);
          } else if (gltf || json) {
            const gltfBlob = new Blob([blob], { type: 'model/gltf+json' });
            blobUrl = window.URL.createObjectURL(gltfBlob);
          } else {
            throw new Error('Unknown file type. Only glb and gltf supported.');
          }
        } catch (error) {
          logger.error(new Error('Cache | Unable to extract model from zip file.', { cause: error }));
        }
      } else {
        const gltf = openCache.url.includes('.gltf');
        const glb = openCache.url.includes('.glb');
        const json = openCache.url.includes('.json');
        try {
          if (glb) {
            const data = await openCache.arrayBuffer();
            const glbBlob = new Blob([data], { type: 'model/gltf-binary' });
            blobUrl = window.URL.createObjectURL(glbBlob);
          } else if (gltf || json) {
            const data = await openCache.text();
            const gltfBlob = new Blob([data], { type: 'model/gltf+json' });
            blobUrl = window.URL.createObjectURL(gltfBlob);
          } else {
            throw new Error('Unknown file type. Only glb and gltf supported.');
          }
        } catch (error) {
          logger.error(new Error('Cache | Unable to create blob url.', { cause: error }));
        }
      }
    }
    return blobUrl;
  }

  /**
   * fetch download url and put into cache api as character id + hash
   * return blob url
   * @param {string} chr_id character id
   * @param {string} chr_file_hash hash code
   * @param {string} url download
   * @return gltf+json/gltf-binary file
   */
  async putCIDHashUrl(chr_id, chr_file_hash, url) {
    const { logger } = this;

    const cIDHash = `${chr_id}-${chr_file_hash}`;
    let cache;
    let request;
    let blobUrl = false;

    try {
      cache = await caches.open(this.cache);
    } catch (error) {
      logger.error(new Error('Cache | Unable to open cache API.', { cause: error }));

      return url;
    }

    try {
      request = new Request(cIDHash);
    } catch (error) {
      logger.error(new Error('Cache | Unable to create cache request.', { cause: error }));

      return url;
    }

    try {
      const requestURL = new Request(url);
      const requestURLResponse = await fetch(requestURL);
      if (requestURLResponse.body) {
        await cache.put(request, requestURLResponse.clone());
        const zip = requestURLResponse.url.includes('.zip');
        let modelFile = null;
        if (zip) {
          try {
            const data = await requestURLResponse.arrayBuffer();
            const zipData = await JSZip.loadAsync(data);
            Object.entries(zipData.files).forEach(async ([key]) => {
              if (key.includes('.glb') || key.includes('.json') || key.includes('.gltf')) {
                modelFile = key;
              }
            });
            const blob = await zipData.file(modelFile).async('blob');
            const glb = modelFile.includes('.glb');
            const gltf = modelFile.includes('.gltf');
            const json = modelFile.includes('.json');
            try {
              if (glb) {
                const glbBlob = new Blob([blob], { type: 'model/gltf-binary' });
                blobUrl = window.URL.createObjectURL(glbBlob);
              } else if (gltf || json) {
                const gltfBlob = new Blob([blob], { type: 'model/gltf+json' });
                blobUrl = window.URL.createObjectURL(gltfBlob);
              } else {
                throw new Error('Unknown file type. Only glb and gltf supported.');
              }
            } catch (error) {
              logger.error(new Error('Cache | Unable to create blob URL from model in zip file.', { cause: error }));
            }
          } catch (error) {
            logger.error(new Error('Cache | Unable to extract model from zip file.', { cause: error }));
          }
        } else {
          try {
            const glb = requestURLResponse.url.includes('.glb');
            const gltf = requestURLResponse.url.includes('.gltf');
            const json = requestURLResponse.url.includes('.json');
            if (glb) {
              const data = await requestURLResponse.arrayBuffer();
              const glbBlob = new Blob([data], { type: 'model/gltf-binary' });
              blobUrl = window.URL.createObjectURL(glbBlob);
            } else if (gltf || json) {
              const data = await requestURLResponse.arrayBuffer();
              const gltfBlob = new Blob([data], { type: 'model/gltf+json' });
              blobUrl = window.URL.createObjectURL(gltfBlob);
            } else {
              throw new Error('Unknown file type. Only glb and gltf supported.');
            }
          } catch (error) {
            logger.error(new Error('Cache | Unable to create blob url.', { cause: error }));
          }
        }
        return blobUrl;
      }

      throw new Error('Failed to download model.');
    } catch (error) {
      logger.error(new Error('Cache | Unable to put character into cache.', { cause: error }));

      return url;
    }
  }

  /**
   * delete entire cache
   */
  async deleteCache() {
    // Get a list of all of the caches for this origin
    const cacheNames = await this.caches.keys();
    cacheNames.forEach(async (element) => {
      await caches.delete(element);
    });
  }
}

const instance = new Cache();

export default instance;
