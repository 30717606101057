import { RapportSceneStatus } from './SceneStatus';
import getBoolean from '../utils/boolean';

/**
 * Progress Bar UI
 * Handle visibility and update
 */
class ProgressBar {
  /**
   * @param {slideBar} progressBar Html Element
   */
  constructor(progressBar) {
    this.progressBar = progressBar;
    this.progressBar.style.display = 'none'; // default is hide

    this.visible = false;
    this.progress = 0;
  }

  setVisibility(status) {
    const newStatus = getBoolean(status);

    if (newStatus) this.show();
    else this.hide();
  }

  show() {
    this.visible = true;
    this.progressBar.style.display = 'block';
  }

  hide() {
    this.visible = false;
    this.progressBar.style.display = 'none';
  }

  update(status) {
    if (this.visible) {
      switch (status) {
        case RapportSceneStatus.LOADING_START:
          this.progress = 0;
          break;
        case RapportSceneStatus.DISCONNECTED:
          this.progress = null;
          break;
        case RapportSceneStatus.SETTING_UP_SESSION:
          this.progress = 10;
          break;
        case RapportSceneStatus.CREATING_ROOM:
        case RapportSceneStatus.RECEIVE_MODEL:
        case RapportSceneStatus.DOWNLOADING_MODEL:
        case RapportSceneStatus.RECEIVED_INVITE:
        case RapportSceneStatus.ROOM_CREATED:
        case RapportSceneStatus.CACHED_MODEL:
          // These events happen out of sequence to each other.
          // If downloading is taking a time, each second 3rd of the remaining progress is added.
          // Formula is indicative to UI that something is happening. Not a scientific measurement.
          this.progress += (90 - this.progress) / 3;
          break;
        case RapportSceneStatus.JOINING_ROOM:
          this.progress = 90;
          break;
        case RapportSceneStatus.LOADING_DONE:
          this.progress = 100;
          break;
        case RapportSceneStatus.RESIZE:
        case RapportSceneStatus.RENDERING:
          // do nothing
          break;
        default:
          this.progress = 0;
      }
      if (this.progress === 100) {
        this.hide();
      } else if (this.progress === null) {
        this.progressBar.value = null;
      } else {
        this.progressBar.value = parseInt(this.progress, 10);
      }
    }
  }
}

export default ProgressBar;
