import * as THREE from 'three';

import getObject3dCenter from '../../../../../../rendering/lib/helpers/getObject3dCenter';
import validatePosition from '../../../../../../utils/validatePosition';
import validateScreenPosition from '../../../../../../utils/validateScreenPosition';

export default function setModelLookAt(params, canvas, activeCamera, scene) {
  let modelLookAt = null;
  let screenPosition = null;
  let virtualScreenSize = null;
  let canvasPosition = null;
  let canvasSize = null;
  let modelLookAtDepth = 0.4;

  if (params === 'camera') {
    // TODO Instead of sceneCenter model eye position should be used.
    const activeCameraPosition = activeCamera.position;
    const sceneCenter = getObject3dCenter(scene);

    modelLookAt = new THREE.Vector3().subVectors(activeCameraPosition, sceneCenter);
  } else {
    let parsedParams;
    if (typeof params === 'string') {
      try {
        parsedParams = JSON.parse(params);
      } catch (error) {
        throw new Error('Failed to parse JSON');
      }
    } else {
      parsedParams = params;
    }

    if (parsedParams instanceof Object) {
      if (parsedParams.position !== undefined) {
        if (validatePosition(parsedParams.position)) {
          modelLookAt = parsedParams.position;
        } else {
          throw new Error('Not a valid position');
        }
      } if (parsedParams.screenPosition !== undefined) {
        // check if screenSize is set, we don't use window.InnerWidth
        if (validateScreenPosition(parsedParams.screenPosition)) {
          screenPosition = parsedParams.screenPosition;
        } else {
          throw new Error('Not a 2D position');
        }

        if (parsedParams.screenSize !== undefined) {
          if (parsedParams.screenSize.width !== undefined &&
            parsedParams.screenSize.height !== undefined) {
            virtualScreenSize = parsedParams.screenSize;
          } else {
            throw new Error('Not a 2D size');
          }
        }

        if (parsedParams.canvasPosition !== undefined) {
          if (validateScreenPosition(parsedParams.canvasPosition)) {
            canvasPosition = parsedParams.canvasPosition;
          } else {
            throw new Error('Not a 2D position');
          }
        }

        if (parsedParams.canvasSize !== undefined) {
          if (parsedParams.canvasSize.width !== undefined &&
            parsedParams.canvasSize.height !== undefined) {
            canvasSize = parsedParams.canvasSize;
          } else {
            throw new Error('Not a 2D size');
          }
        }
        let screenSize = {};

        if (canvasSize === null) {
          const boundingBox = canvas.getBoundingClientRect();
          canvasSize = {
            width: boundingBox.width,
            height: boundingBox.height,
          };
        }

        if (canvasPosition === null) {
          const boundingBox = canvas.getBoundingClientRect();
          canvasPosition = {
            x: boundingBox.x,
            y: boundingBox.y,
          };
        }

        if (virtualScreenSize === null) {
          screenSize.width = window.innerWidth;
          screenSize.height = window.innerHeight;
        } else {
          screenSize = virtualScreenSize;
        }

        const center = {
          x: canvasPosition.x + canvasSize.width / 2,
          y: canvasPosition.y + canvasSize.height / 2,
        };

        let x = 0;
        let y = 0;
        if (screenPosition.x > center.x) {
          x += (screenPosition.x - center.x) / (screenSize.width - center.x) / 2;
        } else {
          x -= (center.x - screenPosition.x) / (center.x) / 2;
        }
        if (screenPosition.y > center.y) {
          y -= (screenPosition.y - center.y) / (screenSize.height - center.y) / 2;
        } else {
          y += (center.y - screenPosition.y) / (center.y) / 2;
        }

        if (parsedParams.depth !== undefined) {
          modelLookAtDepth = parsedParams.depth;
        }
        modelLookAt = { x, y, z: modelLookAtDepth };
      }
    }
  }

  return modelLookAt;
}
