import { flatbuffers } from 'flatbuffers';
import decodeFloat16 from './decodeFloat16';
import * as fb from '../fb/index.ts';

export default class FlatbufferUnpacker {
  constructor(params) {
    Object.assign(this, params);
  }

  rxbuf = [];

  droppedPackets = 0;

  rxPackets = 0;

  lastTs = 0;

  lastDroppedPackets = 0;

  lastRxPackets = 0;

  lastPacketLossPerc = 0;

  logger = null;

  reassemblePacket(packetFragment) {
    // Reassemble package
    // There is a bug in an internal browser webrtc modules which hangs the browser
    // if the unreliable transfer packet is too large, therefor we need to limit the
    // packet size by fragmenting it then reassemble the packet here.
    // This code can be removed later when all supported browsers fix this issue.
    const { rxbuf } = this;
    const view = new DataView(packetFragment);
    const d = new Uint8Array(packetFragment);
    let reassembledPacket;
    if (d[0] === 0) {
      const sn = view.getUint32(1, true);
      reassembledPacket = packetFragment.slice(5);
      let j = 0;
      for (let i = 0; i < rxbuf.length; i += 1) {
        if (rxbuf[i].end > sn) {
          rxbuf[j] = rxbuf[i];
          j += 1;
        }
      }
      this.rxbuf = rxbuf.slice(0, j);
    } else if (d[0] === 1) {
      const sn = view.getUint32(1, true);
      const start = view.getUint32(5, true);
      const end = view.getUint32(9, true);
      const pktdata = d.slice(13);
      let thisRxQ = null;
      for (let i = 0; i < rxbuf.length; i += 1) {
        if (rxbuf[i].start === start && rxbuf[i].end === end) {
          thisRxQ = rxbuf[i];
        }
      }
      if (thisRxQ == null) {
        thisRxQ = { pkts: [], start, end };
        rxbuf.push(thisRxQ);
      }
      // insert packet in rxq
      const pkt = { seqnum: sn, data: pktdata };
      let done = false;
      for (let i = 0; i < thisRxQ.length; i += 1) {
        if (thisRxQ[i].seqnum > sn) {
          // insert before i
          thisRxQ.pkts.splice(i, 0, pkt);
          done = true;
          break;
        }
      }
      if (!done) {
        thisRxQ.pkts.push(pkt);
      }
      if (thisRxQ.pkts.length !== end - start + 1) {
        return null;
      }
      let payloadSize = 0;
      for (let i = 0; i < thisRxQ.pkts.length; i += 1) {
        payloadSize += thisRxQ.pkts[i].data.length;
      }
      const payload = new Uint8Array(payloadSize);
      let j = 0;
      for (let i = 0; i < thisRxQ.pkts.length; i += 1) {
        payload.set(thisRxQ.pkts[i].data, j);
        j += thisRxQ.pkts[i].data.length;
      }
      reassembledPacket = payload.buffer;
      // delete all fragments <= endNum
      j = 0;
      for (let i = 0; i < rxbuf.length; i += 1) {
        if (rxbuf[i].end > end) {
          rxbuf[j] = rxbuf[i];
          j += 1;
        }
      }
      this.rxbuf = rxbuf.slice(0, j);
    }

    return reassembledPacket;
  }

  unpack(packetFragment) {
    const { logger } = this;

    const reassembledPacket = this.reassemblePacket(packetFragment);

    if (!reassembledPacket) {
      return null;
    }

    // unpack flatbuffers
    const bytebuf = new flatbuffers.ByteBuffer(new Uint8Array(reassembledPacket));
    const p = fb.Packet.getRootAsPacket(bytebuf);
    const fb_payload_type = p.payloadType();
    if (fb_payload_type !== fb.Payload.PlayerData) {
      logger.debug(`FlatbufferUnpacker | Unhandled payload: ${fb_payload_type}`);

      return null;
    }
    const fb_pd = p.payload(new fb.PlayerData());
    const ts = fb_pd.timeMs();
    const tts = fb_pd.tts();
    const rmsv = fb_pd.rmsv();
    let animData = fb_pd.animDataArray();
    const opusData = fb_pd.opusDataArray();
    const animFloat16 = fb_pd.animFloat16Array();
    const animationStreamId = fb_pd.animationStreamId();

    if (animFloat16 != null && animFloat16.length !== 0) {
      animData = new Float32Array(animFloat16.length);
      for (let i = 0; i < animData.length; i += 1) {
        animData[i] = decodeFloat16(animFloat16[i]);
      }
    }

    this.rxPackets += 1;

    // Send packet drop percentage to Opus encoder for FEC
    //
    // Note: this uses the sum of reassembled packets, versus not reassembled
    // packets, as an estimator of the real rate of packet loss. The precise rate
    // of network packet loss is not knowable here, due to some received network
    // packets being fragments of a larger packet that should be reassembled, and
    // some received network packets being complete and not requiring reassembly.
    // As the fragmentation algorithm does not include sequence numbers, it is
    // not possible to calculate network packet loss.
    //
    // Calculate drop rate over a minimum 1 second window
    const {
      lastTs,
      droppedPackets,
      lastDroppedPackets,
      lastPacketLossPerc,
      rxPackets,
      lastRxPackets,
    } = this;

    if (ts >= lastTs + 1000) {
      // the dropped packet value should be a percentage in [0, 100]
      const drop = droppedPackets - lastDroppedPackets;
      const rx = rxPackets - lastRxPackets;
      const perc = Math.round((100 * drop) / (drop + rx));
      if (perc !== lastPacketLossPerc) {
        this.lastPacketLossPerc = perc;
      }
      this.lastTs = ts;
      this.lastDroppedPackets = droppedPackets;
      this.lastRxPackets = rxPackets;
    }

    let animationData;

    if (animData != null && animData.length !== 0) {
      animationData = {
        animData,
        tts,
        rmsv,
      };
    }

    const encodedAudioPacket = { packet: opusData, playTime: ts };

    return { animationStreamId, animationData, encodedAudioPacket };
  }
}
