import * as THREE from 'three';

// max tilt back back and forth as 50 deg
//  https://www.livestrong.com/article/95456-normal-neck-range-motion/
const maxHeadPitchRad = THREE.MathUtils.degToRad(50);

// using max left right as 70 deg
// https://www.guildfordchiropractic.co.uk/post/how-far-should-i-be-able-to-turn-my-head
const maxHeadTurnRad = THREE.MathUtils.degToRad(70);

// eye rotation limits
// https://entokey.com/three-dimensional-rotations-of-the-eye/
// hard coded limits use in model

// 40deg movement up, left, right, down
const maxEyeRotationRad = THREE.MathUtils.degToRad(40);

// see ZEN ticket 2832
const blendShapeUpLimit = 0.5; // 0.5 blendshape looks like 40 deg
const blendShapeLimit = 1; // Used for In Out Down

// Megan Tony and RPM character head and eye joints
const headLookAtJoints = ['Head', 'head_jnt', 'Eye_jnt_L', 'Eye_jnt_R', 'l_eye_JNT', 'r_eye_JNT', 'head_JNT', 'Head_jnt',
  'L_Eye_jnt', 'R_Eye_jnt', 'LeftEye', 'RightEye', 'CC_Base_L_Eye', 'CC_Base_R_Eye', 'CC_Base_Head'];

// RPM eye blendShapes
const headLookAtBlendShapes = ['EyeLeft_ncl1_2', 'EyeRight_ncl1_2'];

/**
 * get head turn,
 * subtracts max Eye rotation from angle
 * @param {radians} angle
 * @returns newY
 */
function getHeadTurn(angle) {
  let newY = angle;
  if (newY > maxEyeRotationRad) {
    newY -= maxEyeRotationRad;
  } else if (newY < -maxEyeRotationRad) {
    newY += maxEyeRotationRad;
  } else {
    newY = 0;
  }

  // limit turn
  if (Math.abs(newY) > maxHeadTurnRad) {
    newY = Math.sign(newY) * maxHeadTurnRad;
  }
  return newY;
}

/**
 * get head turn,
 * subtracts max Eye rotation from angle
 * @param {radians} angle
 * @returns newX
 */
function getHeadTilt(angle) {
  let newX = angle;
  if (newX > maxEyeRotationRad) {
    newX -= maxEyeRotationRad;
  } else if (newX < -maxEyeRotationRad) {
    newX += maxEyeRotationRad;
  } else {
    newX = 0;
  }

  // Limit head turn
  if (Math.abs(newX) > maxHeadPitchRad) {
    newX = Math.sign(newX) * maxHeadPitchRad;
  }
  return newX;
}

/**
 * clip Eye Rotation angle to max
 * @param {radians} angle
 * returns clipped angle
 */
function clipEyeRotation(angle) {
  let newAngle = angle;
  if (newAngle > maxEyeRotationRad) {
    newAngle = maxEyeRotationRad;
  }
  return newAngle;
}

/**
 * Look at a position for a given joint
 * @param {THREE.Vector3} position to look at, {0, 0, 1} is looking straight
 * @param {String} jointName name of joint
 * @param {THREE.Vector3} defaultRotation for the joint
 * @returns {THREE.Vector3} new rotation for for eye / head joint
 */
function jointLookAtPosition(position, jointName, defaultRotation) {
  const rotation = defaultRotation;
  if (headLookAtJoints.indexOf(jointName) > -1) {
    // always looking forward in minZ direction
    const minZ = Math.max(position.z, 0.2);

    const yRotation = Math.atan(position.x / minZ) || 0;
    const xRotation = Math.atan(position.y / minZ) || 0;

    switch (jointName) {
      case headLookAtJoints[0]: { // 'Head' / RPM
        const yAngle = getHeadTurn(yRotation);
        rotation.y += yAngle;

        const xAngle = getHeadTilt(xRotation);
        rotation.x -= xAngle;

        break; }
      case headLookAtJoints[1]: { // head_jnt / Megan
        const headZ = getHeadTilt(xRotation);
        rotation.z += headZ;

        const headX = getHeadTurn(yRotation);
        rotation.x = headX;
        break; }

      case headLookAtJoints[2]: { // Megan Eye Left
        const eyeY = clipEyeRotation(yRotation);
        const eyeX = clipEyeRotation(xRotation);
        // angles needs to be scaled down to look more realistic, model specific
        if (eyeX > 0) { rotation.x -= eyeX / 6; } else { rotation.x -= eyeX / 3; }
        rotation.y += eyeY / 2;

        break; }
      case headLookAtJoints[3]: { // Megan Eye Right
        const eyeY = clipEyeRotation(yRotation);
        const eyeX = clipEyeRotation(xRotation);
        // angles needs to be scaled down to look more realistic, model specific
        if (eyeX > 0) { rotation.x -= eyeX / 6; } else { rotation.x -= eyeX / 3; }
        rotation.y -= eyeY / 2;
        break; }
      case headLookAtJoints[4]:
      case headLookAtJoints[5]: { // Samsung Left Eye
        const eyeY = clipEyeRotation(yRotation);
        const eyeX = clipEyeRotation(xRotation);
        // angles needs to be scaled down to look more realistic, model specific
        rotation.x -= eyeX / 4;
        rotation.z -= eyeY / 3;
        break; }
      case headLookAtJoints[6]: { // Samsung head joint
        const headY = getHeadTilt(yRotation);
        rotation.y += headY;

        const headX = getHeadTurn(xRotation);
        rotation.x -= headX;
        break; }
      case headLookAtJoints[7]: { // Tony head
        const headY = getHeadTilt(xRotation);
        rotation.y += headY;

        const headX = getHeadTurn(yRotation);
        rotation.x += headX;
        break; }
      case headLookAtJoints[8]: // Tony Left Eye
      case headLookAtJoints[9]: { // Tony Right Eye
        let left;
        if (jointName === headLookAtJoints[8]) { left = 1; } else { left = -1; }

        const eyeY = clipEyeRotation(yRotation);
        const eyeX = clipEyeRotation(xRotation);
        // angles needs to be scaled down to look more realistic, model specific
        if (eyeX > 0) { rotation.z += eyeX / 6; } else { rotation.z += eyeX / 2; }

        // if (eyeY < 0) rotation.y += eyeY * (left / 3.5); else rotation.y += eyeY * (left / 2.5);
        if (left === 1) {
          if (eyeY < 0) { rotation.y += eyeY / 3.5; } else { rotation.y += eyeY / 2.5; }
        } else if (left === -1) {
          if (eyeY > 0) { rotation.y += eyeY / 3.5; } else { rotation.y += eyeY / 2.5; }
        }
        break;
      }
      case headLookAtJoints[10]: // New RPM Left Eye
      case headLookAtJoints[11]: { // New RPM Right Eye
        const eyeX = clipEyeRotation(xRotation);
        const eyeY = clipEyeRotation(yRotation);
        // Angles need to be scaled down to look more realistic, model specific.
        const eyeXDownScale = 6;
        const eyeYDownScale = 3;

        rotation.x += (eyeX * -1) / eyeXDownScale;
        rotation.y += eyeY / eyeYDownScale;

        break;
      }
      case headLookAtJoints[12]: // cc4 Left Eye
      case headLookAtJoints[13]: { // cc4 Right Eye
        const eyeX = clipEyeRotation(xRotation);
        const eyeY = clipEyeRotation(yRotation);
        // Angles need to be scaled down to look more realistic, model specific.
        const eyeXDownScale = 2;
        const eyeZDownScale = 2;

        rotation.x += (eyeX * -1) / eyeXDownScale;
        rotation.z += eyeY / eyeZDownScale;

        // Limit eye rotation.
        rotation.x = Math.min(rotation.x, 0.18);
        rotation.x = Math.max(rotation.x, -0.10);

        rotation.z = Math.min(rotation.z, 0.30);
        rotation.z = Math.max(rotation.z, -0.30);

        break;
      }
      case headLookAtJoints[14]: { // cc4 head
        const headX = getHeadTilt(xRotation);
        rotation.x += headX * -1;

        const headY = getHeadTurn(yRotation);
        rotation.y += headY;
        break;
      }
      default:
        break;
    }
  }
  return rotation;
}

/**
 * look at position for blendshape
 * @param {THREE.Scene.node} node to be updated
 * @param {THREE.Vector3} position for blendshape node to look at
 */
function blendShapeLookAtPosition(node, position) {
  let xEyeRotation;
  let yEyeRotation;
  switch (node.name) {
    case headLookAtBlendShapes[0]: // RPM Left
    case headLookAtBlendShapes[1]: { // RPM Right
      const leftEye = node.name.toLowerCase().includes('left') ? 1 : -1;

      // y rotation
      const minEyeZ = Math.max(position.z, 0.2); // always looking forward
      yEyeRotation = Math.atan(position.x / minEyeZ) * leftEye || 0;

      // scale rotation to blendShape;
      yEyeRotation /= maxEyeRotationRad;

      if (Math.abs(yEyeRotation) > blendShapeLimit) {
        yEyeRotation = Math.sign(yEyeRotation);
      }

      xEyeRotation = Math.atan(position.y / minEyeZ) || 0;

      // scale rotation to blendshape
      xEyeRotation /= maxEyeRotationRad;
      if (xEyeRotation > 0) {
      // scale up blendShape, different limit
        xEyeRotation *= blendShapeUpLimit;
        if (xEyeRotation > blendShapeUpLimit) {
          xEyeRotation = blendShapeUpLimit;
        }
      } else if (xEyeRotation < -blendShapeLimit) {
        xEyeRotation = -blendShapeLimit;
      }
      break; }
    default:
      break;
  }

  // channelNames: (4)
  // ['eyeLookDownRight', 'eyeLookUpRight', 'eyeLookInRight', 'eyeLookOutRight']
  // eslint-disable-next-line no-param-reassign
  if (xEyeRotation < 0) node.channelValues[0] -= xEyeRotation; // down
  // eslint-disable-next-line no-param-reassign
  if (xEyeRotation >= 0) node.channelValues[1] += xEyeRotation; // up
  // eslint-disable-next-line no-param-reassign
  if (yEyeRotation < 0) node.channelValues[2] -= yEyeRotation; // Out
  // eslint-disable-next-line no-param-reassign
  if (yEyeRotation >= 0) node.channelValues[3] += yEyeRotation; // In
}

export { jointLookAtPosition, blendShapeLookAtPosition };
