import * as THREE from 'three';

import computeDirectionalLightViewBox from '../../../../../common/computeDirectionalLightViewBox';
import getBoundingBox from '../../../../../rendering/lib/helpers/getBoundingBox';

/* eslint-disable no-param-reassign */
export default function initModel(model) {
  const { scene } = model;

  model.cameras = [];
  model.lights = [];
  model.animations ??= [];
  model.rendererEffects = {};

  // Used for model provider specific code.
  model.isRpmModel = model.asset.generator === 'Ready Player Me';
  model.rpmBeard = null;
  model.rpmSkinnedMeshAvatar = null;
  model.rpmSkinnedMeshHead = null;
  model.isRpmFullBody = false;

  // Box helper debug code.
  // This is needed to fix Three.js bounding box calculation issue.
  const boxHelper = new THREE.Box3Helper(getBoundingBox(scene));
  scene.add(boxHelper);

  boxHelper.visible = false;

  /* eslint-disable no-param-reassign */
  scene.traverse((child) => {
    if (child.isMesh) {
      if (model.isRpmModel && child.type === 'SkinnedMesh') {
        if (child.name === 'Wolf3D_Avatar') {
          model.rpmSkinnedMeshAvatar = child;
        } else if (child.name === 'Wolf3D_Head') {
          model.rpmSkinnedMeshHead = child;
        }
      }

      child.receiveShadow = true;

      if (child.name === 'Wolf3D_Beard') {
        child.material.side = THREE.DoubleSide; // Force double Side
        model.rpmBeard = child;
      } else {
        child.material.side = THREE.FrontSide; // Force single front side
      }
      if (child.material.transparent === true && child.name !== 'Wolf3D_Glasses') {
        child.castShadow = false;
      } else {
        child.castShadow = true;
      }

      // Handle custom attributes
      if (child.userData?.renderOrder) {
        child.renderOrder = child.userData.renderOrder;
        if (child.name === 'Hair') { // Based on Culture Foundry JJ character
          child.material.side = THREE.DoubleSide; // Force double Side
        }
      }

      const typeOverride = child.material.userData?.typeOverride;

      if (typeOverride?.includes('ToonMaterial')) {
        const {
          map,
          color,
          alphaMap,
          normalMap,
        } = child.material;

        // For this material typeOverride the normalMap is used as gradientMap texture
        // Other typeOverride should be used if normalMap is needed for the material
        const gradientMap = normalMap;

        if (gradientMap) {
          gradientMap.wrapS = THREE.ClampToEdgeWrapping;
        }

        const materialName = child.material.name;
        const materialUserData = child.material.userData;

        child.material = new THREE.MeshToonMaterial({
          map,
          color,
          alphaMap,
          gradientMap,
          wireframe: false,
        });

        // Set anisotropy value for toon materials.
        // TODO: This should also be exposed as a global renderer option.
        if (child.material.map) {
          child.material.map.anisotropy = 4;
        }

        // Apply the original material's properties to new material.
        child.material.name = materialName;
        child.material.userData = materialUserData;

        // Add outline effect to renderer if there is at least one toon material in the model.
        // Add thick outline effect instead applicable.
        const effectName = typeOverride.includes('OutlineThick') ? 'outlineThick' : 'outline';

        model.rendererEffects.outline = { name: effectName, enabled: true, priority: 1 };
      }

      const outlineParameters = child.material.userData?.outlineParameters;

      if (typeof outlineParameters === 'string') {
        child.material.userData.outlineParameters = JSON.parse(outlineParameters);
      }
    }

    if (child.isCamera) {
      model.cameras.push(child);
    }

    if (child.isLight) {
      model.lights.push(child);

      // Set light properties from custom attributes
      const { userData } = child;

      child.castShadow = !!userData.castShadow;

      if (userData.shadowMapWidth !== undefined && userData.shadowMapHeight !== undefined) {
        child.shadow.mapSize.set(userData.shadowMapWidth, userData.shadowMapHeight);
      }

      if (userData.shadowMapNear !== undefined && userData.shadowMapFar !== undefined) {
        child.shadow.camera.near = userData.shadowMapNear * 0.01; // assuming model is in cm
        child.shadow.camera.far = userData.shadowMapFar * 0.01;
      } else {
        child.shadow.camera.near *= 0.01; // assuming model is in cm
        child.shadow.camera.far *= 0.01;
      }

      if (userData.shadowMapBias !== undefined) {
        child.shadow.bias = userData.shadowMapBias;
      }

      if (child.type === 'DirectionalLight') {
        computeDirectionalLightViewBox(child, scene);
      }
    }

    if (child.isBone) {
      // Check for RPM full body character leg bones.
      if (model.isRpmModel && ['LeftLeg', 'RightLeg'].includes(child.name)) {
        model.isRpmFullBody = true;
      }
    }
  });

  model.rendererEffects = Object.values(model.rendererEffects);
}
