/**
 * Normalize and returns clientX and clientY from mouse events.
 * @param {Object} e Mouse event.
 * @returns {Object} Contains clientX and clientY properties.
 */
export default function normalizeMousePos(e) {
  let mouseEvent = e;

  if (e.touches?.length) {
    [mouseEvent] = e.touches;
  } else if (e.changedTouches?.length) {
    [mouseEvent] = e.changedTouches;
  }

  return { x: mouseEvent.clientX, y: mouseEvent.clientY };
}
