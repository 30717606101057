// Morph targets

// Map of mesh names to SG character setup names.
const stringMap = {
  Wolf3D_Head: 'Wolf3D_Avatar',
  Wolf3D_Teeth: 'Wolf3D_Avatar',
  EyeRight: 'Wolf3D_Avatar',
  EyeLeft: 'Wolf3D_Avatar',
};

// Standardize naming to our convention for third party characters.
export default function configureMorphTargetDictionary(scene, animationNodes) {
  const blendshapes = {};
  const joints = {};
  const jointsOrigin = {};

  scene.traverse((child) => {
    if (child.morphTargetDictionary) {
      const keys = Object.keys(child.morphTargetDictionary);
      if (keys.length > 0) {
        const words = keys[0].split('.');

        if (words.length === 2) {
          // Blendshapes have most likely been exported in the "nodeName.channelName" format
          if (blendshapes[words[0]]) {
            blendshapes[words[0]].push(child);
          } else {
            blendshapes[words[0]] = [child];
          }
        } else if (words.length === 1) {
          // Only channel names have been exported
          // Try to match the exported names to animation nodes
          // Replace morphTargetDictionary with one that follows our naming format
          let nodeName = null;

          // Get the mesh name from the stringMap, if it exists.
          const meshNameAlt = stringMap[child.name];
          for (const node of animationNodes) {
            // Deal with duplicate channel names.
            // Check if the node name or the child name is a substring of the other.
            if (!node.name.includes(child.name) && !child.name.includes(node.name) && !node.name.includes(meshNameAlt)) {
              continue;
            }
            nodeName = node.name;
            break;
          }

          if (nodeName) {
            const newDictionary = {};

            keys.forEach((element) => {
              newDictionary[`${nodeName}.${element}`] = child.morphTargetDictionary[element];
            });

            child.morphTargetDictionary = newDictionary;

            if (blendshapes[nodeName]) {
              blendshapes[nodeName].push(child);
            } else {
              blendshapes[nodeName] = [child];
            }
          }
        }
      }
    }

    if (child.isBone) {
      const name = child.name.trim();

      joints[name] = child;
      jointsOrigin[name] = child.clone();
    }
  });

  return { blendshapes, joints, jointsOrigin };
}
