import {
  Raycaster as ThreeRaycaster,
  Vector2,
} from 'three';

export default class Raycaster {
  /**
   * Create websocket connection for Unreal Pixel Streaming's signaling server.
   * Callback onMessage is called with message from signaling server.
   * @param {object} params Contain raycaster parameters, and callback.
   * @param {array} params.sceneChildren Scene children list.
   * @param {object} params.activeCamera Active camera.
   * @param {object} params.canvas Canvas node.
   * @param {function} params.onMeshesIntersected Callback called with intersected meshes.
   */
  constructor(params) {
    Object.assign(this, params);

    this.addEventListeners();
  }

  threeRaycaster = new ThreeRaycaster();

  addEventListeners() {
    const { canvas } = this;

    canvas.addEventListener('click', this);
  }

  handleEvent(e) {
    switch (e.type) {
      case 'click': {
        const meshes = this.getIntersect(e);

        this.emitMeshesIntersected(meshes);
        break;
      }

      default:
        break;
    }
  }

  emitMeshesIntersected(meshes) {
    if (meshes.length) {
      this.onMeshesIntersected(meshes);
    }
  }

  getIntersect({ clientX, clientY }) {
    const {
      canvas,
      threeRaycaster,
      activeCamera,
      sceneChildren,
    } = this;

    const rect = canvas.getBoundingClientRect();
    const pointer = new Vector2();

    pointer.x = ((clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    pointer.y = -((clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    threeRaycaster.setFromCamera(pointer, activeCamera);

    const meshes = threeRaycaster
      .intersectObjects(sceneChildren, true)
      .map(({ object }) => object)
      .filter((object) => object.isMesh);

    return meshes;
  }

  dispose() {
    this.removeEventListeners();
  }

  removeEventListeners() {
    const { canvas } = this;

    canvas.removeEventListener('click', this);
  }
}
