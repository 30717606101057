import ErrorCode from '../js/ErrorCode';
import EventType from '../js/EventType';
import LoggingLevel from '../js/LoggingLevel';
import Rapport from '../js/Rapport';
import RapportStatus from '../js/RapportStatus';
import RapportStatusCodes from '../js/RapportStatusCodes';
import Audio from '../js/audio/Audio';
import cache from '../js/players/webgl/parseSceneConfig/lib/Cache';
import publicAPIDispatch from '../js/dispatch/publicAPIDispach';
import mapErrorCode from '../js/mapErrorCode';
import createApiModules from '../js/modules/api/createApiModules';
import createModules from '../js/modules/index';
import getFontSize from '../js/utils/getFontSize';
import Logger from '../js/utils/logger';
import camelAttributes from './camelAttributes';
import kebabAttributes from './kebabAttributes';

import { cursorStyles } from '../js/rendering/CanvasCursor';
import RapportLogo from '../js/ui/RapportLogo';
import RapportLogoStyle from '../js/ui/RapportLogo.css?inline';

import AudioControls from '../js/ui/AudioControls';
import Comment from '../js/ui/Comment';
import EmotionIcons from '../js/ui/EmotionIcons';
import ProgressBar from '../js/ui/ProgressBar';
import ModalError from './modelError/ModalError';
import SignalIndicator from '../js/ui/SignalIndicator';

import { RapportSceneStatus } from '../js/ui/SceneStatus';
import getBoolean from '../js/utils/boolean';
import validatePosition from '../js/utils/validatePosition';

import AudioConfig from '../js/ui/AudioConfig';
import AudioConfigStyle from '../js/ui/AudioConfig.css?inline';
import rapportSceneStyle from './rapportScene.css?inline';

import sleep from '../js/utils/sleep';

import PushToTalk from '../js/ui/PushToTalk';

export default class rapportScene extends HTMLElement {
  // see function attributeChangedCallback()
  // kebabAttributes are the attributes we are listening for changes
  static get observedAttributes() {
    return Object.values(kebabAttributes);
  }

  // Private variables

  #rapport; // session manager / rendering / network belong to rapport, should no be private

  #rapportLogo; // Private, customers unable to turn off

  constructor() {
    super();

    this.logger = new Logger();

    const { logger } = this;

    Audio.logger = logger;
    cache.logger = logger;

    this.autoStart = false;

    this.sessionState = 'disconnected';

    this.modules = null;

    // this.timeoutMS = 600 * 1000; default max duration timeout 10 minutes reset in session request
    this.timeoutMS = null;
    this.sessionConnectedMS = null;
    this.inactivityMS = 180 * 1000; // default inactivity timeout 3 minutes reset in session request
    this.inactivityResetMS = null;

    this.cpiTimeoutMS = null; // updated once session connected

    this.disconnectTimeout = null; // initiated on room connection
    this.inactivityTimeout = null; // initiated on room connection and reset on activity

    this.loadingImage = null;
    this.destroyHook = true;
    this.footer = false;
    this.progressBar = false;
    this.statusBar = false;
    this.emotions = false;
    this.openingText = null;
    this.ttsOpeningText = null;
    this.pttKey = null;
    this.orbitalControls = true;
    this.cameraPosition = null;
    this.forceDefaultCamera = false;
    this.rendererSetupHook = null;
    this.cameraLookAt = null;
    this.lights = null; // crud lighting API
    this.background = 'transparent';
    this.baseUrl = 'https://api.rapport.cloud/api/v1/';
    this.speakerMuted = false;
    this.micMuted = false;
    this.micDelay = 300;
    this.lastLoggedJitter = null;
    this.sessionDetails = null;

    this.mutationObserver = null;

    const template = `
    <style>

    ${rapportSceneStyle}

    ${RapportLogoStyle}

    ${AudioConfigStyle}

    ${cursorStyles}

    </style>

    <div class="container">
      <rapport-modal-error></rapport-modal-error>

      <div id="c"></div>

      <div id="rapport-logo"></div>

      <div id="header" class="header">
        <div id="wrench" class="icon wrench"></div>
        <div id="signalIndicator" part="signalIndicator" class="icon signal-indicator"></div>
      </div>

      <div id="footer" class="footer">
        <div class="left">
          <div id="happy" class="icon emotion"></div>
          <div id="sad" class="icon emotion"></div>
          <div id="neutral" class="icon emotion"></div>
          <div id="acknowledge" class="icon emotion"></div>
          <div id="rapportComments" class="rapportComments">Initialised</div>
        </div>
        <div id='right' class="right">
          <div id="micOff" class="icon microphone"></div>
          <div id="micOn" class="icon microphone"></div>
          <div id="volumeUp" class="icon speaker"></div>
          <div id="volumeMute" class="icon speaker"></div>
          <input id="volumeControl" class="volumeControl" type="range" min="0" max="1" value="1" step="0.01">
          <div id="loading" class="icon loading"></div>
          <progress id="rapportProgress" class="rapportProgress" max="100"></progress>
        </div>
      </div>
    </div>


    <div id="audioConfigModal" part="configModal" class="modal"></div>

    <script src="https://webrtc.github.io/adapter/adapter-latest.js" id="adapterjs"></script>
    <audio id="audioUpxsFix" autoplay muted style="display: none;"></audio>
    `;

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = template;

    this.host = this.shadowRoot.host; // use of host might be unnecessary
    this.container = this.shadowRoot.querySelector('#c');

    const rapportModalErrorElement = this.shadowRoot.querySelector('rapport-modal-error');

    this.modalError = new ModalError({ element: rapportModalErrorElement });

    this.signalIndicator = new SignalIndicator({
      imageElement: this.shadowRoot.querySelector('#header #signalIndicator'),
      onClick: () => {
        const [player] = this.#rapport.players.values();

        const { value } = player.characters[0].playbackQueue.jitter;

        /* eslint-disable-next-line no-console */
        console.log(`Jitter | Jitter in ms: ${value}`);
      },
    });

    this.sessionDisconnectedCallback = null;
    this.sessionConnectedCallback = null;
    this.infoCallback = null;
    this.warningCallback = null;

    this.asrMessageCallback = null;
    this.aiMessageCallback = null;
    this.vaMessageCallback = null;
    this.saMessageCallback = null;
    this.ttsStartCallback = null;
    this.ttsEndCallback = null;
    this.ttsOnCallback = null;
    this.ttsOffCallback = null;
    this.ttsMessageCallback = null;
    this.usersChangedCallback = null;
    this.stateChangedCallback = null;
    this.animationFinishedCallback = null;
    this.moduleErrorCallback = null;
    this.meshesIntersectedCallback = null;
    this.publicAPIDispatch = publicAPIDispatch;

    this.footerDiv = this.shadowRoot.getElementById('footer');
    this.audioConfig = new AudioConfig(
      logger,
      this.shadowRoot.getElementById('wrench'),
      this.shadowRoot.getElementById('audioConfigModal'),
      (audioConfigForm) => {
        const { pttEnabled, pttKey } = audioConfigForm;

        // Integrator forced Push to talk key.
        if (this.pttKey) {
          return;
        }

        this.pushToTalk.enabled = pttEnabled;

        if (pttEnabled && pttKey) {
          this.pushToTalk.key = pttKey;
          this.muteMic(true);
          this.pushToTalk.containerRestriction = false;
          Audio.mic.disableNoiseGate();
        } else {
          this.muteMic(false);
        }
      },
    );

    this.footerDiv.style.display = 'none';

    this.rapportProgress = new ProgressBar(this.shadowRoot.getElementById('rapportProgress'));
    this.rapportComment = new Comment(this.shadowRoot.getElementById('rapportComments'));
    this.rapportEmotions = new EmotionIcons({
      happy: this.shadowRoot.getElementById('happy'),
      acknowledge: this.shadowRoot.getElementById('acknowledge'),
      neutral: this.shadowRoot.getElementById('neutral'),
      sad: this.shadowRoot.getElementById('sad'),
    });

    this.audioControls = new AudioControls(
      {
        container: this.shadowRoot.getElementById('right'),
        loading: this.shadowRoot.getElementById('loading'),
        on: this.shadowRoot.getElementById('micOn'),
        off: this.shadowRoot.getElementById('micOff'),
        up: this.shadowRoot.getElementById('volumeUp'),
        mute: this.shadowRoot.getElementById('volumeMute'),
        control: this.shadowRoot.getElementById('volumeControl'),
      },
      {
        logger,
        errorCallback: this.#throwError,
        onStateChange: (payload) => {
          this.publicAPIDispatch({
            type: EventType.STATE_CHANGED,
            payload,
          });
        },
      },
    );

    this.pushToTalk = null;

    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'microphone' })
        .then((permissionStatus) => {
          logger.debug(`Audio | Mic permission state: ${permissionStatus.state}`);

          permissionStatus.onchange = async (event) => {
            logger.debug(`Audio | Mic permission state changed: ${event.target.state}`);

            if (event.target.state === 'granted') {
              this.#updateDevices();
            }
          };
        })
        .catch((error) => {
          logger.error(new Error('Audio | Not supported permission API', { cause: error }));
        });
    }

    // add an ondevicechange event listener so we can tell when
    // an input device is connected and disconnected
    navigator.mediaDevices.ondevicechange = () => {
      this.#updateDevices();
    };

    this.#rapportCallback(RapportSceneStatus.LOADING_START);
  }

  get players() {
    if (!this.#rapport?.players) {
      return [];
    }

    return Array.from(this.#rapport.players.values());
  }

  get animations() {
    if (this.#rapport?.networkUpxs) {
      return this.#rapport.animationControllerUpxs;
    }

    return this.players[0]?.characters?.[0]?.animations || null;
  }

  #updateDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const inputList = [];

      for (let i = 0; i !== devices.length; i += 1) {
        const deviceInfo = devices[i];
        if (deviceInfo.kind === 'audioinput') {
          inputList.push(deviceInfo);
        }
      }

      if (JSON.stringify(inputList) !== JSON.stringify(Audio.storedInputDeviceList)) {
        Audio.storedInputDeviceList = inputList;
      }
    });
  }

  /**
   * Used to prevent session disconnect on moving the scene into the DOM
   * @param {Boolean} value
   */
  allowDestroyHook(value) {
    this.destroyHook = value;
  }

  connectedCallback() {
    if (this.destroyHook) {
      // fires when constructor is done.
      const autoStart = this.getAttribute('auto-start');
      const shouldSessionRequest = autoStart === true || autoStart === 'true';
      if (shouldSessionRequest) {
        this.autoStart = true;
        this.sessionRequest();
      }

      const loadingImage = this.getAttribute('loading-image');
      if (loadingImage) {
        this.loadingImage = loadingImage;
        this.#setLoadingImage(true);
      }
    }
    this.publicAPIDispatch({ type: EventType.COMPONENT_CONNECTED });
  }

  /**
   * Fires when rapport scene gets removed from DOM
   */
  disconnectedCallback() {
    const isConnected = this.sessionState === 'connected';
    const shouldDisconnect = isConnected && this.destroyHook;

    if (shouldDisconnect) {
      this.sessionDisconnect();
    }
  }

  #updateBackground(background) {
    if (this.#rapport && this.#rapport.rendering) {
      this.#rapport.rendering.setBackground(background);
    }
  }

  /**
   * Update scene rendering attributes during session runtime
   */
  setAttributes(params) {
    Object.keys(params).forEach((name) => {
      const value = params[name];
      const isRendering = this.#rapport.rendering || this.#rapport.renderingUpxs;

      if (isRendering) {
        switch (name) {
          case camelAttributes.BACKGROUND:
            this.#updateBackground(value);
            break;
          case camelAttributes.OC_ZOOM:
            this.#rapport.rendering.setOcZoom(getBoolean(value));
            break;
          case camelAttributes.OC_ANGLE:
            this.#rapport.rendering.setAngle(value);
            break;
          case camelAttributes.CAMERA_POSITION:
            this.#rapport.rendering.setCameraPosition(value);
            break;
          case camelAttributes.ORBITAL_CONTROLS:
            if (this.#rapport.orbitControlsUpxs) {
              this.#rapport.orbitControlsUpxs.enabled = getBoolean(value);
              this.#rapport.canvasCursorUpxs.enabled(getBoolean(value));
            } else {
              this.#rapport.rendering.setOrbitalRotation(getBoolean(value));
            }

            break;
          case camelAttributes.CAMERA_LOOK_AT:
            this.#rapport.rendering.setCameraLookAt(value);
            break;
          case camelAttributes.MODEL_LOOK_AT:
            if (this.#rapport.lookAtUpxs) {
              const { x: posX, y: posY } = value.screenPosition;

              this.#rapport.lookAtUpxs.lookAtPosition({ posX, posY });
            } else {
              this.players[0].characters[0].setModelLookAt(value);
            }

            break;
          case camelAttributes.SIGNAL_INDICATOR:
            this.signalIndicator.updateWhenToDisplay(value);
            break;
          default:
            break;
        }
      }
    });
  }

  /**
   * Update scene rendering on html attribute change
   */
  attributeChangedCallback(name, oldValue, newValue) {
    const isRendering = this.#rapport && this.#rapport.rendering;

    if (isRendering) {
      switch (name) {
        case kebabAttributes.BACKGROUND:
          this.#updateBackground(newValue);
          break;
        case kebabAttributes.OC_ZOOM:
          this.#rapport.rendering.setOcZoom(getBoolean(newValue));
          break;
        case kebabAttributes.OC_ANGLE:
          this.#rapport.rendering.setAngle(parseFloat(newValue));
          break;
        case kebabAttributes.CAMERA_POSITION: {
          const cameraPosition = JSON.parse(newValue);
          this.#rapport.rendering.setCameraPosition(cameraPosition);
          break;
        }
        case kebabAttributes.CAMERA_LOOK_AT: {
          const point = JSON.parse(newValue);
          this.#rapport.rendering.setCameraLookAt(point);
          break;
        }
        case kebabAttributes.ORBITAL_CONTROLS:
          if (this.#rapport.orbitControlsUpxs) {
            this.#rapport.orbitControlsUpxs.enabled = getBoolean(newValue);
            this.#rapport.canvasCursorUpxs.enabled(getBoolean(newValue));
          } else {
            this.#rapport.rendering.setOrbitalRotation(getBoolean(newValue));
          }

          break;
        case kebabAttributes.SIGNAL_INDICATOR:
          this.signalIndicator.updateWhenToDisplay(newValue);
          break;
        default:
          break;
      }
    }
  }

  resizeComponents() {
    if (this.#rapport && this.#rapport.rendering) {
      this.#rapport.resizeRenderers();
      this.#rapportLogo.resize();
    }
    this.rapportEmotions.resize();
    if (this.rapportComment.visible) {
      // left takes up 60% of width, with 5px margin either side
      const textSpace = this.footerDiv.offsetWidth * 0.6 - 10;
      const widthFontSize = getFontSize(textSpace);
      // height of text to be 80% the height of the footer dive height to allow for
      // 10% margin top and bottom
      const heightFontSize = 0.8 * this.footerDiv.offsetHeight;
      this.rapportComment.comment.style.fontSize = `${Math.min(widthFontSize, heightFontSize)}px`;
    }
  }

  async #rapportCallback(data) {
    const { logger } = this;

    if (typeof data === 'object') {
      switch (true) {
        case data.CPIMMessage !== undefined:
          this.#receiveCPIMMessage(data.CPIMMessage);
          break;
        case data.cpiTimeout !== undefined: {
          this.cpiTimeoutMS = data.cpiTimeout * 1000 * 60; // cpi timeout in minutes

          if (this.timeoutMS > this.cpiTimeoutMS) {
            this.#statusCallback(LoggingLevel.WARNING, ErrorCode.MAX_TIMEOUT_EXCEEDED);
          }

          const timeout = Math.min(this.timeoutMS, this.cpiTimeoutMS) || this.cpiTimeoutMS;

          // Force disconnect from client side 500 ms earlier then CPI would do so.
          clearTimeout(this.disconnectTimeout);
          this.disconnectTimeout = setTimeout(() => {
            if (this.disconnectTimeout) {
              logger.debug('State | Max duration timeout. Disconnecting.');
              this.#dispatchTimeout();
            }
          }, timeout - 500);
          break;
        }
        case data.emotion !== undefined:
          if (this.emotions && this.audioControls.isVisible) {
            this.rapportEmotions.update(data.emotion);
          }
          break;
        case data.eventType === RapportSceneStatus.TTS_START: {
          this.publicAPIDispatch({
            type: EventType.TTS_START,
            payload: data,
          });
          break;
        }
        case data.eventType === RapportSceneStatus.TTS_END: {
          this.publicAPIDispatch({
            type: EventType.TTS_END,
            payload: data,
          });
          break;
        }
        case data.eventType === 'animationFinished': {
          this.publicAPIDispatch({
            type: 'animationFinished',
            payload: data.payload,
          });
          break;
        }
        case data.eventType === RapportSceneStatus.MESHES_INTERSECTED: {
          this.publicAPIDispatch({
            type: EventType.MESHES_INTERSECTED,
            payload: data.payload,
          });
          break;
        }
        case data.eventType === 'jitterChange': {
          const jitterLogThresholdMs = 200;
          const { clientId, value, oldValue } = data.payload;

          // Set default jitter value.
          if (!this.lastLoggedJitter) {
            this.lastLoggedJitter = oldValue;
          }

          // Only report jitter change when it changes at least 200 ms from last report.
          if (Math.abs(value - this.lastLoggedJitter) >= jitterLogThresholdMs) {
            logger.debug(
              `Jitter | ClientId: ${clientId}, jitter changed from ${this.lastLoggedJitter} to ${value}.`,
            );
            this.lastLoggedJitter = value;
          }

          this.signalIndicator.update(value);
          break;
        }
        default:
          break;
      }
    } else if (typeof data === 'string') {
      // Progress
      if (this.progressBar) this.rapportProgress.update(data);
      // Comment
      if (this.statusBar) this.rapportComment.update(data);

      switch (data) {
        case RapportSceneStatus.RESIZE:
          this.resizeComponents();
          break;
        case RapportSceneStatus.LOADING_START: {
          if (this.statusBar) {
            this.rapportComment.show();
          }

          break;
        }
        case RapportSceneStatus.LOADING_DONE: {
          if (this.#rapport.network) {
            this.audioConfig.updateMicCallback = async (deviceId) => {
              const getMicResult = await Audio.getMic(deviceId);

              if (getMicResult !== ErrorCode.SUCCESS) {
                this.#throwError(getMicResult, false);
              }
            };
          }

          this.modules = createModules(
            (payload) => {
              this.#rapport.network?.sendCommand(payload);
            },
            (errorCode, disconnect) => {
              this.#throwError(errorCode, disconnect);
            },
          );

          const { session, network } = this.#rapport;

          const modulesApi = createApiModules({
            data: {
              commands: {
                commands: session?.roomDetails.commands,
              },
            },
            onApiRequest: async ({ apiName, params }) => session[apiName](params),
            onCpiRequest: (payload) => network.sendCpiCommand(payload),
          });

          Object.assign(this.modules, modulesApi);

          if (Audio.micRequired) {
            this.#updateDevices();
          }

          // assign lighting API just before session is connected
          if (this.#rapport.rendering) {
            this.lights = this.#rapport.rendering.lightsAPI;
          }

          // session
          this.sessionConnectedMS = Date.now();

          if (this.emotions) {
            this.rapportEmotions.update('neutral');
          }

          // AudioControls reset once rapport scene is connected.
          this.audioControls.resetParams();

          this.resizeComponents();

          break;
        }
        case RapportSceneStatus.RENDERING: {
          // if we need to send opening text
          if (this.openingText) {
            this.modules.ai.sendText(this.openingText);
          }

          // if we need to send opening text
          if (this.ttsOpeningText) {
            this.modules.tts.sendText(this.ttsOpeningText);
          }
          // inactivity disabled by setting to zero of false
          this.resetInactivity();

          this.#setLoadingImage(false);

          this.container.style.visibility = 'visible';

          this.rapportComment.hide();

          this.audioControls.show();

          // Start session with muted speakers.
          if (this.speakerMuted) {
            this.audioControls.muteSpeaker();
          }

          this.resizeComponents();

          // If muted mic is not forced then delay enabling mic at session start.
          if (!this.micMuted) {
            setTimeout(() => {
              // Enable mic if user did not muted it.
              if (!this.audioControls.micLocked && !this.pttKey) {
                this.audioControls.muteMic(false);
              }
            }, this.micDelay);
          }

          this.publicAPIDispatch({
            type: EventType.SESSION_CONNECTED,
            payload: {
              roomId: this.#rapport.meetingRoomId,
            },
          });

          break;
        }
        case RapportSceneStatus.USERS_CHANGED: {
          this.publicAPIDispatch({ type: EventType.USERS_CHANGED });
          break;
        }
        case RapportSceneStatus.CREATING_ROOM:
        case RapportSceneStatus.CACHED_MODEL:
        case RapportSceneStatus.JOINING_ROOM:
        case RapportSceneStatus.DOWNLOADING_MODEL:
        case RapportSceneStatus.RECEIVE_MODEL:
        case RapportSceneStatus.SETTING_UP_SESSION:
        case RapportSceneStatus.ROOM_CREATED:
        case RapportSceneStatus.DISCONNECTED:
          break;
        default:
          break;
      }
    }
  }

  muteSpeaker(state) {
    this.audioControls.muteSpeaker(state);
  }

  muteMic(state) {
    if (state) {
      this.audioControls.handleMicOff();
      return;
    }
    this.audioControls.handleMicOn();
  }

  get audio() {
    return Audio;
  }

  async screenshot() {
    const { previewTransformControls, canvas } = this.#rapport.rendering;

    if (previewTransformControls) {
      previewTransformControls.visible = false;
      // Renderer needs time to hide previewTransformControls.
      await sleep(150);
    }

    const screenshot = canvas.toDataURL();

    if (previewTransformControls) {
      await sleep(150);
      previewTransformControls.visible = true;
    }

    return screenshot;
  }

  // if visible shown, else removed
  #setLoadingImage(visible = false) {
    if (visible) {
      this.host.style.backgroundImage = `url(${this.loadingImage})`;
      this.host.style.backgroundRepeat = 'no-repeat';
      this.host.style.backgroundSize = 'auto 100%';
      this.host.style.backgroundPosition = 'center bottom';
      this.container.style.visibility = 'hidden';
    } else {
      this.host.style.backgroundImage = 'none';
    }
  }

  rapport() {
    return this.#rapport;
  }

  // are we leaving this open to integrator? all commands. could change translators etc?
  sendCPIM(module, payload) {
    const message = { TargetCPIM: module, Payload: payload };
    this.#rapport.network.sendCommand(JSON.stringify(message));
  }

  // error thrown only when auto start and disconnect = true
  #throwError(errorCode, disconnect = false) {
    const errorMessage = new RapportStatus(LoggingLevel.ERROR, mapErrorCode(errorCode));

    const { modalError } = this;

    if (modalError.enabled) {
      modalError.content = `${errorMessage} <br> Code: ${errorMessage.code}`;
      modalError.visible = true;
    }

    if (this.autoStart && disconnect) {
      this.#statusCallback(LoggingLevel.ERROR, errorCode);
      return;
    }
    if (disconnect) {
      this.sessionState = 'failed';

      this.sessionDisconnect();
    }

    throw errorMessage.toAPIDispatchObj().msg;
  }

  getCameraPosition() {
    if (this.#rapport && this.#rapport.rendering) {
      return this.#rapport.rendering.getCameraPosition();
    }
    return {
      info: 'call getCameraPosition in session after sessionConnected  event has been dispatched',
    };
  }

  async getMic() {
    const { logger } = this;

    if (!this.#rapport?.network) {
      throw new Error('Session | Session is not running.');
    }

    try {
      if (Audio.mic.inputSource) {
        throw new Error('Audio | Microphone already in use.');
      }

      await this.#rapport.network.switchAudioTrack();
    } catch (error) {
      logger.error(new Error('Audio | Unable to get microphone.', { cause: error }));

      throw new Error(error);
    }
  }

  getTimers() {
    const now = Date.now();
    let cpiTimeout = NaN;
    let sessionDuration = NaN;
    let inactivityDuration = NaN;

    if (this.cpiTimeoutMS !== null) {
      cpiTimeout = this.cpiTimeoutMS / 1000;
    }

    if (this.inactivityResetMS !== null) {
      inactivityDuration = (now - this.inactivityResetMS) / 1000;
    }

    if (this.sessionConnectedMS !== null) {
      sessionDuration = (now - this.sessionConnectedMS) / 1000;
    }

    return {
      timeout: this.timeoutMS / 1000,
      inactivity: this.inactivityMS / 1000,
      cpiTimeout,
      sessionDuration,
      inactivityDuration,
      info: 'Timer values in seconds',
    };
  }

  resetInactivity() {
    // Inactivity is disabled if set to false or zero.
    if (this.inactivityMS > 0) {
      clearTimeout(this.inactivityTimeout);
      this.inactivityResetMS = Date.now();
      this.inactivityTimeout = setTimeout(() => {
        // Disable inactivity timeout temporarily
        // this.#dispatchInactivityTimeout();
      }, this.inactivityMS);
    }
  }

  #dispatchInactivityTimeout() {
    const { logger } = this;

    logger.debug('State | Inactivity timeout disconnect.');

    const inactivityStatus = new RapportStatus(
      LoggingLevel.INFO,
      RapportStatusCodes.RAPPORT_INACTIVITY_TIMEOUT,
    ).toAPIDispatchObj();
    this.publicAPIDispatch({ type: EventType.SESSION_DISCONNECTED, msg: inactivityStatus.msg });
  }

  #dispatchTimeout() {
    const timeoutStatus = new RapportStatus(
      LoggingLevel.INFO,
      RapportStatusCodes.RAPPORT_TIMEOUT,
    ).toAPIDispatchObj();
    this.publicAPIDispatch({ type: EventType.SESSION_DISCONNECTED, msg: timeoutStatus.msg });
  }

  #receiveCPIMMessage(message) {
    const { logger, modalError } = this;

    try {
      if (this.inactivityMS > 0) {
        this.resetInactivity();
      }

      logger.debug(`CPI | Message received: ${message.data}`);

      const msg = JSON.parse(message.data);

      const { SourceCPIMType: sourceCPIMType, Payload: payload } = msg;

      if (sourceCPIMType === 'AI' && payload.method === 'error') {
        if (!modalError.enabled) {
          return;
        }

        const { params } = payload;
        const { message: aiErrorMessage, code, domain } = params;

        modalError.content = `${aiErrorMessage} <br> Code: ${code} <br> domain: ${domain}`;
        modalError.visible = true;

        this.publicAPIDispatch({
          type: 'moduleError',
          payload: {
            ...params,
            module: sourceCPIMType,
          },
        });
      }

      if (msg.SourceCPIM) {
        this.publicAPIDispatch({ type: EventType.SEND_CPIM_MESSAGE, msg });
      } else {
        // Unknown CPI Module
        this.#statusCallback(LoggingLevel.WARNING, ErrorCode.CPI_UNKNOWN_SOURCE_MODULE);
        this.publicAPIDispatch({ type: EventType.INFO, msg });
      }
    } catch (error) {
      logger.error(new Error('CPI | Error parsing CPIM Message.', { cause: error }));
    }
  }

  #statusCallback(level, message) {
    if (message === ErrorCode.SUCCESS) {
      return;
    }
    const dispatchMessage = new RapportStatus(level, mapErrorCode(message));
    this.publicAPIDispatch(dispatchMessage.toAPIDispatchObj());
  }

  #defaultParams() {
    this.timeoutMS = null;
    this.inactivityMS = 180 * 1000;
    this.cpiTimeoutMS = null;
    this.inactivityResetMS = null;
    this.sessionConnectedMS = null;
    this.footer = false;
    this.progressBar = false;
    this.statusBar = false;
    this.emotions = false;
    this.openingText = null;
    this.ttsOpeningText = null;
    this.pttKey = null;
    this.orbitalControls = true;
    this.cameraPosition = null;
    this.cameraLookAt = null;
    this.rendererSetupHook = null;
    this.background = 'transparent';
    this.baseUrl = 'https://api.rapport.cloud/api/v1/';
    this.speakerMuted = false;
    this.micMuted = false;
    this.micDelay = 300;
    this.sessionDetails = null;

    this.rapportComment.resetParams();
  }

  // Refresh running try out session.
  async sessionRefresh() {
    return this.#rapport.sessionRefresh();
  }

  async sessionRequest(params = {}) {
    const { logger } = this;

    if (this.sessionState !== 'disconnected') {
      this.#throwError(ErrorCode.FAILURE_SESSION_IN_PROGRESS);

      throw new Error('Session is in progress.');
    }

    // Reset logger to stop infinite loop after a restarted session.
    logger.reset();

    logger.debug('State | Session requested.');

    this.sessionState = 'connecting';

    const audioUpxsFixElement = document
      .getElementsByTagName('rapport-scene')[0]
      .shadowRoot.getElementById('audioUpxsFix');

    Audio.start(audioUpxsFixElement);

    this.#defaultParams();
    // setup listener handlers
    this.mutationObserver = new MutationObserver(() => {
      if (this.host.style.display !== 'none') {
        this.resizeComponents();
      }
    });

    // assign listeners to handlers
    this.mutationObserver.observe(this.host, { attributes: true, childList: true });
    this.container.addEventListener('dblclick', this.audioConfig.toggle);

    this.modalError.visible = false;

    const debugging = this.getAttribute('debugging') || params.debugging;

    if (debugging === true || debugging === 'true') {
      logger.setDebug(true);
    }

    logger.info(`Info | Debugging: ${debugging}`);
    logger.info(`Info | RWV commit hash: ${__RWV_COMMITHASH__}`);
    logger.info(`Info | userAgent: ${globalThis.navigator.userAgent}`);

    const speakerMuted = this.getAttribute('speaker-muted') || params.speakerMuted;
    if (speakerMuted === true || speakerMuted === 'true') {
      this.speakerMuted = true;
    }

    const micMuted = this.getAttribute('mic-muted') || params.micMuted;
    if (micMuted === true || micMuted === 'true') {
      this.micMuted = true;
    }

    const micDelay = Number(this.getAttribute('mic-delay') || params.micDelay);
    if (Number.isInteger(micDelay)) {
      this.micDelay = micDelay;
    }

    const loadingImage = this.getAttribute('loading-image') || params.loadingImage;
    if (loadingImage) {
      this.loadingImage = loadingImage;
      this.#setLoadingImage(true);
    }

    const forceDefaultCamera =
      this.getAttribute('force-default-camera') || params.forceDefaultCamera;
    if (forceDefaultCamera === true || forceDefaultCamera === 'true') {
      this.forceDefaultCamera = forceDefaultCamera;
    }

    const rendererSetupHook =
      globalThis[this.getAttribute('renderer-setup-hook')] || params.rendererSetupHook;
    if (rendererSetupHook instanceof Function) {
      this.rendererSetupHook = rendererSetupHook;
    }

    const {
      aiMessage,
      asrMessage,
      vaMessage,
      saMessage,
      warning,
      info,
      ttsStart,
      ttsEnd,
      ttsOn,
      ttsOff,
      ttsMessage,
      animationFinished,
      moduleError,
      meshesIntersected,
    } = params;
    if (aiMessage) {
      this.aiMessageCallback = aiMessage;
    }
    if (asrMessage) {
      this.asrMessageCallback = asrMessage;
    }
    if (vaMessage) {
      this.vaMessageCallback = vaMessage;
    }
    if (saMessage) {
      this.saMessageCallback = saMessage;
    }
    if (ttsStart) {
      this.ttsStartCallback = ttsStart;
    }
    if (ttsEnd) {
      this.ttsEndCallback = ttsEnd;
    }
    if (ttsOn) {
      this.ttsOnCallback = ttsOn;
    }
    if (ttsOff) {
      this.ttsOffCallback = ttsOff;
    }
    if (ttsMessage) {
      this.ttsMessageCallback = ttsMessage;
    }
    if (animationFinished) {
      this.animationFinishedCallback = animationFinished;
    }
    if (moduleError) {
      this.moduleErrorCallback = moduleError;
    }
    if (meshesIntersected) {
      this.meshesIntersectedCallback = meshesIntersected;
    }
    if (warning) {
      this.warningCallback = warning;
    }
    if (info) {
      this.infoCallback = info;
    }

    const baseUrl = this.getAttribute('base-url') || params.baseUrl;

    if (baseUrl) {
      this.baseUrl = baseUrl;
    }

    logger.setBaseUrl(this.baseUrl);

    const signalIndicator_param = this.getAttribute('signal-indicator') || params.signalIndicator;
    this.signalIndicator.updateWhenToDisplay(signalIndicator_param);
    // set border after setting attribute
    this.#rapportCallback(RapportSceneStatus.BUFFER_SIGNAL_GOOD);

    const progressBar = this.getAttribute('progress-bar') || params.progressBar;
    if (progressBar === true || progressBar === 'true') {
      this.footer = true;
      this.progressBar = true;
      this.rapportProgress.show();
    }

    const statusBar = this.getAttribute('status-bar') || params.statusBar;
    if (statusBar === true || statusBar === 'true') {
      this.statusBar = true;
      this.footer = true;
      this.rapportComment.show();
    } else {
      this.statusBar = false;
    }

    const emotions = this.getAttribute('emotions') || params.emotions;
    if (emotions === true || emotions === 'true') {
      this.emotions = true;
      this.footer = true;
    } else {
      this.emotions = false;
      this.rapportEmotions.hide();
    }

    const micRequired = this.getAttribute('mic-required') || params.micRequired;
    if (micRequired === false || micRequired === 'false') {
      Audio.micRequired = false;
    }

    const micControl = this.getAttribute('mic-control') || params.micControl;
    const isMicControl = micControl === true || micControl === 'true';
    if (isMicControl) {
      this.audioControls.option('micControl', isMicControl);
      this.footer = isMicControl;
    }

    // volume control need to be enabled
    const volumeControl = this.getAttribute('volume-control') || params.volumeControl;
    const isVolumeControl = volumeControl === true || volumeControl === 'true';
    if (isVolumeControl) {
      this.audioControls.option('volumeControl', isVolumeControl);
      this.footer = isVolumeControl;
    }

    const background = this.getAttribute('background') || params.background;
    if (background) {
      this.background = background;
    }

    // timeouts to be set in seconds
    const timeout = this.getAttribute('timeout') || params.timeout;
    if (timeout !== undefined && timeout >= 0) {
      this.timeoutMS = timeout * 1000;
    }

    const inactivity = this.getAttribute('inactivity') || params.inactivity;
    if (inactivity !== undefined && inactivity >= 0) {
      this.inactivityMS = inactivity * 1000;
    }

    const orbitalControls = this.getAttribute('orbital-controls') || params.orbitalControls;
    if (orbitalControls === false || orbitalControls === 'false') {
      this.orbitalControls = false;
    }

    const cameraPosition = this.getAttribute('camera-position') || params.cameraPosition;
    if (cameraPosition !== undefined) {
      try {
        if (cameraPosition instanceof Object && validatePosition(cameraPosition)) {
          this.cameraPosition = cameraPosition;
        } else if (typeof cameraPosition === 'string') {
          const parsedCameraPosition = JSON.parse(cameraPosition);
          if (validatePosition(parsedCameraPosition)) {
            this.cameraPosition = parsedCameraPosition;
          } else {
            throw new Error('CameraPosition requires {x, y, z} format.');
          }
        } else {
          throw new Error('CameraPosition requires {x, y, z} format.');
        }
      } catch (error) {
        logger.error(new Error('Attribute | Unable to get cameraPosition.', { cause: error }));
      }
    }

    const cameraLookAt = this.getAttribute('camera-look-at') || params.cameraLookAt;
    if (cameraLookAt !== undefined) {
      try {
        if (cameraLookAt instanceof Object && validatePosition(cameraLookAt)) {
          this.cameraLookAt = cameraLookAt;
        } else if (typeof cameraLookAt === 'string') {
          const parsedCameraLookAt = JSON.parse(cameraLookAt);
          if (validatePosition(parsedCameraLookAt)) {
            this.cameraLookAt = parsedCameraLookAt;
          } else {
            throw new Error('CameraLookAt requires {x, y, z} format.');
          }
        } else {
          throw new Error('CameraLookAt requires {x, y, z} format.');
        }
      } catch (error) {
        logger.error(new Error('Attribute | Unable to get cameraLookAt.', { cause: error }));
      }
    }

    const openingText = this.getAttribute('opening-text') || params.openingText;
    // opening text to contain AI search string followed by colon and text
    if (openingText !== undefined) {
      this.openingText = openingText;
    }

    const ttsOpeningText = this.getAttribute('tts-opening-text') || params.ttsOpeningText;
    // opening text to contain AI search string followed by colon and text
    if (ttsOpeningText !== undefined) {
      this.ttsOpeningText = ttsOpeningText;
    }

    // Keyboard key code for push to talk feature.
    const pttKey = this.getAttribute('ptt-key') || params.pttKey;

    this.pushToTalk = new PushToTalk({
      container: this.host,
      key: pttKey,
      onToggle: (value) => {
        this.audioControls.muteMic(value);
      },
    });

    // Integrator forced push to talk key.
    this.audioConfig.featureFlags.ptt = pttKey === undefined;

    if (pttKey !== undefined) {
      this.pttKey = pttKey;
      this.pushToTalk.enabled = true;

      Audio.mic.disableNoiseGate();
    }

    if (params.sessionDisconnected) {
      this.sessionDisconnectedCallback = params.sessionDisconnected;
    }
    if (params.sessionConnected) {
      this.sessionConnectedCallback = params.sessionConnected;
    }

    if (params.usersChanged) {
      this.usersChangedCallback = params.usersChanged;
    }

    if (params.stateChanged) {
      this.stateChangedCallback = params.stateChanged;
    }

    if (this.footer === false) {
      this.footerDiv.style.display = 'none';
    } else {
      this.footerDiv.style.display = 'flex';
      this.resizeComponents();
    }

    if (Audio.micRequired) {
      const getMicResult = await Audio.getMic();

      if (getMicResult !== ErrorCode.SUCCESS) {
        this.#throwError(getMicResult, true);
      }

      // Delay sending voice to CPI until character is visible.
      this.audioControls.muteMic(true);
    }

    const projectID = this.getAttribute('project-id') || params.projectId;

    this.#rapportLogo = new RapportLogo({
      logo: this.shadowRoot.getElementById('rapport-logo'),
      container: this.container,
    });

    let aiSessionId = this.getAttribute('ai-session-id') || params.aiSessionId;
    if (aiSessionId === 'undefined' || aiSessionId === 'null') {
      aiSessionId = null;
    }

    const defaultProjectType = 'ai';
    const projectType =
      this.getAttribute('project-type') || params.projectType || defaultProjectType;

    this.container.classList.remove('project-type--ai');
    this.container.classList.remove('project-type--meeting');
    this.container.classList.add(`project-type--${projectType}`);

    let { sceneConfig: sceneConfigData } = params;

    sceneConfigData ??= { assets: [] };

    // Deep clone sceneConfig param.
    sceneConfigData = JSON.parse(JSON.stringify(sceneConfigData));

    this.#rapport = new Rapport(
      logger,
      this.baseUrl,
      projectID,
      this.getAttribute('project-token') || params.projectToken,
      this.getAttribute('lobby-zone-id') || params.lobbyZoneId,
      this.getAttribute('ai-user-id') || params.aiUserId,
      aiSessionId,
      projectType,
      this.getAttribute('room-id') || params.roomId,
      this.getAttribute('self-character-id') || params.selfCharacterId,
      this.getAttribute('self-display-name') || params.selfDisplayName,
      this.getAttribute('preview-model-url') || params.previewModelUrl,
      sceneConfigData,
      params.baseRendererParams,
      this.#rapportCallback.bind(this),
      (level, message) => this.#statusCallback(level, message),
    );

    let ec = ErrorCode.SUCCESS;
    ec = await this.#rapport.start(
      this.container,
      this.background,
      this.orbitalControls,
      this.getAttribute('oc-zoom') || params.ocZoom || false,
      this.getAttribute('oc-angle') || params.ocAngle || 45,
      Number(this.getAttribute('target-fps')) || params.targetFps,
      this.cameraPosition,
      this.cameraLookAt,
      this.forceDefaultCamera,
      this.rendererSetupHook,
    );

    if (ec !== ErrorCode.SUCCESS) {
      this.#throwError(ec, true);
    }

    this.sessionDetails = {
      contractId: this.#rapport.session?.roomDetails?.room_request_id,
    };

    // Control Rapport logo visibility.
    const hideRapportLogo = this.#rapport.session?.roomDetails?.hideRapportLogo;

    hideRapportLogo ? this.#rapportLogo.hide() : this.#rapportLogo.show();

    this.sessionState = 'connected';

    return this.sessionDetails;
  }

  async sessionDisconnect() {
    const { logger } = this;

    if (this.sessionState === 'disconnected') {
      return;
    }

    if (!['connected', 'failed'].includes(this.sessionState)) {
      throw new Error('Session is not in a connected state.');
    }

    this.sessionState = 'disconnecting';

    clearTimeout(this.disconnectTimeout);
    this.disconnectTimeout = null; // need to null handle after clearing
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = null; // need to null handle after clearing

    logger.debug('State | Session disconnected.');

    this.#setLoadingImage(true);
    this.#clearIcons();

    if (this.#rapport) {
      await Promise.all([Audio.dispose(), this.#rapport.disconnect()]);
      this.lights = null;
    }

    // remove listeners
    this.container.removeEventListener('dblclick', this.audioConfig.toggle);

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }

    this.pushToTalk?.dispose();

    this.mutationObserver = null;
    this.modules = null;
    this.#rapport = null;
    this.lastLoggedJitter = null;

    this.#rapportLogo?.hide();

    this.sessionState = 'disconnected';

    this.#rapportCallback(RapportSceneStatus.DISCONNECTED);
  }

  #clearIcons() {
    this.signalIndicator.hide();
    this.audioConfig.dispose();
    this.audioControls.hide();
    this.rapportEmotions.clearIcons();
  }
}
