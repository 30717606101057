const numberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 5,
});

/**
 * Calculate normal vector with scale.
 * @param {number} rad Sin or cos in radians of alpha angle.
 * @param {number} leg Length between mouse position and orig.
 * @param {number} scale Length between orig and screen edge.
 * @returns {number} The normalized vector with scale.
 */
function addVectorScale(rad, leg, scale) {
  return (rad * leg) / scale;
}

export default class LookAtUpxs {
  /**
   * Calculates normal vector with scale based on model eye position
   * and given X and Y positions. Applies throttle between lookAtPosition
   * method calls.
   * Emits "eyeMove" string after lookAtPosition is called.
   * Callback onEyeMove is called with "eyeMove" string.
   * @param {Object} params Contain "look at" parameters, and callback.
   * @param {Object} params.container Element to get model eye position from.
   * @param {Function} params.onEyeMove Callback called with "eyeMove" string.
   */
  constructor(params) {
    Object.assign(this, params);
  }

  container = null;

  onEyeMove = null;

  // 15 scans pre seconds.
  maxInvokeFrequency = 66;

  lastInvokeTime = Date.now() - this.maxInvokeFrequency;

  getEyePosition() {
    const { container } = this;
    const containerBox = container.getBoundingClientRect();

    const x = containerBox.x + container.clientWidth / 2;
    const y = containerBox.y + container.clientHeight / 3;

    return { x, y };
  }

  lookAtPosition({ posX, posY }) {
    const { container, maxInvokeFrequency, lastInvokeTime } = this;

    // Throttle calling this function.
    const now = Date.now();

    if (now - lastInvokeTime < maxInvokeFrequency) {
      return;
    }

    this.lastInvokeTime = now;

    // Get character approximate eye position.
    const { x: origX, y: origY } = this.getEyePosition(container);

    // Get right triangle legs.
    const legX = Math.abs(origX - posX);
    const legY = Math.abs(origY - posY);

    // Calculate hypotenuse.
    const hypotenuse = Math.hypot(legX, legY);

    // Calculate vector.
    let cosAlpha = legX / hypotenuse;
    let sinAlpha = legY / hypotenuse;

    // Set negative sign according to vector direction.
    let scaleX;
    let scaleY;

    if (posX < origX) {
      cosAlpha = -cosAlpha;
      scaleX = Math.abs(0 - origX);
    } else {
      scaleX = window.innerWidth - origX;
    }

    if (posY > origY) {
      sinAlpha = -sinAlpha;
      scaleY = window.innerHeight - origY;
    } else {
      scaleY = Math.abs(0 - origY);
    }

    // Add scale to vector.
    const cosVectorScale = numberFormat.format(addVectorScale(cosAlpha, legX, scaleX));
    const sinVectorScale = numberFormat.format(addVectorScale(sinAlpha, legY, scaleY));

    this.emitEyeMove(`eyeMove: ${cosVectorScale};${sinVectorScale}`);
  }

  emitEyeMove(eyeMove) {
    this.onEyeMove(eyeMove);
  }
}
