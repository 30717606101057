import getBoolean from '../utils/boolean';

/**
 * Comment UI
 * Handle visibility and update
 */
class Comment {
  /**
   * @param {div} statusBar where comments appear
   */
  constructor(statusBar) {
    this.comment = statusBar;

    this.visible = false;
  }

  setVisibility(status) {
    const newStatus = getBoolean(status);

    if (newStatus) this.show();
    else this.hide();
  }

  show() {
    this.visible = true;
    this.comment.style.display = 'block';
  }

  hide() {
    this.visible = false;
    this.comment.style.display = 'none';
  }

  update(newMessage) {
    if (this.visible) {
      this.message = newMessage;
      this.comment.innerHTML = newMessage;
    }
  }

  resetParams() {
    this.message = '';
    this.hide(); // default state - status bar is hidden
  }
}

export default Comment;
