/**
 * implement Modified Queue as per
 * https://jsperf.com/queue-push-unshift-vs-shift-pop/35
 * Used for buffering Animation Frames
 */
function Queue() {
  // initialize the queue and offset
  let queue = [];
  let offset = 0;

  // Returns the length of the queue.
  this.getLength = () => queue.length - offset;

  // Returns the queue.
  this.getQueue = () => queue;

  // Returns true if the queue is empty, and false otherwise.
  this.isEmpty = () => queue.length === 0;

  /* Enqueues the specified item. The parameter is:
   *
   * item - the item to enqueue
   */
  this.enqueue = (item) => queue.push(item);

  /* Dequeues an item and returns it. If the queue is empty, the value
   * 'undefined' is returned.
   */
  this.dequeue = () => {
    // if the queue is empty, return immediately
    if (queue.length === 0) return undefined;

    // store the item at the front of the queue
    const item = queue[offset];

    // don't hold a reference
    queue[offset] = undefined;

    // increment the offset and remove the free space if necessary
    offset += 1;
    if (offset * 2 >= queue.length) {
      queue = queue.slice(offset);
      offset = 0;
    }

    // return the dequeued item
    return item;
  };

  /* Returns the item at the front of the queue (without dequeuing it). If the
   * queue is empty then undefined is returned.
   */
  this.peekNext = () => (queue.length > 0 ? queue[offset] : undefined);

  this.peekLast = () => (queue.length > 0 ? queue[this.getLength() + offset - 1] : undefined);

  this.dispose = () => {
    queue = null;
    offset = null;
  };
}

export default Queue;
