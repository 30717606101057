import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import getObjectCenter from './helpers/getObject3dCenter';

/**
 * Create and attach Three.js TransformControls to given model.
 * Callback onInteract called with value when user interacts with the TransformControls.
 * @param {Object} params Contain TransformControls parameters, and callbacks.
 * @param {Object} params.activeCamera Scene's active camera.
 * @param {Object} params.character Character to attach TransformControls to.
 * @param {Object} params.scene Scene containing the model.
 * @param {Function} params.onInteract Callback called on TransformControls interact.
 */
export default class PreviewTransformControls {
  constructor({
    activeCamera,
    canvas,
    character,
    scene,
    onInteract,
  }) {
    const transformControls = new TransformControls(activeCamera, canvas);
    const { scene: characterModelScene } = character.model;

    this.transformControls = transformControls;
    this.onInteract = onInteract;

    scene.add(transformControls);

    transformControls.attach(characterModelScene);

    const modelCenterPos = getObjectCenter(characterModelScene);

    transformControls.position.set(modelCenterPos.x, modelCenterPos.y, modelCenterPos.z);

    this.addEventListeners();
  }

  get visible() {
    return this.transformControls.visible;
  }

  set visible(value) {
    this.transformControls.visible = value;
  }

  addEventListeners() {
    const { transformControls } = this;

    transformControls.addEventListener('mouseDown', () => {
      this.handleEvent({ type: 'mouseDown' });
    });
    transformControls.addEventListener('mouseUp', () => {
      this.handleEvent({ type: 'mouseUp' });
    });
  }

  handleEvent(e) {
    switch (e.type) {
      case 'mouseDown': {
        this.onInteract(false);
        break;
      }

      case 'mouseUp': {
        this.onInteract(true);
        break;
      }

      default:
        break;
    }
  }

  dispose() {
    this.removeEventListeners();
  }

  removeEventListeners() {
    const { transformControls } = this;

    transformControls.removeEventListener('mouseDown', this);
    transformControls.removeEventListener('mouseUp', this);
  }
}
