/* eslint-disable class-methods-use-this */
/* eslint-disable spellcheck/spell-checker */
import happy from '../../static/images/svgs/happy.svg?raw';
import neutral from '../../static/images/svgs/neutral.svg?raw';
import sad from '../../static/images/svgs/sad.svg?raw';
import acknowledge from '../../static/images/svgs/acknowledge.svg?raw';

/**
 * EmotionIcons UI
 * Handle visibility and update
 */
class EmotionIcons {
  /**
   * @param {Object} params HTMLElements for container, circle, happy, sad, neutral, acknoledge
   */
  constructor(params) {
    this.visible = false;

    // Emotions
    this.happy = params.happy;
    this.happy.innerHTML = happy;

    this.acknowledge = params.acknowledge;
    this.acknowledge.innerHTML = acknowledge;

    this.neutral = params.neutral;
    this.neutral.innerHTML = neutral;

    this.sad = params.sad;
    this.sad.innerHTML = sad;

    this.init();
  }

  init() {
    this.emotions = {
      happy: this.happy,
      positive: this.happy,
      acknowledge: this.acknowledge,
      neutral: this.neutral,
      negative: this.sad,
    };

    this.clearIcons(); // Hide all icons by default
  }

  /**
   * Show container and its content <=> emotion icons visible
   */
  show() {
    this.visible = true;
  }

  /**
   * Hide container and its content<=> emotion icons hidden
   */
  hide() {
    this.visible = false;
  }

  /**
   * Hide all icons
   */
  clearIcons() {
    Object.keys(this.emotions).forEach((key) => {
      const { display } = this.emotions[key].style;
      if (display !== 'none') this.emotions[key].style.display = 'none';
    });
  }

  /**
   * Update which emotion icon should be displayed
   *
   * @param {String} emotion - happy | positive | acknowledge | neutral | negative
   */
  update(emotion) {
    const shouldUpdate = !!this.emotions[emotion];

    if (shouldUpdate) {
      if (!this.visible) this.show();

      this.clearIcons(); // reset all visibility

      this.emotions[emotion].style.display = 'block';
      this.resize();
    }
  }

  resize() {
    if (this.visible) {
      this.happy.style.width = `${this.happy.offsetHeight}px`;
      this.neutral.style.width = `${this.neutral.offsetHeight}px`;
      this.sad.style.width = `${this.sad.offsetHeight}px`;
      this.acknowledge.style.width = `${this.acknowledge.offsetHeight}px`;
    }
  }
}

export default EmotionIcons;
