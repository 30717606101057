/**
 * Invoke passed argument synchronous function at next animation frame.
 * @param {Function} callback Synchronous function to be invoked.
 * @throws {Error} Rejects if callback function throw an error.
 * @returns {Promise<undefined>} Resolves to undefined.
 */
export default async function defer(callback) {
  return new Promise((resolve, reject) => {
    requestAnimationFrame(() => {
      try {
        callback();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  });
}
