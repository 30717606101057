import Module from './Module';
import MethodNames from '../utils/MethodNames';

export default class AsrModule extends Module {
  /**
   * Set the language spoken by user.
   * @param {string} lang Language code to set. Format: 'en-GB'.
   * @param {string} asr Name of the ASR provider. Enum: ['amazon', 'azure', 'google', 'lex'].
   */
  setLanguage(lang, asr = 'amazon') {
    const command = {
      method: MethodNames.SET_ASR,
      params: {
        lang,
        asr,
      },
    };
    this.sendCommand('ASR*', command);
  }

  /**
   * Tries to identify speaker language during the time frame of the timeout duration.
   * If the language identification was successful, the user will be notified with
   * the detected language through the AsrMessage event.
   * Else the user will get a timeout notification through the AsrMessage event.
   * Language identification takes 5 seconds minimum and needs constant voice input.
   * Param languageHints format eg.: 'en-US,de-DE,fr-FR'
   * @param {string} languageHints List of language codes to hint ASR the user's language.
   * @param {number} timeout Language identification process max duration in seconds.
   */
  identifyLanguage({ languageHints: langs, timeout }) {
    const command = {
      method: MethodNames.IDENTIFY_ASR_LANG,
      params: {
        langs,
        timeout,
      },
    };
    this.sendCommand('ASR*', command);
  }
}
