/* eslint-disable spellcheck/spell-checker */
import Browser from '../utils/browser';

const cursorClassName = Object.freeze({ grab: 'cursor--grab', grabbing: 'cursor--grabbing', default: 'cursor--default' });

const cursorGrabStyle = `
  .${cursorClassName.grab} {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
`;

const cursorGrabbingStyle = `
  .${cursorClassName.grabbing} {
    cursor: grabbing;
    cursor: -moz-grabbing; 
    cursor: -webkit-grabbing;
  }
`;

const cursorDefaultStyle = `
  .${cursorClassName.default} {
    cursor: default;
  }
`;

export const cursorStyles = `
  ${cursorDefaultStyle}
  ${cursorGrabbingStyle}
  ${cursorGrabStyle}
`;

/**
 * CanvasCursor
 *
 * Handle cursor update on mouse action
 */
class CanvasCursor {
  /**
   * @param {Object} params = {
   *  canvas: HTMLCanvasElement
   * }
   */
  constructor(params) {
    this.canvas = params.canvas;
    this.isEnabled = params.isEnabled;
    this.listenerStarted = false;
    this.mouseDown = false;

    this.init();
  }

  init() {
    this.handleCanvasMouseDown = this.handleCanvasMouseDown.bind(this);
    this.handleCanvasMouseUp = this.handleCanvasMouseUp.bind(this);

    if (this.isEnabled) {
      if (!Browser.isSafari) this.canvas.className = cursorClassName.grab; // default
      this.setupListeners();
    }
  }

  handleCanvasMouseDown() {
    this.mouseDown = true;
    this.canvas.className = cursorClassName.grabbing;
  }

  handleCanvasMouseUp() {
    this.mouseDown = false;
    this.canvas.className = cursorClassName.grab;
  }

  /**
   * Create listeners on mouse goes down and up
   */
  setupListeners() {
    if (this.canvas && !this.listenerStarted) {
      this.listenerStarted = true;

      this.canvas.addEventListener('mousedown', this.handleCanvasMouseDown);
      this.canvas.addEventListener('mouseup', this.handleCanvasMouseUp);
    }
  }

  /**
   * Clear listeners
   */
  disposeListeners() {
    if (this.canvas && this.listenerStarted) {
      this.canvas.removeEventListener('mousedown', this.handleCanvasMouseDown);
      this.canvas.removeEventListener('mouseup', this.handleCanvasMouseUp);

      this.canvas.className = cursorClassName.default;
      this.listenerStarted = false;
    }
  }

  /**
   * Enabled or disabled the custom cursor
   *
   * @param {Boolean} state
   */
  enabled(isEnabled) {
    switch (isEnabled) {
      case true:
        this.canvas.className = cursorClassName.grab;
        this.setupListeners();
        break;
      default:
        this.disposeListeners();
    }
  }

  /**
   * Fix Safari not initialising the custom cursor on first load
   */
  refresh() {
    if (this.canvas && Browser.isSafari && this.isEnabled) {
      // Cursor refresh for Safari
      this.canvas.className = this.mouseDown ? cursorClassName.grabbing : cursorClassName.grab;
    }
  }
}

export default CanvasCursor;
