import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import getBoolean from '../utils/boolean';

export const updateOrbitControlsAngle = ({ orbitControls, angle }, needUpdate = false) => {
  const updateOrbitControls = orbitControls;

  const newAngle = angle || Math.PI / 4;

  updateOrbitControls.minPolarAngle = Math.PI / 2 - newAngle / 2;
  updateOrbitControls.maxPolarAngle = Math.PI / 2 + newAngle / 2;
  updateOrbitControls.maxAzimuthAngle = newAngle;
  updateOrbitControls.minAzimuthAngle = -newAngle;

  if (needUpdate) {
    updateOrbitControls.update();
  }
};

/**
 * set up default camera for scene
 * @param {THREE.Camera} camera the camera to be controlled.
 * @param {domElement} the HTML element used for event listeners.
 * @param {boolean} enableZoom if orbital zoom desired, default false
 * @param {radians} angle angle in radians allowed, default PI/4
 * @return {OrbitControls} the camera orbit controls
 */
export function setupOrbitControls(
  camera,
  domElement,
  enableZoom = false,
  enableRotate = true,
  angle = Math.PI / 4,
) {
  const orbitControls = new OrbitControls(camera, domElement);
  // if camera position changes to something that is not z forward, theta angle
  // adjusts orbital controls max min azimuth angle for new initial camera position
  // camera.position.x = 0, camera.position.z = 1, theta = Math.atan2(0,1) = 0
  // camera.position.x = 1, camera.position.z = 0, theta = Math.atan2(1,0) = 1.5707963267
  // camera.position.x = 0, camera.position.z = -1, theta = Math.atan2(0,-1) = 3.1415926535
  // camera.position.x = -1, camera.position.z = 0, theta = Math.atan2(-1,0) = -1.5707963267
  // const theta = Math.atan2(camera.position.x, camera.position.z);
  orbitControls.enablePan = false;
  orbitControls.enableZoom = getBoolean(enableZoom);
  orbitControls.enableRotate = getBoolean(enableRotate);

  // Update angle
  updateOrbitControlsAngle({ camera, orbitControls, angle });

  orbitControls.target.set(0, camera.position.y, 0);
  orbitControls.update();

  return orbitControls;
}
