import * as THREE from 'three';
import { jointLookAtPosition, blendShapeLookAtPosition } from '../../../../../../common/lookAt';

const X_AXIS = new THREE.Vector3(1, 0, 0);
const Y_AXIS = new THREE.Vector3(0, 1, 0);
const Z_AXIS = new THREE.Vector3(0, 0, 1);

/**
 * Concatenates another rotation to the given quaternion
 * @param {THREE.Quaternion} baseQuaternion base quaternion
 * @param {THREE.Vector3} rotation SG_COM joint rotation update
 * @return {THREE.Quaternion} concatenated quaternion
 */
function concatenateRotation(baseQuaternion, rotation) {
  const pitchQuaternion = new THREE.Quaternion();
  pitchQuaternion.setFromAxisAngle(X_AXIS, rotation.x);

  const yawQuaternion = new THREE.Quaternion();
  yawQuaternion.setFromAxisAngle(Y_AXIS, rotation.y);

  const rollQuaternion = new THREE.Quaternion();
  rollQuaternion.setFromAxisAngle(Z_AXIS, rotation.z);

  let quaternion = rollQuaternion.multiply(yawQuaternion);
  quaternion = quaternion.multiply(pitchQuaternion);
  quaternion = baseQuaternion.multiply(quaternion);

  return quaternion;
}

/**
 * Update Joint with translation and rotation data
 * @param {Joint} joint The joint in skeleton of the character to update
 * @param {Joint} baseJoint base of joint
 * @param {THREE.Vector3} translation to translate joint
 * @param {THREE.Vector3} rotation to rotate joint
 */
function updateJoint(joint, baseJoint, translation, rotation) {
  joint.position.addVectors(baseJoint.position, translation.divideScalar(100));

  const q = concatenateRotation(baseJoint.quaternion.clone(), rotation);
  joint.setRotationFromQuaternion(q);
}

/**
 * Update blendShape skeleton for animation node
 * @param {Skeleton} skeleton to be updated
 * @param {Node} animationNode to be updated
 */
function updateBlendShape(skeleton, animationNode) {
  const meshGroup = skeleton.blendshapes[animationNode.name];
  if (meshGroup !== undefined) {
    meshGroup.forEach((mesh) => {
      const positionAttribute = mesh.geometry.attributes.position;
      const morphTargetInfluenceMap = skeleton.morphTargetInfluenceMaps[mesh.name];
      const morphedVertexBufferPtr = skeleton.morphedVertexBufferPtr[mesh.name];
      for (let i = 0; i < animationNode.channelNames.length; i += 1) {
        morphTargetInfluenceMap.set(
          animationNode.morphTargetNameArray[i],
          animationNode.channelValues[i],
        );
      }
      const morphTargetAnimation = skeleton.morphTargetAnimation[mesh.name];
      morphTargetAnimation.updateMorphTargets(
        morphTargetInfluenceMap,
        morphedVertexBufferPtr,
      );
      const morphedVertexArray = new Float32Array(
        skeleton.MorphTargetAnimationModule.HEAPU8.buffer,
        morphedVertexBufferPtr,
        positionAttribute.array.length,
      );
      positionAttribute.array.set(morphedVertexArray);
      positionAttribute.needsUpdate = true;
    });
  }
}

/**
 * Update Joints after data parsed for browser version
 * @param {Skeleton} skeleton The skeleton of the character
 * @param {*} animationNodes Array of AnimationNode objects
 * @param {bool} blendAdditive If true, joint animation is added to the current pose
 * @param {THREE.Vector3} lookAt Position where to look at
 */
export default function skeletonUpdate(
  skeleton,
  animationNodes,
) {
  const { modelLookAt } = skeleton;

  // for (const animationNode of animationNodes) {
  animationNodes.forEach((node) => {
    if (node.type === 'JOINT') {
      const joint = skeleton.joints[node.name];
      let baseJoint = skeleton.joints[node.name];

      // Blend additive mode.
      baseJoint = skeleton.joints[node.name];

      // Non blend additive mode.
      // baseJoint = skeleton.jointsOrigin[node.name];

      if (joint !== undefined && baseJoint !== undefined) {
        const translation = new THREE.Vector3(
          node.channelValues[0],
          node.channelValues[1],
          node.channelValues[2],
        );
        let rotation = new THREE.Vector3(
          THREE.MathUtils.degToRad(node.channelValues[3]),
          THREE.MathUtils.degToRad(node.channelValues[4]),
          THREE.MathUtils.degToRad(node.channelValues[5]),
        );

        if (modelLookAt) {
          rotation = jointLookAtPosition(modelLookAt, joint.name, rotation);
        }
        // update each joint individually with vectors
        updateJoint(joint, baseJoint, translation, rotation);
      }
    } else if (node.type === 'BLENDSHAPE') {
      if (modelLookAt) {
        blendShapeLookAtPosition(node, modelLookAt);
      }
      updateBlendShape(skeleton, node);
    }
  });
}
