export default class SignalingConnection {
  /**
   * Create websocket connection for Unreal Pixel Streaming's signaling server.
   * Callback onMessage is called with message from signaling server.
   * Message value is enum of "offer" or "iceCandidate".
   * Callback onError is called with "error" object.
   * Error has "type" prop with value of "signaling".
   * @param {Object} params Contain signaling server parameters, and callbacks.
   * @param {string} params.url Signaling server url.
   * @param {string} params.jwt Signaling server jwt.
   * @param {Function} params.onMessage Callback called with message from signaling server.
   * @param {Function} params.onError Callback called with error object.
   */
  constructor(params) {
    Object.assign(this, params);

    this.connect();
    this.addEventListeners();
  }

  connection = null;

  connect() {
    const { url, jwt } = this;
    const connectionUrl = `${url}?jwt=${jwt}`;

    this.connection = new WebSocket(connectionUrl);
  }

  addEventListeners() {
    const { connection } = this;

    connection.addEventListener('message', this);
    connection.addEventListener('close', this, { once: true });
  }

  handleEvent(e) {
    switch (e.type) {
      case 'message': {
        this.emitMessage(e);
        break;
      }

      case 'close': {
        const error = new Error(`upxs signaling server connection closed: ${e.code} - ${e.reason}`);

        this.emitError(error);
        break;
      }

      default:
        break;
    }
  }

  emitMessage(e) {
    this.onMessage(e);
  }

  emitError(error) {
    error.type = 'signaling';
    this.onError(error);
  }

  send(payload) {
    this.connection.send(JSON.stringify(payload));
  }

  disconnect() {
    const { connection } = this;

    if (connection) {
      this.removeEventListeners();
      connection.close(1000, 'normal closure');
    }
  }

  removeEventListeners() {
    const { connection } = this;

    connection.removeEventListener('message', this);
    connection.removeEventListener('close', this, { once: true });
  }
}
