/**
 * In some cases we are using both string and boolean type for setting
 * a boolean value. This function will return a correct boolean value even
 * if you pass a string
 *
 * @param {Any} value - ideally a string or a boolean
 * @returns boolean
 */
const getBoolean = (value) => (!!(value && value.toString().toLowerCase() === 'true'));

export default getBoolean;
