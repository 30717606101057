import defer from '../../utils/defer';

/**
 * Pre render scene to speed up rendering of first frame, and
 * lower main thread blocking time during first couple of frames.
 * Precompile scene, initialize textures, and render first frame of the scene.
 * @param {Function} rendering RWV WebGL rendering object.
 * @throws {Error} Rejects if any prerender step thrown an error.
 * @returns {Promise<undefined>} Resolves to undefined.
 */
export default async function preRender(rendering) {
  const {
    renderer,
    scene,
    activeCamera,
    sceneConfig,
  } = rendering;

  await defer(() => {
    renderer.compile(scene, activeCamera);
  });

  const { characters, sceneObjects } = sceneConfig;

  const textureCaches =
    [...characters, ...sceneObjects]
      .map(({ model }) => Object.values(model.parser.textureCache))
      .flat();

  for (const textureCache of textureCaches) {
    const texture = await textureCache;

    await defer(() => {
      renderer.initTexture(texture);
    });
  }

  await defer(() => {
    rendering.render();
  });
}
