export default {
  ASR_GOOGLE: 'ASR_Google',
  ASR_AMAZON: 'ASR_Amazon',
  ASR_AZURE: 'ASR_Azure',
  ASR_LEX: 'ASR_Lex',
  ASR_WHISPER: 'ASR_Whisper',
  ASR_SPEECHMATICS: 'ASR_Speechmatics',
  ASR_GROG: 'ASR_Groq',
  ANIMATION_CONTROLLER: 'AC_SGCOM',
  WEBRTC: 'WebRTCCommModule',
  CLEVERBOT: 'AI_Cleverbot',
  LEX: 'AI_Lex',
  PANDORABOT: 'AI_Pandorabot',
  VAD: 'VAD',
  OPENAI: 'AI_OpenAI',
  OPENAICHAT: 'AI_OpenAI_Chat',
  OPENAIASSISTANT: 'AI_OpenAI_Assistant',
  VA: 'VoiceAnalytics',
  SA: 'Sentiment',
};
