// list of all camelCase set attributes we listen to
export default Object.freeze({
  BACKGROUND: 'background',
  ORBITAL_CONTROLS: 'orbitalControls',
  OC_ANGLE: 'ocAngle',
  OC_ZOOM: 'ocZoom',
  CAMERA_POSITION: 'cameraPosition',
  CAMERA_LOOK_AT: 'cameraLookAt',
  MODEL_LOOK_AT: 'modelLookAt',
  FORCE_DEFAULT_CAMERA: 'forceDefaultCamera',
  SIGNAL_INDICATOR: 'signalIndicator',
});
