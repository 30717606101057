// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { flatbuffers } from 'flatbuffers';

export class PlayerData {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PlayerData {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPlayerData(bb:flatbuffers.ByteBuffer, obj?:PlayerData):PlayerData {
  return (obj || new PlayerData()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPlayerData(bb:flatbuffers.ByteBuffer, obj?:PlayerData):PlayerData {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PlayerData()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

timeMs():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

animData(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

animDataLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

animDataArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

tts():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

opusData(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

opusDataLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

opusDataArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

rmsv():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

animFloat16(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint16(this.bb!.__vector(this.bb_pos + offset) + index * 2) : 0;
}

animFloat16Length():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

animFloat16Array():Uint16Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? new Uint16Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

engineData0(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

engineData0Length():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

engineData0Array():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

engineData0Csum():number {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

engineData1(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

engineData1Length():number {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

engineData1Array():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

engineData1Csum():number {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

unrealMessage():string|null
unrealMessage(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unrealMessage(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

animationStreamId():number {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

status():string|null
status(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
status(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startPlayerData(builder:flatbuffers.Builder) {
  builder.startObject(13);
}

static addTimeMs(builder:flatbuffers.Builder, timeMs:number) {
  builder.addFieldInt32(0, timeMs, 0);
}

static addAnimData(builder:flatbuffers.Builder, animDataOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, animDataOffset, 0);
}

static createAnimDataVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createAnimDataVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createAnimDataVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startAnimDataVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addTts(builder:flatbuffers.Builder, tts:boolean) {
  builder.addFieldInt8(2, +tts, +false);
}

static addOpusData(builder:flatbuffers.Builder, opusDataOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, opusDataOffset, 0);
}

static createOpusDataVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startOpusDataVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addRmsv(builder:flatbuffers.Builder, rmsv:number) {
  builder.addFieldFloat32(4, rmsv, 0.0);
}

static addAnimFloat16(builder:flatbuffers.Builder, animFloat16Offset:flatbuffers.Offset) {
  builder.addFieldOffset(5, animFloat16Offset, 0);
}

static createAnimFloat16Vector(builder:flatbuffers.Builder, data:number[]|Uint16Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createAnimFloat16Vector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createAnimFloat16Vector(builder:flatbuffers.Builder, data:number[]|Uint16Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(2, data.length, 2);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt16(data[i]!);
  }
  return builder.endVector();
}

static startAnimFloat16Vector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(2, numElems, 2);
}

static addEngineData0(builder:flatbuffers.Builder, engineData0Offset:flatbuffers.Offset) {
  builder.addFieldOffset(6, engineData0Offset, 0);
}

static createEngineData0Vector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startEngineData0Vector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addEngineData0Csum(builder:flatbuffers.Builder, engineData0Csum:number) {
  builder.addFieldInt32(7, engineData0Csum, 0);
}

static addEngineData1(builder:flatbuffers.Builder, engineData1Offset:flatbuffers.Offset) {
  builder.addFieldOffset(8, engineData1Offset, 0);
}

static createEngineData1Vector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startEngineData1Vector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addEngineData1Csum(builder:flatbuffers.Builder, engineData1Csum:number) {
  builder.addFieldInt32(9, engineData1Csum, 0);
}

static addUnrealMessage(builder:flatbuffers.Builder, unrealMessageOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, unrealMessageOffset, 0);
}

static addAnimationStreamId(builder:flatbuffers.Builder, animationStreamId:number) {
  builder.addFieldInt16(11, animationStreamId, 0);
}

static addStatus(builder:flatbuffers.Builder, statusOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, statusOffset, 0);
}

static endPlayerData(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPlayerData(builder:flatbuffers.Builder, timeMs:number, animDataOffset:flatbuffers.Offset, tts:boolean, opusDataOffset:flatbuffers.Offset, rmsv:number, animFloat16Offset:flatbuffers.Offset, engineData0Offset:flatbuffers.Offset, engineData0Csum:number, engineData1Offset:flatbuffers.Offset, engineData1Csum:number, unrealMessageOffset:flatbuffers.Offset, animationStreamId:number, statusOffset:flatbuffers.Offset):flatbuffers.Offset {
  PlayerData.startPlayerData(builder);
  PlayerData.addTimeMs(builder, timeMs);
  PlayerData.addAnimData(builder, animDataOffset);
  PlayerData.addTts(builder, tts);
  PlayerData.addOpusData(builder, opusDataOffset);
  PlayerData.addRmsv(builder, rmsv);
  PlayerData.addAnimFloat16(builder, animFloat16Offset);
  PlayerData.addEngineData0(builder, engineData0Offset);
  PlayerData.addEngineData0Csum(builder, engineData0Csum);
  PlayerData.addEngineData1(builder, engineData1Offset);
  PlayerData.addEngineData1Csum(builder, engineData1Csum);
  PlayerData.addUnrealMessage(builder, unrealMessageOffset);
  PlayerData.addAnimationStreamId(builder, animationStreamId);
  PlayerData.addStatus(builder, statusOffset);
  return PlayerData.endPlayerData(builder);
}
}
