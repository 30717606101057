import * as THREE from 'three';

import setupDefaultRenderer from '../common/setupDefaultRenderer';
import addRendererEffects from '../common/addRendererEffects';

import CanvasCursor from './CanvasCursor';
import replaceCharacterPlaceholder from './lib/replaceCharacterPlaceholder';
import LightsFactory from './lib/LightsFactory';
import Raycaster from './lib/Raycaster';
import preRender from './lib/preRender';

import convertToRadians from '../utils/angle';
import validatePosition from '../utils/validatePosition';

import { RapportSceneStatus } from '../ui/SceneStatus';
import { updateOrbitControlsAngle, setupOrbitControls } from '../common/setupOrbitControls';
import LoggingLevel from '../LoggingLevel';
import ErrorCode from '../ErrorCode';

import PreviewTransformControls from './lib/PreviewTransformControls';

class Rendering {
  constructor({
    scene3D,
    selfPlayer,
    displayName,
    background,
    orbitalControls,
    ocZoom,
    ocAngle,
    targetFps,
    cameraPosition,
    cameraLookAt,
    forceDefaultCamera,
    rendererSetupHook,
    baseRendererParams = {},
    preview,
    logger,
    callback,
    statusCallback,
  }) {
    this.statusCallback = statusCallback;
    this.scene = null;
    this.scene3D = scene3D;
    this.canvasContainer = null;
    this.selfPlayer = selfPlayer;
    this.displayName = displayName;
    this.background = background;
    this.transparent = this.background === 'transparent';
    this.orbitControls = null;
    this.orbitalControls = orbitalControls;
    this.ocAngle = convertToRadians(ocAngle);
    this.targetFps = targetFps || 50;
    this.ocZoom = ocZoom;
    this.callback = callback;
    this.cameraPosition = cameraPosition;
    this.cameraLookAt = cameraLookAt;
    this.forceDefaultCamera = forceDefaultCamera;
    this.activeCamera = null;
    this.sceneDimensions = { height: 0, width: 0 };
    this.aspect = 1;
    this.frameCount = 0;
    this.lightsAPI = null;
    this.rendererSetupHook = rendererSetupHook;
    this.preview = preview;
    this.logger = logger;
    this.baseRendererParams = baseRendererParams;

    if (preview) {
      this.baseRendererParams.preserveDrawingBuffer = true;
    }

    this.glb = null;
    this.raycaster = null;
    this.previewTransformControls = null;
  }

  sceneConfig = null;

  characters = null;

  #activeCamera = null;

  get activeCamera() {
    return this.#activeCamera;
  }

  set activeCamera(camera) {
    this.#activeCamera = camera;

    if (this.orbitControls) {
      this.orbitControls.object = camera;
    }

    this.sceneConfig?.characters.forEach(({ skeleton }) => {
      if (skeleton) {
        skeleton.activeCamera = camera;
      }
    });
  }

  async init(sceneConfig) {
    this.sceneConfig = sceneConfig;
    this.frameCount = 0;
    this.jitterTimer = Date.now();
    this.scene = new THREE.Scene();

    const {
      logger,
      scene,
      rendererSetupHook,
      baseRendererParams,
      selfPlayer,
      displayName,
      transparent,
      background,
      preview,
      aspect,
      ocZoom,
      orbitalControls,
      ocAngle,
      cameraPosition,
      cameraLookAt,
    } = this;

    const renderer = setupDefaultRenderer(rendererSetupHook, baseRendererParams, logger);
    const canvas = renderer.domElement;

    this.renderer = renderer;
    this.canvas = canvas;

    const canvasContainer = document.createElement('div');
    const canvasContainerPartAttributes = selfPlayer ? 'canvasContainer selfCanvasContainer' : 'canvasContainer';

    this.canvasContainer = canvasContainer;
    canvasContainer.setAttribute('part', canvasContainerPartAttributes);
    canvasContainer.classList.add('canvasContainer');
    canvasContainer.appendChild(renderer.domElement);
    this.scene3D.appendChild(canvasContainer);

    const mapDimensions = canvasContainer.getBoundingClientRect();

    renderer.setSize(mapDimensions.width, mapDimensions.height);

    // Create user display name label.
    if (displayName) {
      const displayNameElement = document.createElement('div');
      const displayNamePrefix = selfPlayer ? '(You) ' : '';

      displayNameElement.innerHTML = `${displayNamePrefix}${displayName}`;
      displayNameElement.classList.add('displayName');

      const displayNamePartAttributes = selfPlayer ? 'displayName selfDisplayName' : 'displayName';
      displayNameElement.setAttribute('part', displayNamePartAttributes);

      canvasContainer.appendChild(displayNameElement);
    }

    this.canvasCursor = new CanvasCursor({
      canvas,
      isEnabled: orbitalControls,
    });

    this.callback({ rapportScene: RapportSceneStatus.RESIZE });

    if (!transparent) {
      this.setBackground(background);
    }

    const { characters, sceneObjects } = sceneConfig;

    // Apply the first effects that can be found in any sceneObject to renderer.
    const rendererEffects =
      [...characters, ...sceneObjects]
        .find(({ model }) => model.rendererEffects)?.model?.rendererEffects;

    try {
      this.effects = addRendererEffects(renderer, rendererEffects);
    } catch (error) {
      // If adding effects to the renderer failed we try to render without effects.
      logger.warn(`Rendering | Failed to add renderer effects. ${error}`);
      this.statusCallback(LoggingLevel.WARNING, ErrorCode.FAILURE_ADD_EFFECTS);
    }

    // Add models to scene.
    [...characters, ...sceneObjects].forEach(({ model }) => {
      scene.add(model.scene);
    });

    // Replace first character placeholder with first character if any.
    // Temporary solution until RRMS scene config support is implemented.
    const [characterPlaceholder] = sceneConfig.characterPlaceholders;

    if (characterPlaceholder) {
      replaceCharacterPlaceholder({
        characterPlaceholder,
        character: characters[0],
      });
    }

    // Setup cameras.
    const { cameras } = sceneConfig;

    cameras.forEach((camera) => {
      // Move camera to top level of hierarchy so
      // it won't interfere with scene transform operations.
      scene.attach(camera);
      camera.aspect = aspect;
      camera.updateProjectionMatrix();
    });

    const [defaultCamera, mainSceneCamera] = cameras;

    if (this.forceDefaultCamera || !mainSceneCamera) {
      this.activeCamera = defaultCamera;
    } else {
      this.activeCamera = mainSceneCamera;
    }

    const { activeCamera } = this;

    if (cameraPosition) {
      this.setCameraPosition(cameraPosition);
    }

    this.orbitControls = setupOrbitControls(
      activeCamera,
      canvas,
      ocZoom,
      orbitalControls,
      ocAngle,
    );

    // cameraLookAt changes orbital controls target
    if (cameraLookAt) {
      this.setCameraLookAt(cameraLookAt);
    }

    // Setup lights.
    const lights =
      [...characters, ...sceneObjects]
        .map(({ model }) => model.lights)
        .flat();

    // Enable physicallyCorrectLights when using default lights.
    if (!lights.length) {
      this.renderer.physicallyCorrectLights = true;
    }

    this.lightsAPI = new LightsFactory(scene, lights, logger);

    sceneConfig.lights.forEach((light) => {
      this.lightsAPI.create(light);
    });

    this.raycaster = new Raycaster({
      sceneChildren: scene.children,
      activeCamera,
      canvas,
      onMeshesIntersected: (meshes) => {
        this.callback({
          eventType: RapportSceneStatus.MESHES_INTERSECTED,
          payload: meshes,
        });
      },
    });

    if (preview) {
      this.previewTransformControls = new PreviewTransformControls({
        activeCamera,
        canvas,
        character: characters[0],
        scene,
        onInteract: (value) => {
          this.setOrbitalRotation(value);
        },
      });
    }

    // Pre render scene before first render cycle.
    await preRender(this);
  }

  getCameraPosition() {
    return {
      x: this.activeCamera.position.x,
      y: this.activeCamera.position.y,
      z: this.activeCamera.position.z,
    };
  }

  setBackground(background) {
    if (background) {
      const isTransparent = background === 'transparent';
      if (isTransparent) {
        this.transparent = isTransparent;
        this.scene.background = null;
        return;
      }

      const urlCheck = ['http', './'];
      const isUrl = urlCheck.some((el) => background.includes(el));
      if (isUrl) {
        const loader = new THREE.TextureLoader();
        const bgTexture = loader.load(background);
        this.scene.background = bgTexture;
      } else {
        this.scene.background = new THREE.Color(background);
      }
    }
  }

  setOrbitalRotation(isEnabled) {
    if (this.orbitControls) {
      this.orbitControls.enableRotate = isEnabled;
      this.canvasCursor.enabled(isEnabled);
    }
  }

  setOcZoom(value) {
    if (this.orbitControls) {
      this.orbitControls.enableZoom = value;
    }
  }

  setAngle(angle) {
    const { activeCamera, orbitControls } = this;
    if (activeCamera && orbitControls && angle) {
      updateOrbitControlsAngle({
        activeCamera,
        orbitControls,
        angle: convertToRadians(angle),
      }, true);
    }
  }

  setCameraPosition(position) {
    const { activeCamera, orbitControls } = this;
    const updatePosition = !!activeCamera && validatePosition(position);
    if (updatePosition) {
      const { x, y, z } = position;
      activeCamera.position.set(x, y, z);
      orbitControls?.update();
    }
  }

  setCameraLookAt(position) {
    const { activeCamera, orbitControls } = this;
    const updatePosition = !!activeCamera && validatePosition(position);
    if (updatePosition) {
      const { x, y, z } = position;
      orbitControls.target.set(x, y, z);
      orbitControls.update();
    }
  }

  disposeRenderer() {
    if (this.scene) {
      const sceneTraverse = (obj, fn) => {
        if (!obj) return;
        fn(obj);
        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((o) => {
            sceneTraverse(o, fn);
          });
        }
      };
      sceneTraverse(this.scene, (o) => {
        if (o.geometry) {
          o.geometry.dispose();
        }
        if (o.material) {
          if (o.material.length) {
            for (let i = 0; i < o.material.length; i += 1) {
              o.material[i].dispose();
            }
          } else {
            o.material.dispose();
          }
        }
      });
      this.scene = null;
    }
    if (this.renderer) {
      this.renderer.dispose();
      this.effects = null;
      this.renderer = null;
    }
  }

  dispose() {
    this.canvasContainer?.remove();

    if (this.lightsAPI) {
      this.lightsAPI.delete();
    }
    this.disposeRenderer();
    this.activeCamera = null;
    if (this.orbitControls) {
      this.orbitControls.dispose();
      this.orbitControls = null;
    }

    if (this.canvasCursor) {
      this.canvasCursor.disposeListeners();
    }

    this.raycaster?.dispose();

    this.previewTransformControls?.dispose();
  }

  resizeRenderer() {
    const { logger } = this;

    // see issue https://github.com/mrdoob/three.js/issues/16747
    try {
      const {
        renderer,
        activeCamera,
        canvas,
        displayName,
        scene3D,
        canvasContainer,
      } = this;
      const {
        width: containerWidth,
        height: containerHeight,
      } = scene3D.getBoundingClientRect();

      if (!this.renderer) {
        return;
      }

      // Only consider relative elements to the size calculation.
      const relativeElemCount = Array.from(scene3D.children)
        .filter((elem) => getComputedStyle(elem).position === 'relative').length;

      let width = containerWidth / relativeElemCount;
      let height = containerHeight;
      const canvasContainerStyle = getComputedStyle(canvasContainer);
      const minRendererWidth = canvasContainerStyle.getPropertyValue('--minDimension').replace('px', '');

      width = width < minRendererWidth ? minRendererWidth : width;

      const marginLeft = parseFloat(canvasContainerStyle.marginLeft);
      const marginRight = parseFloat(canvasContainerStyle.marginRight);
      const marginTop = parseFloat(canvasContainerStyle.marginTop);
      const marginBottom = parseFloat(canvasContainerStyle.marginBottom);

      width -= marginLeft + marginRight;
      height -= marginTop + marginBottom;

      const borderLeftWidth = parseFloat(canvasContainerStyle.borderLeftWidth);
      const borderRightWidth = parseFloat(canvasContainerStyle.borderRightWidth);
      const borderTopWidth = parseFloat(canvasContainerStyle.borderTopWidth);
      const borderBottomWidth = parseFloat(canvasContainerStyle.borderBottomWidth);

      width -= borderLeftWidth + borderRightWidth;
      height -= borderTopWidth + borderBottomWidth;

      // Force 1/1 aspect ratio canvases for meeting rooms.
      if (displayName) {
        height = Math.min(width, height);
        width = height;
      }

      // Force default size on elements which positions are modified by the integrator.
      if (canvasContainerStyle.position !== 'relative') {
        const containerSize = Math.max(canvasContainerStyle.width.replace('px', ''), minRendererWidth);

        width = containerSize;
        height = containerSize;
      }

      // Update renderer
      renderer.setSize(width, height);

      // Update Camera
      if (activeCamera) {
        activeCamera.aspect = canvas.clientWidth / canvas.clientHeight;
        activeCamera.updateProjectionMatrix();
      }
    } catch (error) {
      logger.error(new Error('Rendering | Failed to resize renderer.', { cause: error }));
    }
  }

  /**
   * check if the scene container size has changed
   * and call resizeRenderer()
   */
  updateCanvasSize() {
    const { height: oldHeight, width: oldWidth } = this.sceneDimensions;
    const { height, width } = this.scene3D.getBoundingClientRect();

    if (oldHeight !== height || oldWidth !== width) {
      this.sceneDimensions = { height, width };
      this.resizeRenderer();
    }
  }

  updateSceneObjectAnimations(frameTime) {
    const { characters, sceneObjects } = this.sceneConfig;

    [...characters, ...sceneObjects].forEach((sceneObject) => {
      sceneObject.updateAnimation(frameTime);
    });
  }

  render() {
    const {
      effects,
      renderer,
      scene,
      activeCamera,
    } = this;

    if (effects) {
      effects.render(scene, activeCamera);
    } else {
      renderer.render(scene, activeCamera);
    }
  }

  renderLoop(frameTime) {
    const {
      logger,
      renderer,
      scene,
      activeCamera,
      canvasCursor,
      nextFrameTargetTime = frameTime,
      targetFps,
    } = this;

    if (!renderer || !scene || !activeCamera) {
      return;
    }

    // First frame
    if (!frameTime) {
      canvasCursor.refresh();

      logger.debug('Rendering | Animating started.');
    }

    this.updateCanvasSize();

    // Cap frame rate by delay render until desired time.
    // Skip render at first frame.
    if (!frameTime || (frameTime < nextFrameTargetTime)) {
      requestAnimationFrame((newFrameTime) => this.renderLoop(newFrameTime));

      return;
    }

    // Desired delay between two renders in milliseconds.
    const fpsInterval = 1000 / targetFps;
    // Current frame's delay compared to desired delay.
    let frameTargetOvertime = frameTime - nextFrameTargetTime;

    // If screen refresh rate is lower then desired frame rate then disable frame rate cap.
    if (frameTargetOvertime > fpsInterval) {
      frameTargetOvertime = fpsInterval;
    }

    // Schedule next frame's timestamp.
    this.nextFrameTargetTime = frameTime + fpsInterval - frameTargetOvertime;

    this.updateSceneObjectAnimations(frameTime);

    this.frameCount += 1;

    if (this.frameCount === 10) {
      this.callback({ rapportScene: RapportSceneStatus.RENDERING });
    }

    this.render();

    requestAnimationFrame((newFrameTime) => this.renderLoop(newFrameTime));
  }
}

export default Rendering;
