import EventType from '../EventType';

/**
 * dispatch object to integrator
 * @param {Object} obj to be dispatched
 */
export default function publicAPIDispatch(obj) {
  /**
   * dispatch an event to the host
   * @param {String} eventName of host listener
   * @param {*} payload message
   */
  if (!this.shadowDispatch) {
    this.shadowDispatch = function shadowDispatch(eventName, payload) {
      this.shadowRoot.dispatchEvent(
        new CustomEvent(eventName, {
          detail: payload,
          bubbles: false,
          cancelable: false,
          composed: true,
        }),
      );
    };
  }

  switch (obj.type) {
    case EventType.SESSION_CONNECTED: {
      if (this.sessionConnectedCallback) {
        this.sessionConnectedCallback(obj.payload);
      }
      const sessionConnectedEvent = new CustomEvent(EventType.SESSION_CONNECTED, {
        detail: obj.payload,
        bubbles: false,
        cancelable: false,
        composed: true,
      });
      this.dispatchEvent(sessionConnectedEvent);
      break;
    }
    case EventType.COMPONENT_CONNECTED: {
      // cannot have a component connected callback
      // needs to be connected before you can assign callback in sessionRequest
      const componentConnectedEvent = new CustomEvent(EventType.COMPONENT_CONNECTED, {
        bubbles: false,
        cancelable: false,
        composed: true,
      });
      this.dispatchEvent(componentConnectedEvent);
      break;
    }
    case EventType.ERROR: // ERROR becomes SESSION_DISCONNECTED event type
    case EventType.SESSION_DISCONNECTED:
      // we disconnect. should API sessionDisconnect throw errors? try catch ignore?
      this.sessionDisconnect();
      if (this.sessionDisconnectedCallback) {
        this.sessionDisconnectedCallback(obj.msg);
      }
      this.shadowDispatch(EventType.SESSION_DISCONNECTED, obj.msg);
      break;
    case EventType.WARNING:
      if (this.warningCallback) {
        this.warningCallback(obj.msg);
      }
      this.shadowDispatch(EventType.WARNING, obj.msg); // and message and type
      break;
    case EventType.INFO:
      if (this.infoCallback) {
        this.infoCallback(obj.msg);
      }
      this.shadowDispatch(EventType.INFO, obj.msg); // and message and type
      break;
    case EventType.SEND_CPIM_MESSAGE: {
      if (obj.msg.Payload.method === 'msg-from-unreal') {
        const { event, payload } = obj.msg.Payload.params;
        const eventCallback = this[`${event}Callback`];

        this.shadowDispatch(event, payload);
        eventCallback?.(payload);

        break;
      }

      const eventTypeMap = {
        AI: {
          eventName: 'aiMessage',
          eventCallback: 'aiMessageCallback',
        },
        ASR: {
          eventName: 'asrMessage',
          eventCallback: 'asrMessageCallback',
        },
        Sentiment: {
          eventName: 'saMessage',
          eventCallback: 'saMessageCallback',
        },
        VoiceAnalytics: {
          eventName: 'vaMessage',
          eventCallback: 'vaMessageCallback',
        },
      };

      const eventType = eventTypeMap[obj.msg.SourceCPIMType];

      if (!eventTypeMap[obj.msg.SourceCPIMType]) {
        return;
      }

      const { eventName, eventCallback } = eventType;
      const payload = obj.msg.Payload;

      this.shadowDispatch(eventName, payload);
      this[eventCallback]?.(payload);

      break;
    }
    case EventType.TTS_START: {
      this.shadowDispatch(EventType.TTS_START, obj.payload);
      if (this.ttsStartCallback) {
        this.ttsStartCallback(obj.payload);
      }
      this.shadowDispatch('ttsOn', { tts: 'on' });
      this.ttsOnCallback?.({ tts: 'on' });
      this.shadowDispatch('ttsMessage', { params: obj.payload });
      this.ttsMessageCallback?.({ params: obj.payload });
      break;
    }
    case EventType.TTS_END: {
      this.shadowDispatch(EventType.TTS_END, obj.payload);
      if (this.ttsEndCallback) {
        this.ttsEndCallback(obj.payload);
      }
      this.shadowDispatch('ttsOff', { tts: 'off' });
      this.ttsOffCallback?.({ tts: 'off' });
      break;
    }
    case EventType.USERS_CHANGED: {
      if (this.usersChangedCallback) {
        this.usersChangedCallback();
      }
      const usersChangedEvent = new CustomEvent(EventType.USERS_CHANGED, {
        bubbles: false,
        cancelable: false,
        composed: true,
      });
      this.dispatchEvent(usersChangedEvent);
      break;
    }
    case EventType.STATE_CHANGED: {
      if (this.stateChangedCallback) {
        this.stateChangedCallback(obj.payload);
      }
      const stateChangedEvent = new CustomEvent(EventType.STATE_CHANGED, {
        detail: obj.payload,
        bubbles: false,
        cancelable: false,
        composed: true,
      });
      this.dispatchEvent(stateChangedEvent);
      break;
    }
    case 'animationFinished': {
      this.shadowDispatch('animationFinished', obj.payload);
      if (this.animationFinishedCallback) {
        this.animationFinishedCallback(obj.payload);
      }
      break;
    }
    case EventType.MESHES_INTERSECTED: {
      this.shadowDispatch(EventType.MESHES_INTERSECTED, obj.payload);
      if (this.meshesIntersectedCallback) {
        this.meshesIntersectedCallback(obj.payload);
      }
      break;
    }
    case 'moduleError': {
      this.shadowDispatch('moduleError', obj.payload);
      this.moduleErrorCallback?.(obj.payload);
      break;
    }
    default: {
      break;
    }
  }
}
