export default class AudioBufferSource {
  /**
   * A virtual audio player which processes 20 ms audio chunks and pipe them to the outputNode.
   * @param {Object} params AudioBufferSource constructor params.
   * @param {Object} params.context App audio context.
   * @param {Object} params.outputNode Output audio node which the source is connected to.
   * @param {Object} params.gain Starting gain of the source audio node.
   */
  constructor(params) {
    const { context, outputNode, gain } = params;
    this.context = context;
    this.gainNode = context.createGain();
    this.gainNode.connect(outputNode);

    this.setGain(gain, 0);
    this.previousGainNodeValue = gain;
  }

  createBuffer(chunk) {
    const { context } = this;

    const buffer = context.createBuffer(1, chunk.length, 48000);
    const channelData = buffer.getChannelData(0);

    for (let i = 0; i < channelData.length; i += 1) {
      channelData[i] = chunk[i] / 32768.0;
    }

    return buffer;
  }

  playAudioFrame({ audioFrame, playTime }) {
    const { context, gainNode } = this;
    const bufferPlayTime = playTime / 1000;
    const buffer = this.createBuffer(audioFrame);
    const source = new AudioBufferSourceNode(context, { buffer });

    source.connect(gainNode);
    source.start(bufferPlayTime);
  }

  // Always use this method to change gain. Setting the gain directly will cause audio issues.
  setGain(value, timeConstant) {
    const { gainNode, context } = this;

    gainNode.gain.setTargetAtTime(value, context.currentTime, timeConstant);
  }
}
