const jsonrpc = '2.0';
let counter = 0;
export default class Module {
  constructor(callback, throwError) {
    this.callback = callback;
    this.throwError = throwError;
  }

  /**
   * Send a command to CPI
   * @param {Object} module CPI module, ASR, TTS, AC or AI
   * @param {Object} payload to be sent to module
   */
  sendCommand(module, payload) {
    counter += 1;
    const newPayload = {
      jsonrpc,
      ...payload,
      id: counter,
    };

    const message = { TargetCPIM: module, Payload: newPayload };
    this.callback(JSON.stringify(message));
  }
}
