import Rendering from '../../rendering/Rendering';
import parseSceneConfig from './parseSceneConfig/parseSceneConfig';

export default class PlayerWebgl {
  /**
   * Creates a webgl player which contains rendering, virtual audio player and sceneConfig parsing.
   * Callbacks are called with notification events.
   *
   * @param {Object} params Contain player parameters, and callbacks.
   * @param {String} params.clientId Player client id.
   * @param {Boolean} params.selfPlayer Flag to control UI elements.
   * @param {Object} params.sceneConfigData sceneConfigData to setup scene of.
   * @param {Object} params.audioBufferSourceParams Params passed to audioBufferSource class.
   * @param {Object} params.renderingParams Params passed to renderer class.
   * @param {Object} params.logger Global logger object.
   * @param {Function} params.onCallback Callback called with notifications.
   * @param {Function} params.onStatusCallback Callback called with rendering notifications.
   */
  constructor(params) {
    Object.assign(this, {
      clientId: params.clientId,
      selfPlayer: params.selfPlayer,
      sceneConfigData: params.sceneConfigData,
      audioBufferSourceParams: params.audioBufferSourceParams,
      logger: params.logger,
      onCallback: params.onCallback,
      onStatusCallback: params.onStatusCallback,
    });

    this.ready = new Promise((resolve, reject) => {
      this.readyResolver = resolve;
      this.readyRejecter = reject;
    });

    this.rendering = new Rendering({
      selfPlayer: params.selfPlayer,
      logger: params.logger,
      ...params.renderingParams,
      statusCallback: (...args) => this.emitStatusCallback(...args),
    });
  }

  ready = null;

  readyResolver = null;

  readyRejecter = null;

  enabled = false;

  sceneConfig = null;

  rendering = null;

  logger = null;

  get sceneObjects() {
    return this.sceneConfig?.sceneObjects || [];
  }

  get characters() {
    return this.sceneConfig?.characters || [];
  }

  async init() {
    const { logger } = this;

    try {
      const { sceneConfigData, audioBufferSourceParams } = this;

      const sceneObjectParams = {
        onAnimationFinished: (payload) => {
          this.emitCallback({ eventType: 'animationFinished', payload });
        },
      };
      const playbackQueueParams = {
        audioBufferSourceParams,
        onJitterChange: (payload) => {
          const { clientId } = this;

          payload.clientId = clientId;

          this.emitCallback({ eventType: 'jitterChange', payload });
        },
      };

      this.sceneConfig = await parseSceneConfig({
        sceneConfigData,
        sceneObjectParams,
        playbackQueueParams,
        logger,
      });

      this.readyResolver();
    } catch (error) {
      this.readyRejecter(error);

      throw error;
    }
  }

  async startRendering() {
    const {
      sceneConfig,
      rendering,
      characters,
    } = this;

    await rendering.init(sceneConfig);

    const { canvas, activeCamera } = rendering;

    const initSkeletonPromises =
      characters.map((character) => character.initSkeleton({ canvas, activeCamera }));

    await Promise.all(initSkeletonPromises);

    rendering.renderLoop();

    this.enabled = true;
  }

  suspendJitter() {
    const { sceneConfig } = this;

    sceneConfig?.characters?.forEach(({ playbackQueue }) => {
      playbackQueue.jitter.suspended = true;
    });
  }

  emitCallback(...args) {
    this.onCallback(...args);
  }

  emitStatusCallback(...args) {
    this.onStatusCallback(...args);
  }

  dispose() {
    const { sceneObjects, characters } = this;

    this.rendering.dispose();

    [...sceneObjects, ...characters].forEach((sceneObject) => {
      globalThis.URL.revokeObjectURL(sceneObject.file.cacheUrl);

      sceneObject.dispose();
    });
  }
}
