import * as THREE from 'three';
import getBoundingBox from './getBoundingBox';

/**
 * Get Three.js Object3D center coordinates.
 *
 * @param {Object} object3d Three.js Object3D to get center coordinates from.
 * @return {Object} Three.js vector3, contains given object's center coordinates.
 */
export default function getObject3dCenter(object3d) {
  const boundingBox = getBoundingBox(object3d);
  const center = new THREE.Vector3();

  boundingBox.getCenter(center);

  return center;
}
