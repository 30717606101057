/* eslint-disable max-len */
import configureMorphTargetDictionary from './configureMorphTargetDictionary';
import MorphTargetAnimationModuleUrl from '../../../../../../../static/wasm/MorphTargetAnimation.wasm?url';
import MorphTargetAnimationModule from '../../../../../../wasm/MorphTargetAnimation.mjs';
import AnimationNode from './AnimationNode';
import validateAnimationNodes from './validateAnimationNodes';
import setModelLookAt from './setModelLookAt';
import skeletonUpdate from './skeletonUpdate';

/*
* Setup the AC_SGCOM Animation Nodes structure from the JSON packet
* @return {Array} Array of AnimationNode objects
*/
function configureAnimationNodes(animationNodes) {
  return animationNodes.map((node) => {
    const animationNode = new AnimationNode();

    animationNode.name = node.name;
    animationNode.channelNames = Array.from(node.channels);

    if (node.type === 'joint') {
      animationNode.type = 'JOINT';
    } else if (node.type === 'blendshape') {
      animationNode.type = 'BLENDSHAPE';
      for (let i = 0; i < animationNode.channelNames.length; i += 1) {
        animationNode.morphTargetNameArray.push(`${animationNode.name}.${animationNode.channelNames[i]}`);
      }
    }

    return animationNode;
  });
}

class Skeleton {
  constructor({
    scene,
    canvas,
    activeCamera,
    animationNodes,
    logger,
  }) {
    this.scene = scene;
    this.canvas = canvas;
    this.activeCamera = activeCamera;

    this.animationNodes = configureAnimationNodes(animationNodes);

    this.logger = logger;

    const {
      blendshapes,
      joints,
      jointsOrigin,
    } = configureMorphTargetDictionary(scene, this.animationNodes);

    this.blendshapes = blendshapes; // Key:string (Blendshape name) Value: Array of three.js.Object3D (Mesh) Dictionary of meshes that have blendshapes
    this.joints = joints; // Key:string Value:three.js.Object3D  Current values of the skeleton
    this.jointsOrigin = jointsOrigin; // Key:string Value:three.js.Object3D  Base values of the skeleton
    this.jointsCache = {}; // Key:string Value:three.js.Object3D  Cached values of the bones targeted by SG Com

    // WASM
    this.MorphTargetAnimationModule = {}; // WASM module
    this.morphTargetAnimation = {}; // Key:string (Mesh name) Value:MorphTargetAnimationModule.MorphTargetAnimation object
    this.morphTargetInfluenceMaps = {}; // Key:string (Mesh name) Value:Map of channelName/channelValue
    this.morphedVertexBufferPtr = {}; // Key:string (Mesh name) Value:Binary buffer of vertex data, after the application of morph targets

    // Validates SG_Com animation nodes against the loaded model. Only logs invalid nodes.
    validateAnimationNodes(this.animationNodes, this, logger);

    this.cacheTargetJoints();
  }

  animationNode = null;

  modelLookAt = null;

  activeCamera = null;

  async init() {
    await this.initializeWASM();
  }

  // Must be called after the blendshapes property has been initialized
  async initializeWASM() {
    this.MorphTargetAnimationModule = await MorphTargetAnimationModule({ locateFile: () => MorphTargetAnimationModuleUrl });
    Object.keys(this.blendshapes).forEach((key) => {
      this.blendshapes[key].forEach((mesh) => {
        const positionAttribute = mesh.geometry.attributes.position;
        let elementOffset = 0;
        let elementStride = 3;
        if (positionAttribute.isInterleavedBufferAttribute) {
          elementOffset = positionAttribute.offset;
          elementStride = positionAttribute.data.stride;
        }
        // Copy the vertex buffer to WASM memory
        const morphTargetAnimation = new this.MorphTargetAnimationModule.MorphTargetAnimation();
        const vertexBufferPtr = this.arrayToHeap(positionAttribute.array.slice(0));
        const vertexBufferLength = positionAttribute.array.length;
        const morphTargetsMap = new this.MorphTargetAnimationModule.MapStringVectorFloat();

        // Allocate WASM memory for morph targets
        Object.keys(mesh.morphTargetDictionary).forEach((morphTargetName) => {
          const morphTargetPositionAttribute = mesh.geometry.morphAttributes.position[mesh.morphTargetDictionary[morphTargetName]];
          const morphTargetPositionBuffer = new this.MorphTargetAnimationModule.VectorFloat();
          morphTargetPositionAttribute.array.forEach((element) => {
            morphTargetPositionBuffer.push_back(element);
          });
          morphTargetsMap.set(morphTargetName, morphTargetPositionBuffer);
        });

        morphTargetAnimation.initialize(vertexBufferPtr, vertexBufferLength, elementOffset, elementStride, morphTargetsMap);
        this.morphTargetAnimation[mesh.name] = morphTargetAnimation;
        this.morphTargetInfluenceMaps[mesh.name] = new this.MorphTargetAnimationModule.MapStringFloat();
        // eslint-disable-next-line no-underscore-dangle
        this.morphedVertexBufferPtr[mesh.name] = this.MorphTargetAnimationModule._malloc(positionAttribute.array.byteLength);
      });
    });
  }

  // Copy a TypedArray to the WASM heap
  arrayToHeap(array) {
    const numBytes = array.byteLength;
    // eslint-disable-next-line no-underscore-dangle
    const arrayPtr = this.MorphTargetAnimationModule._malloc(numBytes);
    const heapBytes = new Uint8Array(this.MorphTargetAnimationModule.HEAPU8.buffer, arrayPtr, numBytes);
    const tmpArray = new Uint8Array(array.buffer, 0, numBytes);
    heapBytes.set(tmpArray);
    return arrayPtr;
  }

  // Reset targeted joints to the cached pose
  resetTargetJointsToCachedPose() {
    const {
      animationNodes,
      jointsOrigin,
      joints,
      jointsCache,
    } = this;

    animationNodes.forEach((node) => {
      if (node.type === 'JOINT') {
        if ((node.name in jointsOrigin)) {
          joints[node.name].position.copy(jointsCache[node.name].position);
          joints[node.name].quaternion.copy(jointsCache[node.name].quaternion);
        }
      }
    });
  }

  // Cache the current pose of the target joints
  cacheTargetJoints() {
    const {
      animationNodes,
      jointsCache,
      joints,
    } = this;

    animationNodes.forEach((node) => {
      if (node.type === 'JOINT') {
        if ((node.name in joints)) {
          jointsCache[node.name] = joints[node.name].clone();
        }
      }
    });
  }

  setModelLookAt(params) {
    const { scene, canvas, activeCamera } = this;

    this.modelLookAt = setModelLookAt(params, canvas, activeCamera, scene);
  }

  update(animationData) {
    const { animationNodes } = this;

    const { animData } = animationData;
    let animDataIdx = 0;

    animationNodes.forEach((node) => {
      node.channelValues = animData.slice(animDataIdx, animDataIdx + node.channelNames.length);
      animDataIdx += node.channelNames.length;
    });

    skeletonUpdate(this, animationNodes);
  }
}

export default Skeleton;
