import EventType from './EventType';
import LoggingLevel from './LoggingLevel';

export default class RapportStatus extends Error {
  constructor(level, value) {
    super(value.message);
    this.code = value.code;
    this.type = this.lookupTypeFromLevel(level);
  }

  toAPIDispatchObj() {
    return {
      type: this.type,
      msg: {
        message: this.message, // 'PERMISSION DENIED: microphone access not granted by user'
        code: this.code, // NO_MIC_PERMISSION
        level: this.type, // 'warning'
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  lookupTypeFromLevel(level) {
    switch (level) {
      case LoggingLevel.INFO:
        return EventType.INFO;
      case LoggingLevel.WARNING:
        return EventType.WARNING;
      case LoggingLevel.ERROR:
        return EventType.ERROR;
      default:
        return EventType.ERROR;
    }
  }

  isError() {
    if (this.type === EventType.ERROR) {
      return true;
    }
    return false;
  }

  isWarning() {
    if (this.type === EventType.WARNING) {
      return true;
    }
    return false;
  }

  isInfo() {
    if (this.type === EventType.WARNING) {
      return true;
    }
    return false;
  }
}
