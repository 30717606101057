import Module from './Module';
import MethodNames from '../utils/MethodNames';
import ModuleNames from '../utils/ModuleNames';

export default class VadModule extends Module {
  /**
   * Set the VAD aggressiveness mode.
   * @param {number} mode VAD aggressiveness mode.
   */
  setMode(mode) {
    const command = {
      method: MethodNames.SET_VAD_MODE,
      params: { mode },
    };

    this.sendCommand(ModuleNames.VAD, command);
  }

  /**
   * Set threshold for switching between false and true.
   * @param {Object} params The VAD classifier produces a sequence of true/false values.
   * @param {number} params.true_ms A continuous sequence of at least `threshold` of true value.
   * @param {number} params.false_ms A continuous sequence of at least `threshold` of false value.
   */
  setThreshold(params) {
    const command = {
      method: MethodNames.SET_VAD_THRESHOLD,
      params,
    };

    this.sendCommand(ModuleNames.VAD, command);
  }
}
