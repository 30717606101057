import { RapportSceneStatus } from '../ui/SceneStatus';

/**
 * calculates the width of longest string RapportSceneStatus.CACHED_MODEL
 * for given fontsize
 * @param {float} fontsize to calculate width of longest string for
 * @returns width of font size
 */
function getWidthOfText(fontsize) {
  const text = RapportSceneStatus.CACHED_MODEL; // Longest string to rapport Comments
  const fontName = 'Montserrat,sans-serif';
  if (getWidthOfText.c === undefined) {
    getWidthOfText.c = document.createElement('canvas');
    getWidthOfText.context = getWidthOfText.c.getContext('2d');
  }
  const fontSpec = `${fontsize}px ${fontName}`;
  if (getWidthOfText.context.font !== fontSpec) {
    getWidthOfText.context.font = fontSpec;
  }
  return getWidthOfText.context.measureText(text).width;
}

/**
 * binary search of font sizes to find least width
 * @param {float} width that is being searched for
 * @param {int} start lowest font size
 * @param {*} end highest font size
 * @returns end value or another search
 */
function binarySearch(width, start, end) {
  // Base Condition
  if (start > end) {
    return end;
  }

  // Find the middle index
  const mid = Math.floor((start + end) / 2);
  const textWidth = getWidthOfText(mid);

  // If element at mid is greater than x,
  // search in the left half of mid
  if (textWidth > width) {
    return binarySearch(width, start, mid - 1);
  }
  // If element at mid is smaller than x,
  // search in the right half of mid
  return binarySearch(width, mid + 1, end);
}

/**
 * getFont size requird for a given width
 * @param {int} width of string container
 * @returns font size between 5 and 80
 */
export default function getFontSize(width) {
  return binarySearch(width, 5, 80);
}
