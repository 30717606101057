import * as THREE from 'three';

/**
 * getBoundingBoxCorners
 * @param {THREE.Box3} boundingBox to get the corners of
 * @return {Array} Vectors of bounding box corner positions
 */
export default function getBoundingBoxCorners(boundingBox) {
  return [
    new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z), // 000
    new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z), // 001
    new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z), // 010
    new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z), // 011
    new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z), // 100
    new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z), // 101
    new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z), // 110
    new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z), // 111
  ];
}
