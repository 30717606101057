import getBoundingBox from './helpers/getBoundingBox';

/**
 * Replace placeholder with character model by setting it's scale and transform properties
 * to match with placeholder model's.
 * @param {object} params Placeholder character replace parameters.
 * @param {object} params.characterPlaceholder Placeholder to replace to.
 * @param {object} params.character Character to replace with.
 * @returns {undefined} Returns undefined.
 */
export default function replaceCharacterPlaceholder({ characterPlaceholder, character }) {
  const characterModel = character.model.scene;
  const { min, max } = getBoundingBox(characterModel);
  const modelHeight = Math.abs(min.y - max.x);
  const scale = characterPlaceholder.geometry.height / modelHeight;

  for (const [key, value] of Object.entries(characterPlaceholder.transform)) {
    Object.assign(characterModel[key], value);
  }

  characterModel.scale.set(scale, scale, scale);
}
