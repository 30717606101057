import * as Tone from 'tone';

export default class Mic {
  /**
   * Request mic access and pipe mic input to a gain node then to
   * a noise gate. The output stream can be connected to the webrtc audio input.
   * Mic enabled state can be controlled independently by the user or the system.
   *
   * @param {Object} params Contain mic parameters.
   * @param {Object} params.context Window audio context.
   */
  constructor(params) {
    Object.assign(this, params);

    const { context, noiseGateThreshold, noiseGateSmoothing } = this;

    Tone.setContext(context);

    const gainNode = context.createGain();
    gainNode.channelCount = 1;
    gainNode.channelCountMode = 'explicit';

    const meter = new Tone.Meter();
    meter.channelCount = 1;
    meter.channelCountMode = 'explicit';

    const noiseGate = new Tone.Gate(noiseGateThreshold, noiseGateSmoothing);
    noiseGate.channelCount = 1;
    noiseGate.channelCountMode = 'explicit';

    const outputSource = context.createMediaStreamDestination();
    outputSource.channelCount = 1;
    outputSource.channelCountMode = 'explicit';

    Object.assign(this, {
      gainNode,
      meter,
      noiseGate,
      outputSource,
    });

    Tone.connect(gainNode, meter);
    Tone.connect(gainNode, noiseGate);
    noiseGate.connect(outputSource);
  }

  #systemEnabled = true;

  #userEnabled = true;

  inputSource = null;

  gainNode = null;

  noiseGate = null;

  outputSource = null;

  #noiseGateThreshold = -50;

  noiseGateSmoothing = 1;

  // Mic state controlled by the system.
  // E.g. not interruptable  character talking.
  get systemEnabled() {
    return this.#systemEnabled;
  }

  set systemEnabled(value) {
    this.#systemEnabled = value;

    if (this.inputSource) {
      this.inputSource.mediaStream.getTracks()[0].enabled = value;
    }
  }

  // Mic state controlled by the user.
  // E.g. mute mic button.
  get userEnabled() {
    return this.#userEnabled;
  }

  set userEnabled(value) {
    this.#userEnabled = value;

    this.outputStream.getTracks()[0].enabled = value;
  }

  get outputStream() {
    return this.outputSource.stream;
  }

  // Always use this method to change gain. Setting the gain directly will cause audio issues.
  setGain(value, timeConstant) {
    const { gainNode, context } = this;

    gainNode.gain.setTargetAtTime(value, context.currentTime, timeConstant);
  }

  get beforeGatedLevel() {
    return this.meter.getValue();
  }

  get noiseGateThreshold() {
    return this.#noiseGateThreshold;
  }

  set noiseGateThreshold(value) {
    this.#noiseGateThreshold = value;
    this.noiseGate.set({ threshold: value });
  }

  async useDevice(deviceId) {
    const constraints = {
      video: false,
      audio: {
        deviceId,
        channelCount: 1,
      },
    };

    this.stop();

    const { context } = this;

    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    const inputSource = context.createMediaStreamSource(stream);
    inputSource.channelCount = 1;
    inputSource.channelCountMode = 'explicit';
    inputSource.connect(this.gainNode);
    this.inputSource = inputSource;
  }

  disableNoiseGate() {
    this.noiseGateThreshold = -100;
  }

  stop() {
    const { inputSource } = this;

    if (inputSource) {
      inputSource.mediaStream.getTracks()[0].stop();
    }
  }
}
