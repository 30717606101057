/* eslint-disable no-param-reassign */
import * as THREE from 'three';

import getBoundingBox from '../rendering/lib/helpers/getBoundingBox';
import getLightCameraViewBox from './getLightCameraViewBox';
import getBoundingBoxCorners from './computeBoundingBoxCorners';

const yAxis = new THREE.Vector3(0, 1, 0);

/**
 * compute a tight view box for a directional light shadow's camera
 * @param {THREE.DirectionalLight} dirLight required && static
 * @param {THREE.Scene} scene required && static
 */
function computeDirectionalLightViewBox(dirLight, scene) {
  const boundingBox = getBoundingBox(scene);
  const boundingBoxCorners = getBoundingBoxCorners(boundingBox);

  // Comment out for helper function to visual positions
  // function addSphereToScene(pos, color) {
  //   const geometry = new THREE.SphereGeometry( 0.04, 32, 16 );
  //   geometry.translate(pos.x, pos.y, pos.z)
  //   const material = new THREE.MeshBasicMaterial( { color } );
  //   const sphere = new THREE.Mesh( geometry, material );
  //   return  sphere;
  // }

  const lightPosition = dirLight.position.clone();

  // apply rotation to target

  const target = dirLight.target.position.clone();
  target.applyQuaternion(dirLight.quaternion.clone());

  // directional light direction from position to target with same rotation applied
  const lightDirection = new THREE.Vector3();
  lightDirection.addVectors(lightPosition, target);

  // create yAxis up plane through center of light in light direction
  const dirLightVector = new THREE.Vector3().subVectors(lightDirection, lightPosition);
  // plane normal
  const yNormal = new THREE.Vector3().crossVectors(dirLightVector, yAxis).normalize();
  // plane offset
  const yDot = yNormal.dot(lightPosition);
  const yPlane = new THREE.Plane(yNormal, -yDot);

  // create perpendicular plane to yPlane and direction of target
  // plane normal
  const xNormal = new THREE.Vector3().crossVectors(yPlane.normal, target).normalize();
  // // plane offset
  const xDot = xNormal.dot(lightPosition);
  const xPlane = new THREE.Plane(xNormal, -xDot);

  // center of directional light may not pass through bounding box
  const shadowDimensions = getLightCameraViewBox(xPlane, yPlane, boundingBoxCorners);

  dirLight.shadow.camera.left = shadowDimensions.left;
  dirLight.shadow.camera.right = shadowDimensions.right;
  dirLight.shadow.camera.top = shadowDimensions.top;
  dirLight.shadow.camera.bottom = shadowDimensions.bottom;
  dirLight.shadow.camera.updateProjectionMatrix();
}

export default computeDirectionalLightViewBox;
