import Animations from './Animations';

export default class sceneObjects {
  /**
   * Creates a model object with animation support.
   * @param {Object} params sceneObjects constructor params.
   * @param {String} params.id Scene object id.
   * @param {Object} params.model Three.js model object.
   * @param {Function} params.onAnimationFinished Callback called with animation id when finished.
   */
  constructor(params) {
    Object.assign(this, params);

    const { model } = params;

    this.animations = new Animations({
      scene: model.scene,
      clips: model.animations,
      onAnimationFinished: () => this.emitAnimationFinished(),
    });
  }

  animations = null;

  updateAnimation(frameTime) {
    this.animations.update(frameTime);
  }

  emitAnimationFinished() {
    const { id } = this;

    this.onAnimationFinished({ id });
  }

  dispose() {
    this.animations.dispose();
  }
}
