/**
 * Format Parameters
 * @param {expression} condition
 * @return {Promise} when condition passed
 */
export default function waitUntil(condition) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!condition()) {
        return;
      }
      clearInterval(interval);
      resolve();
    }, 100);
  });
}
