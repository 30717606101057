// callback and listener names
export default Object.freeze({
  ERROR: 'error', // errors get mapped to sessionDisconnected
  SESSION_DISCONNECTED: 'sessionDisconnected',
  WARNING: 'warning',
  INFO: 'info',
  ASR_MESSAGE: 'asrMessage',
  AI_MESSAGE: 'aiMessage',
  COMPONENT_CONNECTED: 'componentConnected',
  SESSION_CONNECTED: 'sessionConnected',
  SEND_CPIM_MESSAGE: 'sendCPIMMessage',
  TTS_START: 'ttsStart',
  TTS_END: 'ttsEnd',
  MEETING_USERS_CHANGED: 'meetingUsersChanged',
  STATE_CHANGED: 'stateChanged',
  VA_MESSAGE: 'vaMessage',
  SA_MESSAGE: 'saMessage',
  MESHES_INTERSECTED: 'meshesIntersected',
});
