import Audio from '../audio/Audio';
import ModuleNames from '../utils/ModuleNames';
import MethodNames from '../utils/MethodNames';
import LoggingLevel from '../LoggingLevel';
import ErrorCode from '../ErrorCode';

class Network {
  constructor(params) {
    Object.assign(this, params);

    this.pc.onconnectionstatechange = () => this.pcConnectionHandler();
  }

  logger = null;

  pc = new RTCPeerConnection();

  ctrldc = null;

  animdc = null;

  customerChannel = null;

  statdc = null;

  ctrlId = 0;

  getHeaderId = 0;

  pcConnectionHandler() {
    const { pc, logger } = this;

    switch (pc.connectionState) {
      case 'new':
      case 'checking':
        logger.debug('Network | CPI peer connection state changed: connecting');
        break;
      case 'connected':
        logger.debug('Network | CPI peer connection state changed: connected');
        break;
      case 'completed':
        logger.debug('Network | CPI peer connection state changed: completed');
        break;
      case 'disconnected':
        logger.error('Network | CPI peer connection state changed: disconnected');
        this.statusCallback(LoggingLevel.WARNING, ErrorCode.PC_DISCONNECTED);
        break;
      case 'closed':
        logger.error('Network | CPI peer connection state changed: closed');
        this.statusCallback(LoggingLevel.ERROR, ErrorCode.PC_CLOSED);
        break;
      case 'failed':
        logger.error('Network | CPI peer connection state changed: failed');
        this.statusCallback(LoggingLevel.ERROR, ErrorCode.PC_FAILED);
        break;
      default:
        logger.debug(`Network | CPI peer connection state changed: ${pc.connectionState}`);
        break;
    }
  }

  sendCommand(message) {
    const { logger } = this;

    this.customerChannel.send(message);

    logger.debug(`Network | CPI customer channel message sent. ${message}`);
  }

  sendCpiCommand({ TargetCPIM, method, params }) {
    const { logger } = this;

    this.ctrlId += 1;

    const message = JSON.stringify({
      TargetCPIM,
      Payload: {
        id: this.ctrlId,
        jsonrpc: '2.0',
        method,
        params,
      },
    });

    this.customerChannel.send(message);

    logger.debug(`Network | CPI customer channel message sent. ${message}`);
  }

  getControlHeader() {
    const { ctrldc, getHeaderId } = this;

    ctrldc.send(
      JSON.stringify({
        TargetCPIM: ModuleNames.ANIMATION_CONTROLLER,
        Payload: {
          jsonrpc: '2.0',
          method: MethodNames.GET_HEADER,
          params: [],
          id: getHeaderId,
        },
      }),
    );
  }

  async init() {
    const { pc } = this;

    const dcOptions = { ordered: false, maxRetransmits: 0 };

    this.animdc = pc.createDataChannel('anim', dcOptions);

    this.animdc.binaryType = 'arraybuffer';

    this.animdc.onmessage = (ev) => {
      this.callback({ rxPlayer: ev.data });
    };

    this.animdc.onopen = () => {
      const { logger } = this;

      logger.debug('Network | CPI animation data channel state changed: open');
    };

    this.animdc.onclose = () => {
      const { logger } = this;

      logger.debug('Network | CPI animation data channel state changed: close');
    };

    // customer Channel is Customer Channel
    this.customerChannel = pc.createDataChannel('customer');

    this.customerChannel.onopen = () => {
      const { logger } = this;

      logger.debug('Network | CPI customer data channel state changed: open');
    };

    this.customerChannel.onclose = () => {
      const { logger } = this;

      logger.debug('Network | CPI customer data channel state changed: close');
    };

    this.customerChannel.onmessage = (ev) => {
      this.callback({ rapportScene: { CPIMMessage: ev } });
    };

    // control data channel
    this.ctrldc = pc.createDataChannel('ctrl');
    this.ctrldc.binaryType = 'arraybuffer';
    this.ctrlId = 0;
    const SET_SESSION = 1; // second command sent is SET_AI_SESSION_ID

    this.ctrldc.onmessage = (ev) => {
      const { logger, getHeaderId } = this;

      logger.debug(`Network | CPI control data channel message received. ${ev.data}`);
      const r = JSON.parse(ev.data);
      if (r.Payload.id === getHeaderId) {
        this.callback({ rapportScene: { cpiTimeout: r.Payload.result['max-session-duration'] } });

        const cpiVersion = JSON.stringify(r.Payload.result['cpi-version']);
        const maxSessionDuration = r.Payload.result['max-session-duration'];

        logger.debug(`cpiVersion: ${cpiVersion}, maxSessionDuration: ${maxSessionDuration}`);

        let { 'sgcom-header': sgcomAnimationNodes } = r.Payload.result;
        const {
          unreal_ip: upxsSignalingIp,
          unreal_port: upxsSignalingPort,
          unreal_token: upxsSignalingJwt,
          animation_stream_id: animationStreamId,
        } = r.Payload.result;
        let upxsParams;

        /* Render mode is Unreal Pixel Streaming. */
        if (upxsSignalingIp) {
          sgcomAnimationNodes = undefined;

          upxsParams = {
            signalingParams: {
              url: `wss://${upxsSignalingIp}:${upxsSignalingPort}`,
              jwt: upxsSignalingJwt,
            },
          };
        }

        this.callback({ sgcomAnimationNodes, upxsParams, animationStreamId });
      }
      if (r.Payload.id === SET_SESSION) {
        if (r.Payload.error) {
          this.statusCallback(LoggingLevel.WARNING, ErrorCode.CPI_SET_AI_SESSION_FAILURE);
        }
      }
      if (r.SourceCPIM === 'RoomCtrl') {
        this.callback({
          meetingRoomEvent: r.Payload.params.event,
        });
      }
    };

    this.ctrldc.onopen = async () => {
      const { logger } = this;

      logger.debug('Network | CPI control data channel state changed: open');

      this.getControlHeader();

      if (this.aiSessionId) {
        this.ctrlId += 1;
        // sending set client for all AI sessions, do we need to strip dashes for Lex?
        this.ctrldc.send(
          JSON.stringify({
            TargetCPIM: 'AI*',
            Payload: {
              jsonrpc: '2.0',
              method: MethodNames.SET_AI_SESSION_ID,
              params: [this.aiSessionId],
              id: this.ctrlId,
            },
          }),
        );
      }

      // Set FPS to 30
      this.ctrlId += 1;
      this.ctrldc.send(
        JSON.stringify({
          TargetCPIM: ModuleNames.ANIMATION_CONTROLLER,
          Payload: {
            jsonrpc: '2.0',
            method: MethodNames.SET_FPS_DIVISOR,
            params: [2],
            id: this.ctrlId,
          },
        }),
      );
    };

    this.ctrldc.onclose = () => {
      const { logger } = this;

      logger.debug('Network | CPI control data channel state changed: close');
    };

    // status data channel
    this.statdc = pc.createDataChannel('status');

    this.statdc.binaryType = 'arraybuffer';

    this.statdc.onopen = () => {
      const { logger } = this;

      logger.debug('Network | CPI stat data channel state changed: open');
    };

    this.animdc.onclose = () => {
      const { logger } = this;

      logger.debug('Network | CPI stat data channel state changed: close');
    };

    this.statdc.onmessage = (ev) => {
      const { logger } = this;

      const msg = JSON.parse(ev.data);

      switch (msg.type) {
        case 'log': {
          const message = JSON.stringify(msg);

          if (msg.severity === 'error') {
            logger.error(`Network | CPI stat data channel SGCOM error received. ${message}`);

            switch (msg.error_code) {
              case 'lex:AccessDenied': // CPI room creation now fails before it can send this, unable to test
                this.statusCallback(LoggingLevel.ERROR, ErrorCode.FAILURE_IAM_ROLE);
                break;
              case 'pandorabots:Unauthorized': // Tested against
                this.statusCallback(LoggingLevel.ERROR, ErrorCode.FAILURE_BOT_KEY);
                break;
              case 'tts:InvalidSsmlException':
                this.statusCallback(LoggingLevel.WARNING, ErrorCode.FAILURE_MALFORMED_SSML);
                break;
              case 'cpi:MaxDuration':
                this.statusCallback(LoggingLevel.ERROR, ErrorCode.CPI_MAX_SESSION_TIMEOUT);
                break;
              case 'cpi:RoomDeleted':
                this.statusCallback(LoggingLevel.ERROR, ErrorCode.CPI_ROOM_DELETED);
                break;
              case 'aws:AccessDeniedException':
              default:
                this.statusCallback(LoggingLevel.WARNING, ErrorCode.CPI_UNKNOWN_ERROR);
                break;
            }
          } else {
            logger.debug(
              `Network | CPI stat data channel SGCOM debug message received. ${message}`,
            );
          }
          break;
        }
        case 'sgcom-debug': {
          // Omit expression status from the log.
          if (msg.text.includes('sgcom status expression')) {
            break;
          }

          logger.debug(`Network | CPI stat data channel SGCOM debug message received. ${msg.text}`);

          break;
        }
        case 'mood':
          this.callback({ rapportScene: { emotion: msg.mood } });
          break;
        case 'stats':
          break;
        case 'tts-start': {
          this.callback({
            tts: 'start',
            ...msg,
          });
          break;
        }
        case 'tts-end': {
          this.callback({
            tts: 'end',
            ...msg,
          });
          break;
        }
        case 'expression': {
          break;
        }
        case 'auto_mode': {
          break;
        }
        case 'mode': {
          break;
        }
        case 'role': {
          break;
        }
        default:
          logger.debug(`Network | CPI stat data channel not implemented message received. ${msg}`);
      }
    };

    if (!Audio.micRequired) {
      Audio.createSilentTrack();
    }

    pc.addTrack(Audio.localStream.getAudioTracks()[0], Audio.localStream); // mic or silent track

    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    return pc.localDescription.sdp;
  }

  async connect(answerSdp) {
    const sessionDescription = new RTCSessionDescription({ type: 'answer', sdp: answerSdp });

    await this.pc.setRemoteDescription(sessionDescription);
  }

  async switchAudioTrack() {
    const { logger } = this;
    try {
      await Audio.getMic();

      const audioTracks = Audio.localStream.getAudioTracks();

      if (audioTracks.length === 0) {
        throw new Error('Network | Stream contains no audio tracks.');
      }

      if (audioTracks.length !== 1) {
        throw new Error('Network | Unexpected audio device.');
      }

      this.pc.getSenders()[0].replaceTrack(audioTracks[0]);
    } catch (error) {
      logger.error(new Error('Network | Failed to switch audio track.', { cause: error }));

      throw error;
    }
  }

  disconnect() {
    if (this.ctrldc) {
      this.ctrldc.onopen = null;
      this.ctrldc.onclose = null;
      this.ctrldc.onmessage = null;
      this.ctrldc.close();
      this.ctrldc = null;
    }
    if (this.animdc) {
      this.animdc.onopen = null;
      this.animdc.onclose = null;
      this.animdc.onmessage = null;
      this.animdc.close();
      this.animdc = null;
    }

    if (this.customerChannel) {
      this.customerChannel.onopen = null;
      this.customerChannel.onclose = null;
      this.customerChannel.onmessage = null;
      this.customerChannel.close();
      this.customerChannel = null;
    }

    if (this.statdc) {
      this.statdc.onopen = null;
      this.statdc.onclose = null;
      this.statdc.onmessage = null;

      this.statdc.close();
      this.statdc = null;
    }

    if (this.pc) {
      this.pc.onconnectionstatechange = null;
      this.pc.close();
      this.pc = null;
    }
  }
}

export default Network;
