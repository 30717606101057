/**
  * Validates SG_Com animation nodes against the loaded model
  * @param {*} animationNodes Array of AnimationNode objects
  * @param {*} skeleton an instance of Skeleton
  * @param {Object} logger Global logger.
  * @return {*} true if validated
  */
export default function validateAnimationNodes(animationNodes, skeleton, logger) {
  animationNodes.forEach((node) => {
    if (node.type === 'JOINT') {
      if (!(node.name in skeleton.jointsOrigin)) {
        logger.warn(`Skeleton | jointsOrigin["${node.name}"] not found`);
      }
    } else if (node.type === 'BLENDSHAPE') {
      node.channelNames.forEach((channelName) => {
        if (skeleton.blendshapes[node.name] === undefined) {
          logger.warn(`Skeleton | Blendshape "${node.name}" not found`);
        } else {
          const morphTargetName = `${node.name}.${channelName}`;
          skeleton.blendshapes[node.name].forEach((mesh) => {
            if (!(morphTargetName in mesh.morphTargetDictionary)) {
              logger.warn(`Skeleton | Morph target "${morphTargetName}" not found in mesh "${mesh.name}".`);
            }
          });
        }
      });
    } else {
      logger.warn(`Skeleton | Unknown node type: ${node.type}`);
    }
  });
}
