export default class RtcConnecton {
  /**
   * Create RTC connection for Unreal Pixel Streaming.
   * RTC connection is for one direction video and audio transport from Unreal pixel streamer.
   * Unreal pixel streamer initiates the connection.
   * Callback onCandidate is called with ICE candidate object.
   * Callback onStreams is called with "streams" object containing "audioStream" and "videoStream".
   * Callback onAnswer is called with SDP answer object. Called after "addOffer".
   * Callback onError is called with "error" object.
   * Error has "type" prop with value of "rtc".
   * @param {Object} params Contain callbacks.
   * @param {Function} params.onCandidate Callback called with ICE candidate object.
   * @param {Function} params.onStreams Callback called with streams object.
   * @param {Function} params.onAnswer Callback called with SDP answer object.
   * @param {Function} params.onError Callback called with error object.
   */
  constructor(params) {
    Object.assign(this, params);

    this.connect();
    this.addEventListeners();
  }

  connection = null;

  audioStream = null;

  videoStream = null;

  connect() {
    this.connection = new RTCPeerConnection();
  }

  addEventListeners() {
    const { connection } = this;

    connection.addEventListener('icecandidate', this);
    connection.addEventListener('track', this);
    connection.addEventListener('connectionstatechange', this);
  }

  handleEvent(e) {
    const { connection } = this;

    switch (e.type) {
      case 'icecandidate': {
        this.emitCandidates(e);
        break;
      }

      case 'track': {
        this.addStream(e);
        this.emitStreams();
        break;
      }

      case 'connectionstatechange': {
        if (connection.connectionState === 'failed') {
          this.emitError(new Error('upxs RTC connectionstatechange: failed'));
        }
        break;
      }

      default:
        break;
    }
  }

  emitCandidates({ candidate }) {
    if (candidate) {
      this.onCandidate(candidate);
    }
  }

  addStream(e) {
    const stream = e.streams[0];

    if (e.track.kind === 'audio') {
      this.audioStream = stream;
    } else if (e.track.kind === 'video') {
      this.videoStream = stream;
    }
  }

  emitStreams() {
    const { audioStream, videoStream, onStreams } = this;

    // Wait for audio and video streams to both be available then emit them.
    if (audioStream && videoStream) {
      onStreams({ audioStream, videoStream });
    }
  }

  async emitAnswer() {
    try {
      const { connection } = this;

      const answer = await connection.createAnswer();
      await connection.setLocalDescription(answer);

      this.onAnswer(answer);
    } catch (error) {
      this.emitError(error);
    }
  }

  emitError(error) {
    error.type = 'rtc';
    this.onError(error);
  }

  async addOffer(offer) {
    try {
      const { connection } = this;

      await connection.setRemoteDescription(new RTCSessionDescription(offer));

      connection.addTransceiver('video', { direction: 'recvonly' });
      connection.addTransceiver('audio', { direction: 'recvonly' });

      this.emitAnswer();
    } catch (error) {
      this.emitError(error);
    }
  }

  async addCandidate(candidate) {
    try {
      await this.connection.addIceCandidate(new RTCIceCandidate(candidate));
    } catch (error) {
      this.emitError(error);
    }
  }

  disconnect() {
    const { connection } = this;

    if (connection) {
      this.removeEventListeners();
      connection.close();
    }
  }

  removeEventListeners() {
    const { connection } = this;

    connection.removeEventListener('icecandidate', this);
    connection.removeEventListener('track', this);
    connection.removeEventListener('connectionstatechange', this);
  }
}
