/**
 * rotate an object around a point
 * @param {THREE.Object} obj to rotate
 * @param {THREE.Vector3} point which to rotate around
 * @param {THREE.Vector3} axis which to rotate around
 * @param {Float} theta angle which to rotate
 */
export default function rotateAboutPoint(obj, point, axis, theta) {
  obj.position.sub(point); // remove the offset
  obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
  obj.position.add(point); // re-add the offset
  obj.rotateOnAxis(axis, theta); // rotate the OBJECT
}
