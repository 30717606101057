import Module from './Module';

export default class SentimentAnalysisModule extends Module {
  analyseSentiment(params) {
    const command = {
      method: 'analyse-sentiment',
      params,
    };

    this.sendCommand('Sentiment_*', command);
  }
}
