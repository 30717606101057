import sessionRequest from './lib/sessionRequest';

export default class SessionMeeting {
  /**
   * Create session manager for meeting room based sessions.
   * Start method creates room or join to a room if roomId is given.
   * @param {object} params Contain session manager params.
   * @param {string} params.baseUrl RRMS api base URL.
   * @param {string} params.projectId Accounts project id.
   * @param {string} params.projectToken Accounts project token.
   * @param {string} params.roomId Optional roomId to join to.
   * @param {string} params.characterId Self character id to use.
   * @param {string} params.displayName Name to display on renderer.
   * @param {string} params.offerSdp WebRTC session description SDP offer.
   */
  constructor(params) {
    Object.assign(this, params);
  }

  roomDetails;

  async start() {
    const { roomId } = this;
    let roomDetails;

    if (roomId) {
      roomDetails = await this.joinRoom();
    } else {
      // Create a room and join to it.
      roomDetails = await this.createRoom();
      this.roomId = roomDetails.roomId;
    }

    this.roomDetails = roomDetails;

    return roomDetails;
  }

  async request({ url, method, data }) {
    const { baseUrl, projectToken } = this;
    const path = `meeting-projects/${this.projectId}/${url}`;

    return sessionRequest({
      baseUrl,
      path,
      method,
      authToken: `Bearer ${projectToken}`,
      data,
    });
  }

  async createRoom() {
    const {
      characterId,
      displayName,
      offerSdp,
    } = this;

    return this.request({
      url: 'rooms',
      method: 'POST',
      data: {
        characterId,
        displayName,
        offerSdp,
      },
    });
  }

  async joinRoom() {
    const {
      roomId,
      characterId,
      displayName,
      offerSdp,
    } = this;

    return this.request({
      url: `rooms/${roomId}/join`,
      method: 'POST',
      data: {
        characterId,
        displayName,
        offerSdp,
      },
    });
  }

  async getUsers() {
    const { roomId } = this;

    const response = await this.request({
      url: `rooms/${roomId}/users`,
      method: 'GET',
    });

    return response.items;
  }

  async getAvailableCharacters() {
    const response = await this.request({
      url: 'available-characters',
      method: 'GET',
    });

    return response.items;
  }
}
