import Cache from './Cache';

export default async function cacheFile({ id, hash, url }) {
  // ObjectURL was passed to preview mode previewModelUrl param.
  if (url.startsWith('blob:')) {
    return { characterModelCacheUrl: url, cached: false };
  }

  let characterModelCacheUrl;

  characterModelCacheUrl = await Cache.getCIDHash(id, hash);

  if (characterModelCacheUrl) {
    return { characterModelCacheUrl, cached: true };
  }

  characterModelCacheUrl = await Cache.putCIDHashUrl(id, hash, url);

  return { characterModelCacheUrl, cached: false };
}
