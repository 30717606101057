import LibopusUrl from '../../../../../../static/wasm/libopus-1.0.0.wasm?url';
import LibopusModule from '../../../../../wasm/libopus-1.0.0.mjs';

export default class LibopusDecoder {
  libopus = null;

  version = null;

  decoder = null;

  async init() {
    const libopus = await LibopusModule({ locateFile: () => LibopusUrl });

    this.libopus = libopus;
    this.version = libopus.opus_get_version_string();
    this.decoder = libopus.opus_decoder_create();
  }

  decodeAudioPacket({ packet, playTime, lastPlayTime }) {
    const audioPackets = [];

    for (let i = lastPlayTime + 20; i <= playTime; i += 20) {
      let encodedAudioPacket = packet;
      // final frame does not require FEC
      let forwardErrorCorrection = 0;
      // use Forward Error Correction present in frame ts to create lost
      // frame at ts-20
      // for any other lost frames, there is no FEC, so libopus will use
      // Packet Loss Concealment
      if (i < playTime) {
        forwardErrorCorrection = 1;
        if (i < playTime - 20) {
          encodedAudioPacket = null;
        }
      }

      const audioFrame = this.libopus.opus_decode(
        this.decoder,
        encodedAudioPacket,
        forwardErrorCorrection,
      );

      if (audioFrame.length !== 960) {
        throw new Error('audioFrame bad sample length');
      }

      if (encodedAudioPacket) {
        audioPackets.push({ audioFrame, audioFrameTimeStamp: i });
      }
    }

    return audioPackets;
  }

  dispose() {
    // eslint-disable-next-line no-underscore-dangle
    this.libopus?._free(this.libopusDecoder);
  }
}
