import ErrorCode from '../ErrorCode';
import MethodNames from '../utils/MethodNames';
import Module from './Module';
import getBoolean from '../utils/boolean';

export default class TtsModule extends Module {
  /**
   * send text get TTS to say the text in the language
   * @param {String} text to be spoken
   * @param {String} lang language to be spoken in
   */
  sendText(text, lang) {
    if (typeof text !== 'string') {
      this.throwError(ErrorCode.NOT_A_STRING);
    }
    const command = {
      method: MethodNames.SEND_TEXT,
      params: { text, lang },
    };
    this.sendCommand('TTS*', command);
  }

  /**
   * Set TTS languange, voice, TTS provider, and neural setting
   * @param {String} lang languge
   * @param {String} voice name of voice
   * @param {String} tts provider, default polly
   * @param {Boolean} neural default true
   */
  setLanguage(lang, voice, tts = 'polly', neural = true) {
    if (typeof lang !== 'string' || typeof voice !== 'string' || typeof tts !== 'string') {
      this.throwError(ErrorCode.NOT_A_STRING);
    }
    const neuralSetting = getBoolean(neural);

    // need to send 2 commands to change lang and voice
    const langCommand = {
      method: MethodNames.SET_TTS_LANG,
      params: {
        lang,
      },
    };
    this.sendCommand('TTS*', langCommand);

    const voiceCommand = {
      method: MethodNames.SET_TTS,
      params: {
        tts,
        voice,
        neural: neuralSetting,
      },
    };
    this.sendCommand('TTS*', voiceCommand);
  }
}
