/* eslint-disable no-console */

export default class Logger {
  #logToken = null;

  #logUrl = null;

  #debug = false;

  #logs = [];

  #running = false;

  #lastFetch = 0;

  reset() {
    this.#logToken = null;
    this.#running = false;
    this.#logs = [];
  }

  setLogToken(logToken) {
    this.#logToken = logToken;

    if (!this.#logToken || !this.#logUrl) {
      return;
    }

    for (const log of this.#logs) {
      this.log(log.level, log.message);
    }
    this.#logs = [];
  }

  setBaseUrl(baseUrl) {
    this.#logUrl = new URL('/api/v1/logs', baseUrl);

    this.#sendLog();
  }

  setDebug(debug) {
    this.#debug = debug;

    this.#sendLog();
  }

  log(level, message) {
    if (__LOG_TO_CONSOLE__) {
      switch (level) {
        case 'error':
        case 'warn':
        case 'info':
          console[level](level, message);
          break;
        default:
          console.log(level, message);
      }

      // TODO: remove after chrome prints error cause.
      if (message?.cause) {
        console.error(message.cause);
      }
    }

    let logLevel = level;
    if (!['info', 'warn', 'error'].includes(logLevel)) {
      logLevel = 'debug';
    }

    if (!this.#debug && logLevel === 'debug') {
      return;
    }

    this.#sendLog({ level, message, timestamp: new Date().toISOString() });
  }

  #sendLog(logEntry) {
    if (!this.#logToken || !this.#logUrl || this.#running) {
      if (logEntry) {
        this.#logs.push(logEntry);
      }
      return;
    }

    let logLines = '';
    const addLogLine = (l) => {
      if (!this.#debug && l.level === 'debug') {
        return;
      }

      try {
        logLines += this.#stringify(l);
      } catch (e) {
        console.error('invalid log', l);
      }
    };

    for (const log of this.#logs) {
      addLogLine(log);
    }
    this.#logs = [];

    if (logEntry) {
      addLogLine(logEntry);
    }

    if (!logLines) {
      return;
    }

    this.#running = true;
    (async () => {
      for (let i = 0; i < 3; i += 1) {
        const wait = this.#lastFetch + 1000 - new Date().getTime();
        if (wait > 0) {
          await new Promise((resolve) => {
            setTimeout(resolve, wait);
          });
        }

        this.#lastFetch = new Date().getTime();
        try {
          await fetch(this.#logUrl, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.#logToken}`,
              'Content-Type': 'text/plain',
            },
            body: logLines,
          });
          break;
        } catch (e) {
          // retry
        }
      }
      this.#running = false;
      if (this.#logs.length > 0) {
        this.#sendLog();
      }
    })();
  }

  #stringify(logEntry) {
    // eslint-disable-next-line prefer-destructuring
    let message = logEntry.message;
    if (typeof message === 'string') {
      // do nothing
    } else if (message instanceof Error) {
      message = this.#collectStack(message);
    } else {
      message = JSON.stringify(message, (k, v) => {
        if (v instanceof Error) {
          return this.#collectStack(message);
        }
        return v;
      });
    }

    // eslint-disable-next-line prefer-template
    return JSON.stringify({ ...logEntry, message }) + '\n';
  }

  #collectStack(err, maxDepth = 10) {
    // eslint-disable-next-line prefer-destructuring
    let stack = err.stack;
    if (stack.startsWith('Error: ') && err.constructor?.name && err.constructor.name !== 'Error') {
      stack = err.constructor.name + stack.substring(5);
    }

    if (err.cause) {
      stack += '\n[cause] ';
      if (maxDepth <= 1) {
        stack += 'MAX DEPTH REACHED';
      } else if (err.cause instanceof Error) {
        stack += this.#collectStack(err.cause, maxDepth - 1);
      } else {
        stack += err.cause;
      }
    }
    return stack;
  }

  debug(message) {
    this.log('debug', message);
  }

  info(message) {
    this.log('info', message);
  }

  warn(message) {
    this.log('warn', message);
  }

  error(error) {
    this.log('error', error);
  }
}
