/**
 * Returns a number whose value is limited to the given range.
 * @param {number} number The number to clamp.
 * @param {number} min The lower boundary of the output range.
 * @param {number} max The upper boundary of the output range.
 * @returns {number} A number in the range of min and max values.
 */
export default function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}
