import imageSignalFailure from '../../static/images/svgs/signal-failure.svg?raw';
import imageSignalMedium from '../../static/images/svgs/signal-medium.svg?raw';
import imageSignalGood from '../../static/images/svgs/signal-good.svg?raw';

const whenToDisplayAllowedValues = ['never', 'always', 'auto'];

export default class SignalIndicator {
  /**
   * Control visibility, click event, and icon image of the signal indicator.
   * @param {object} params Contain SignalIndicator parameters, and callback.
   * @param {object} params.imageElement Signal indicator img element.
   * @param {function} params.onClick Callback called when clicked on the icon.
   */
  constructor(params) {
    Object.assign(this, params);

    this.setupEventListeners();

    // Set initial image.
    this.update();
  }

  #signalQualityImage;

  #whenToDisplay = whenToDisplayAllowedValues[0];

  setupEventListeners() {
    const { imageElement } = this;

    imageElement.addEventListener('click', () => {
      this.emitClick();
    });
  }

  /**
   * Updates whether the network signal quality indicator is visible or not.
   *
   * @param {String} whenToDisplay Possible values can be: "never", "always", "auto".
   */
  updateWhenToDisplay(whenToDisplay) {
    if (!whenToDisplay || !whenToDisplayAllowedValues.includes(whenToDisplay)) {
      [this.#whenToDisplay] = whenToDisplayAllowedValues;
      return;
    }

    this.#whenToDisplay = whenToDisplay;
    this.#updateVisibility();
  }

  /**
   * Updates the icon and the visibility of the signalIndicator
   * based on the jitter value.
   *
   * @param {Number} jitter Jitter value in milliseconds.
   */
  update(jitter) {
    const { imageElement } = this;

    if (jitter === 1600) {
      this.#signalQualityImage = imageSignalFailure;
    } else if (jitter >= 800) {
      this.#signalQualityImage = imageSignalMedium;
    } else {
      this.#signalQualityImage = imageSignalGood;
    }

    // Set icon image.
    imageElement.innerHTML = this.#signalQualityImage;

    this.#updateVisibility();
  }

  #updateVisibility() {
    if (
      this.#whenToDisplay === 'never' ||
      (this.#whenToDisplay === 'auto' && this.#signalQualityImage === imageSignalGood)
    ) {
      this.hide();
      return;
    }
    this.show();
  }

  hide() {
    this.imageElement.style.display = 'none';
  }

  show() {
    this.imageElement.style.display = 'block';
  }

  emitClick() {
    this.onClick();
  }
}
