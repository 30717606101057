/**
 * distances from xPlane and yPlane to each of the boundingBoxCorners give the
 * light shadow camera's viewBox  / frustum { left, right, top, bottom }
 * @param {THREE.Plane} xPlane perpendicular plane through light and point of interest
 * @param {THREE.Plane} yPlane vertical plane through light and point of interest
 * @param {Array} boundingBoxCorners Array of THREE.Vector3 for each of the 8 corners
 * @return {Object} left, right, top, bottom
 */
export default function getLightCameraViewBox(xPlane, yPlane, boundingBoxCorners) {
  let left;
  let right;
  let top;
  let bottom;
  boundingBoxCorners.forEach((element) => {
    const yDist = yPlane.distanceToPoint(element);
    const xDist = xPlane.distanceToPoint(element);
    if (right === undefined) right = yDist;
    if (left === undefined) left = yDist;
    if (top === undefined) top = xDist;
    if (bottom === undefined) bottom = xDist;

    left = Math.min(left, yDist);
    right = Math.max(right, yDist);
    bottom = Math.min(bottom, xDist);
    top = Math.max(top, xDist);
  });
  return {
    left, right, top, bottom,
  };
}
