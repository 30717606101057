export default Object.freeze({
  GET_HEADER: 'get-header',
  SET_FPS_DIVISOR: 'set-fpsdivisor',
  SET_DEBUG: 'set-debug',
  SET_AI_LANG: 'set-ai-lang',
  SET_ASR: 'set-asr',
  IDENTIFY_ASR_LANG: 'identify-lang',
  SET_SGCOM_FREQ: 'set-sgcom-freq',
  SET_SGCOM_MOOD: 'set-sgcom-mood',
  SET_SGCOM_SCALE: 'set-sgcom-scale',
  SET_SGCOM_SPEED: 'set-sgcom-speed',
  SET_TRANSLATOR: 'set-translator',
  SET_TTS: 'set-tts',
  SET_TTS_LANG: 'set-tts-lang',
  SEND_TEXT: 'send-text',
  SET_AI_SESSION_ID: 'set-ai-session-id',
  GET_AI_SESSION_ID: 'get-ai-session-id',
  SET_AI_ID: 'set-ai-id',
  SET_PACKET_LOSS: 'set-packet-loss',
  SET_VAD_MODE: 'set-vad-mode',
  SET_VAD_THRESHOLD: 'set-vad-threshold',
});
