import Audio from '../../../../../audio/Audio';
import LibopusDecoder from './LibopusDecoder';
import AudioBufferSource from './AudioBufferSource';
import Queue from './Queue';
import Jitter from './Jitter';
import convertRmsToDb from './convertRmsToDb';

export default class PlaybackQueue {
  /**
   * State machine to store and process animation and audio data.
   * Animation and audio is synchronized during processing.
   * A jitter state machine is also taken into account during data processing.
   * A virtual audio player is feed with audio data which is connected to a main
   * audio player..
   * @param {Object} params PlaybackQueue constructor params.
   * @param {Object} params.audioBufferSourceParams Params passed to audioBufferSource constructors.
   * @param {Function} params.onJitterChange Callback called when jitter value is changed
   */
  constructor({ audioBufferSourceParams, ...params }) {
    Object.assign(this, params);

    this.audioBufferSource = new AudioBufferSource(audioBufferSourceParams);

    this.libopusDecoder = new LibopusDecoder();
  }

  queue = new Queue();

  jitter = new Jitter({
    onChange: (params) => {
      this.emitJitterChange(params);
    },
  });

  audioBufferSource = null;

  libopusDecoder = null;

  audioCurrentTimeInit = null;

  animFrameCount = 0;

  lastAnimationData = null;

  lastPlayTime = null;

  dropLogThreshold = 100;

  droppedPackets = 0;

  lastLoggedDropCount = 0;

  logger = null;

  async init() {
    await this.libopusDecoder.init();
  }

  // Drop out of time and out of order packets.
  checkAudioPacketNovelty({ playTime }) {
    const { lastPlayTime, logger } = this;

    if (playTime <= lastPlayTime) {
      logger.warn('PlaybackQueue | Discarding out of order packet.');

      return false;
    }

    // Check and log how many packets were dropped since the current packet.
    if (playTime !== lastPlayTime + 20) {
      const lostPackets = (playTime - lastPlayTime) / 20 - 1;

      this.droppedPackets += lostPackets;

      if (this.droppedPackets - this.lastLoggedDropCount >= this.dropLogThreshold) {
        logger.debug(`PlaybackQueue | Summed packet drop count: ${this.droppedPackets}`);

        this.lastLoggedDropCount = this.droppedPackets;
      }
    }

    return true;
  }

  // Calculate actual play time based on PlaybackQueue start time and current jitter.
  calculatePlayTime(basePlayTime) {
    const { audioCurrentTimeInit, jitter } = this;

    return audioCurrentTimeInit + basePlayTime + jitter.value;
  }

  handleUnpackedFlatbuffer({ animationData, encodedAudioPacket }) {
    const { lastPlayTime, libopusDecoder, audioBufferSource, queue, logger } = this;
    const { currentTimeMs } = Audio;

    const upToDatePacket = this.checkAudioPacketNovelty(encodedAudioPacket);

    if (!upToDatePacket) {
      logger.warn('PlaybackQueue | Discarding out of order packet.');

      return;
    }

    // First packet sets playtime to now. Start play audio immediately.
    this.audioCurrentTimeInit ??= currentTimeMs - encodedAudioPacket.playTime;

    // Decode audio packet and creates FEC packets if needed.
    const audioPackets = libopusDecoder.decodeAudioPacket({ ...encodedAudioPacket, lastPlayTime });

    this.lastPlayTime = encodedAudioPacket.playTime;

    // Schedule FEC, normal audio frames and animation data.
    audioPackets.forEach((audioPacket, index) => {
      const { audioFrameTimeStamp, audioFrame } = audioPacket;
      const playTime = this.calculatePlayTime(audioFrameTimeStamp);

      // Do not play out of time packets.
      if (playTime <= currentTimeMs) {
        return;
      }

      // Schedule audio frame play.
      audioBufferSource.playAudioFrame({ audioFrame, playTime });

      // Schedule animation packet playTime to end of last audio frame.
      if (animationData && index === audioPackets.length - 1) {
        const audioFrameLengthMs = 20;
        const audioFrameEndTime = playTime + audioFrameLengthMs;

        queue.enqueue({
          playTime: audioFrameEndTime,
          ...animationData,
        });
      }
    });
  }

  // Gets up to date anim packet and removes obsolete packets.
  // Returns null if queue is empty or no new anim frame needs to be rendered at the current time.
  getSyncAnimation() {
    const { queue } = this;
    const { outputCurrentTimeMs } = Audio;

    let animationData = null;

    while (queue.getLength() > 0 && outputCurrentTimeMs > queue.peekNext().playTime) {
      animationData = queue.dequeue();
    }

    return animationData;
  }

  getAnimationData() {
    const { lastAnimationData, jitter, queue } = this;

    let animationData = this.getSyncAnimation();

    if (animationData) {
      this.lastAnimationData = animationData;

      this.animFrameCount += 1;

      const { animFrameCount } = this;

      if (animFrameCount > 10) {
        // Resume jitter calculation after stable packet procession starts.
        jitter.suspended = false;

        const audioDbLevel = convertRmsToDb(animationData.rmsv);
        const queueLength = queue.getLength();

        jitter.reduce({
          audioDbLevel,
          queueLength,
          animFrameCount,
        });
      }
    } else {
      const nextAnimationData = queue.peekNext();

      // Animation queue is empty. Increase jitter.
      if (!nextAnimationData) {
        jitter.increase();
      }

      animationData = lastAnimationData;
    }

    return animationData;
  }

  emitJitterChange(params) {
    this.onJitterChange(params);
  }

  dispose() {
    this.libopusDecoder.dispose();
  }
}
