export default class ModuleCommands {
  /**
   * Create "commands" module to trigger commands through an RRMS api.
   * @param {object} params Contain module params.
   * @param {string} params.data Module data.
   * @param {Function} params.onApiRequest API request with apiName and api params.
   * @param {Function} params.onCpiRequest CPI request with CPI payload param.
   */
  constructor(params) {
    Object.assign(this, params);
  }

  get list() {
    return this.data.commands;
  }

  async get() {
    return this.data.commands;
  }

  async trigger(commandId) {
    return this.onApiRequest({
      apiName: 'triggerCommand',
      params: { commandId },
    });
  }

  stopAllSpeech() {
    return this.onCpiRequest({
      TargetCPIM: 'TTS*',
      method: 'stop-all-speech',
      params: [],
    });
  }
}
