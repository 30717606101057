import RapportStatusCodes from './RapportStatusCodes';
import ErrorCode from './ErrorCode';
/**
 * map internal RWV error code to public RapportStatusCode
 * @param {ErrorCallback} code
 * @returns RapportStatusCode
 */
export default function mapErrorCode(code) {
  if (typeof code !== 'symbol') {
    return { code, message: '' };
  }

  switch (code) {
    case ErrorCode.SESSION_LIMIT_REACHED: {
      return RapportStatusCodes.RAPPORT_SESSION_LIMIT_REACHED;
    }
    case ErrorCode.SESSION_CONCURRENCY_LIMIT_REACHED: {
      return RapportStatusCodes.RAPPORT_SESSION_CONCURRENCY_LIMIT_REACHED;
    }
    case ErrorCode.FAILURE_SETUP_SESSION:
    case ErrorCode.FAILURE_CREATE_ROOM:
    case ErrorCode.FAILURE_CREATE_WEBGL_PLAYER:
      return RapportStatusCodes.RAPPORT_SETUP_SESSION_FAIL;
    case ErrorCode.FAILURE_JOIN_SESSION:
    case ErrorCode.FAILURE_JOIN_ROOM:
    case ErrorCode.FAILURE_GET_ROOM_INVITE:
      return RapportStatusCodes.RAPPORT_JOIN_SESSION_FAIL;
    case ErrorCode.FAILURE_MAX_ROOM:
      return RapportStatusCodes.RAPPORT_MAX_ROOMS;
    case ErrorCode.FAILURE_GET_MODEL:
      return RapportStatusCodes.RAPPORT_GET_MODEL_FAIL;
    case ErrorCode.FAILURE_HEARTBEAT:
      return RapportStatusCodes.RAPPORT_UNSTABLE_CONNECTION;
    case ErrorCode.NO_MIC_PERMISSION:
      return RapportStatusCodes.RAPPORT_NO_MIC_PERMISSION;
    case ErrorCode.NO_HEADPHONES:
      return RapportStatusCodes.RAPPORT_HEADPHONES;
    case ErrorCode.NO_RUNNING_AUDIO_CONTEXT:
      return RapportStatusCodes.RAPPORT_NO_RUNNING_AUDIO_CONTEXT;
    case ErrorCode.PC_CLOSED:
      return RapportStatusCodes.RAPPORT_PEER_CONNECTION_CLOSED;
    case ErrorCode.PC_FAILED:
      return RapportStatusCodes.RAPPORT_PEER_CONNECTION_FAILED;
    case ErrorCode.PC_DISCONNECTED:
      return RapportStatusCodes.RAPPORT_PEER_CONNECTION_DISCONNECTED;
    case ErrorCode.FAILURE_LOGOUT:
      return RapportStatusCodes.RAPPORT_UNABLE_TO_LOGOUT;
    case ErrorCode.FAILURE_LEAVE_ROOM:
      return RapportStatusCodes.RAPPORT_UNABLE_TO_LEAVE_ROOM;
    case ErrorCode.FAILURE_LEAVE_LOBBY:
      return RapportStatusCodes.RAPPORT_UNABLE_TO_LEAVE_LOBBY;
    case ErrorCode.FAILURE_NOT_CONNECTED:
      return RapportStatusCodes.RAPPORT_WAIT_FOR_SESSION_CONNECTED;
    case ErrorCode.NOT_A_NUMBER:
      return RapportStatusCodes.RAPPORT_NOT_A_NUMBER;
    case ErrorCode.NOT_A_STRING:
      return RapportStatusCodes.RAPPORT_NOT_A_STRING;
    case ErrorCode.NOT_A_POSITION:
      return RapportStatusCodes.RAPPORT_NOT_A_POSITION;
    case ErrorCode.NOT_A_2D_POSITION:
      return RapportStatusCodes.RAPPORT_NOT_A_2D_POSITION;
    case ErrorCode.NOT_A_2D_SIZE:
      return RapportStatusCodes.RAPPORT_NOT_A_2D_SIZE;
    case ErrorCode.FAILURE_PARSE_STRING:
      return RapportStatusCodes.RAPPORT_UNABLE_TO_PARSE_STRING;
    case ErrorCode.MAX_TIMEOUT_EXCEEDED:
      return RapportStatusCodes.RAPPORT_TIMEOUT_EXCEEDED;
    case ErrorCode.FAILURE_MALFORMED_SSML:
      return RapportStatusCodes.CPI_MALFORMED_SSML;
    case ErrorCode.CPI_UNKNOWN_ERROR:
      return RapportStatusCodes.CPI_UNKNOWN_ERROR;
    case ErrorCode.CPI_MAX_SESSION_TIMEOUT:
      return RapportStatusCodes.CPI_MAX_SESSION_TIMEOUT;
    case ErrorCode.CPI_ROOM_DELETED:
      return RapportStatusCodes.CPI_ROOM_DELETED;
    case ErrorCode.FAILURE_IAM_ROLE:
    case ErrorCode.FAILURE_BOT_KEY:
      return RapportStatusCodes.CPI_AUTHORIZATION_FAILURE;
    case ErrorCode.CPI_SET_AI_SESSION_FAILURE:
      return RapportStatusCodes.CPI_AI_SET_SESSION_ID_FAIL;
    case ErrorCode.FAILURE_WEBGL_CONTEXT:
    case ErrorCode.FAILURE_START_RENDERING:
      return RapportStatusCodes.RAPPORT_FAILURE_RENDERER;
    case ErrorCode.FAILURE_ADD_EFFECTS:
      return RapportStatusCodes.RAPPORT_FAILURE_ADD_EFFECTS;
    case ErrorCode.NO_WEBRTC_ANSWER:
      return RapportStatusCodes.RAPPORT_WEBRTC_FAILURE;
    case ErrorCode.CPI_UNKNOWN_SOURCE_MODULE:
      return RapportStatusCodes.CPI_UNKNOWN_MODULE;
    case ErrorCode.FAILURE_LIGHTS_CREATE:
      return RapportStatusCodes.RAPPORT_FAILURE_CREATE_LIGHT;
    case ErrorCode.FAILURE_LIGHTS_READ:
      return RapportStatusCodes.RAPPORT_FAILURE_READ_LIGHT;
    case ErrorCode.FAILURE_LIGHTS_UPDATE:
      return RapportStatusCodes.RAPPORT_FAILURE_UPDATE_LIGHT;
    case ErrorCode.FAILURE_LIGHTS_DELETE:
      return RapportStatusCodes.RAPPORT_FAILURE_DELETE_LIGHT;
    case ErrorCode.WASM_CACHING_FAILURE:
      return RapportStatusCodes.RAPPORT_UNABLE_TO_FETCH_WASM_FILE;
    case ErrorCode.FAILURE_SESSION_IN_PROGRESS:
      return RapportStatusCodes.RAPPORT_SESSION_IN_PROGRESS;
    case ErrorCode.FAILURE_SESSION_DISCONNECTED:
      return RapportStatusCodes.RAPPORT_SESSION_DISCONNECTED;
    case ErrorCode.UPXS_SIGNALING_CLOSED:
      return RapportStatusCodes.RAPPORT_UPXS_SIGNALING_CLOSED;
    case ErrorCode.UPXS_RTC_CLOSED:
      return RapportStatusCodes.RAPPORT_UPXS_RTC_CLOSED;
    case ErrorCode.UPXS_RENDERING_FAILURE:
      return RapportStatusCodes.RAPPORT_UPXS_RENDERING_FAILURE;
    case ErrorCode.MEETING_SESSION_START_FAILURE:
      return RapportStatusCodes.RAPPORT_MEETING_SESSION_START_FAILURE;
    case ErrorCode.MEETING_ROOM_SYNC_FAILURE:
      return RapportStatusCodes.RAPPORT_MEETING_ROOM_SYNC_FAILURE;
    case ErrorCode.INVALID_SESSION_TYPE:
      return RapportStatusCodes.RAPPORT_INVALID_SESSION_TYPE;
    default:
  }
  return RapportStatusCodes.RAPPORT_UNDEFINED;
}
