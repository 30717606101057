export default class Jitter {
  /**
   * Jitter state machine with min and max value, and methods to increase and reduce
   * based on predefined conditions.
   * @param {Object} params Jitter constructor params.
   * @param {Function} params.onChange Callback called with new and old jitter value on change.
   */
  constructor(params) {
    Object.assign(this, params);
  }

  value = 50;

  minValue = 50;

  maxValue = 1600;

  suspended = true;

  throttleTimer = null;

  increase() {
    const {
      suspended,
      throttleTimer,
      maxValue,
      value,
    } = this;

    // Only allow to increase value if not suspended.
    // Prevent the value too increase too rapidly.
    const jitterThrottled = throttleTimer + value > Date.now();

    if (suspended || jitterThrottled) {
      return;
    }

    const oldValue = value;
    const newValue = Math.min(maxValue, value * 2);

    this.value = newValue;
    this.throttleTimer = Date.now();

    this.emitChange({ value: newValue, oldValue });
  }

  reduce({ audioDbLevel, queueLength, animFrameCount }) {
    const {
      suspended,
      value,
      minValue,
    } = this;

    // Only allow to reduce value if value is not suspended,
    // the packet's audio only contains silence, and there are packets in the buffer.
    // Can't reduce value more then once every 30 animation frames.
    if (
      suspended ||
      audioDbLevel ||
      value === this.minValue ||
      queueLength < 2 ||
      animFrameCount % 30 !== 0
    ) {
      return;
    }

    const oldValue = value;
    const newValue = Math.max(minValue, value - 20);

    this.value = newValue;

    this.emitChange({ value: newValue, oldValue });
  }

  emitChange(params) {
    this.onChange(params);
  }
}
