import clamp from '../../utils/math/clamp';
import normalizeMousePos from '../../utils/normalizeMousePos';

const numberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 5,
});

export default class OrbitControlsUpxs {
  /**
   * Attaches gesture event listeners to container and
   * emits "cameraOrbit" string based on mouse movement.
   * Callback onCameraOrbit is called with "cameraOrbit" string.
   * @param {Object} params Contain orbit control parameters, and callback.
   * @param {boolean} params.enabled Allows to emit "cameraOrbit" string.
   * @param {Object} params.container Element to set mouse event listeners on.
   * @param {Function} params.onCameraOrbit Callback called with "cameraOrbit" string.
   */
  constructor(params) {
    Object.assign(this, params);

    this.addEventListeners();
  }

  enabled = true;

  container = null;

  onCameraOrbit = null;

  mouseDown = false;

  origX = null;

  origY = null;

  maxMouseMove = null;

  // 15 scans pre seconds.
  pointerScanFrequency = 66;

  lastPointerScanTime = Date.now();

  addEventListeners() {
    const { container } = this;

    container.addEventListener('mousedown', this);
    window.addEventListener('mouseup', this);
    window.addEventListener('pointermove', this);
    container.addEventListener('touchstart', this);
    window.addEventListener('touchend', this);
  }

  handleEvent(e) {
    switch (e.type) {
      case 'mousedown': {
        e.preventDefault();
        this.handleMouseDown(e);
        break;
      }

      case 'mouseup': {
        this.handleMouseUp(e);
        break;
      }

      case 'touchstart': {
        e.preventDefault();
        this.handleMouseDown(e);
        break;
      }

      case 'touchend': {
        this.handleMouseUp(e);
        break;
      }

      case 'pointermove': {
        this.handlePointerMove(e);
        break;
      }

      default:
        break;
    }
  }

  handleMouseDown(e) {
    const { enabled } = this;

    if (!enabled) {
      return;
    }

    this.mouseDown = true;

    const { x: mousePosX, y: mousePosY } = normalizeMousePos(e);

    this.origX = mousePosX;
    this.origY = mousePosY;

    const { offsetWidth, offsetHeight } = this.container;

    // The maxMouseMove equals to container smaller side.
    this.maxMouseMove = offsetWidth < offsetHeight ? offsetWidth : offsetHeight;

    const unitVectors = this.getUnitVectors(e);

    this.emitCameraOrbit({ ...unitVectors, mouseButtonEvent: 1 });
  }

  handleMouseUp(e) {
    const { enabled, mouseDown } = this;

    if (!enabled || !mouseDown) {
      return;
    }

    this.mouseDown = false;

    const unitVectors = this.getUnitVectors(e);

    this.emitCameraOrbit({ ...unitVectors, mouseButtonEvent: 2 });
  }

  handlePointerMove(e) {
    const { enabled, mouseDown, pointerScanFrequency, lastPointerScanTime } = this;

    if (!enabled || !mouseDown) {
      return;
    }

    // Throttle handling pointer move.
    const now = Date.now();

    if (now - lastPointerScanTime < pointerScanFrequency) {
      return;
    }

    this.lastPointerScanTime = now;

    const unitVectors = this.getUnitVectors(e);

    this.emitCameraOrbit({ ...unitVectors, mouseButtonEvent: 0 });
  }

  getUnitVectors(e) {
    const { origX, origY, maxMouseMove } = this;

    // Mouse screen position.
    const { x: mousePosX, y: mousePosY } = normalizeMousePos(e);

    const unitX = numberFormat.format(clamp((mousePosX - origX) / maxMouseMove, -1, 1));
    const unitY = numberFormat.format(clamp((mousePosY - origY) / maxMouseMove, -1, 1) * -1);

    return { unitX, unitY };
  }

  // mouseButtonEvent: 0=none 1=mousedown, 2=mouseup
  emitCameraOrbit({ unitX, unitY, mouseButtonEvent }) {
    this.onCameraOrbit(`cameraOrbit: ${unitX};${unitY};${mouseButtonEvent}`);
  }

  dispose() {
    this.removeEventListeners();
  }

  removeEventListeners() {
    const { container } = this;

    container.removeEventListener('mousedown', this);
    window.removeEventListener('mouseup', this);
    container.removeEventListener('touchstart', this);
    window.removeEventListener('touchend', this);
    window.removeEventListener('pointermove', this);
  }
}
