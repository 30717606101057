export default Object.freeze({
  RAPPORT_NO_MIC_PERMISSION: {
    code: 'RAPPORT_NO_MIC_PERMISSION',
    message: 'PERMISSION DENIED: User did not grant microphone access.',
  },
  RAPPORT_HEADPHONES: {
    code: 'RAPPORT_HEADPHONES',
    message: 'Unable to establish acoustic echo cancellation. Please use headphones.',
  },
  RAPPORT_UNDEFINED: {
    code: 'RAPPORT_UNDEFINED',
    message: 'An undefined error has occurred',
  },
  RAPPORT_MAX_ROOMS: {
    code: 'RAPPORT_MAX_ROOMS',
    message: 'You have reached your maximum rooms allowance',
  },
  RAPPORT_NO_RUNNING_AUDIO_CONTEXT: {
    code: 'RAPPORT_NO_RUNNING_AUDIO_CONTEXT',
    message: 'Audio context will be resumed after page gesture',
  },
  RAPPORT_PEER_CONNECTION_FAILED: {
    code: 'RAPPORT_PEER_CONNECTION_FAILED',
    message: 'WebRTC peer connection failed',
  },
  RAPPORT_PEER_CONNECTION_CLOSED: {
    code: 'RAPPORT_PEER_CONNECTION_CLOSED',
    message: 'WebRTC peer connection closed',
  },
  RAPPORT_PEER_CONNECTION_DISCONNECTED: {
    code: 'RAPPORT_PEER_CONNECTION_DISCONNECTED',
    message: 'WebRTC peer connection disconnected',
  },
  RAPPORT_CPI_COMMUNICATIONS: {
    code: 'RAPPORT_CPI_COMMUNICATIONS',
    message: 'Receiving messages from CPI',
  },
  RAPPORT_CPI_COMMUNICATIONS_FAILED: {
    code: 'RAPPORT_CPI_COMMUNICATIONS_FAILED',
    message: 'Unable to communicate with CPI Tower',
  },
  RAPPORT_GET_MODEL_FAIL: {
    code: 'RAPPORT_GET_MODEL_FAIL',
    message: 'Failed to get character model',
  },
  RAPPORT_SESSION_LIMIT_REACHED: {
    code: 'RAPPORT_SESSION_LIMIT_REACHED',
    message: 'Session limit reached.',
  },
  RAPPORT_SESSION_CONCURRENCY_LIMIT_REACHED: {
    code: 'RAPPORT_SESSION_CONCURRENCY_LIMIT_REACHED',
    message: 'Session concurrency limit reached.',
  },
  RAPPORT_SETUP_SESSION_FAIL: {
    code: 'RAPPORT_SETUP_SESSION_FAIL',
    message: 'Failed to setup session.',
  },
  RAPPORT_JOIN_SESSION_FAIL: {
    code: 'RAPPORT_JOIN_SESSION_FAIL',
    message: 'Failed to join session.',
  },
  RAPPORT_UNSTABLE_CONNECTION: {
    code: 'RAPPORT_UNSTABLE_CONNECTION',
    message: 'The network connection is unstable',
  },
  RAPPORT_TIMEOUT: {
    code: 'RAPPORT_TIMEOUT',
    message: 'The rapport session has timed out',
  },
  RAPPORT_INACTIVITY_TIMEOUT: {
    code: 'RAPPORT_INACTIVITY_TIMEOUT',
    message: 'The rapport session has timed out due to inactivity',
  },
  RAPPORT_UNABLE_TO_LOGOUT: {
    code: 'RAPPORT_UNABLE_TO_LOGOUT',
    message: 'Unable to log out of rapport session',
  },
  RAPPORT_UNABLE_TO_LEAVE_LOBBY: {
    code: 'RAPPORT_UNABLE_TO_LEAVE_LOBBY',
    message: 'Unable to leave lobby of rapport session',
  },
  RAPPORT_UNABLE_TO_LEAVE_ROOM: {
    code: 'RAPPORT_UNABLE_TO_LEAVE_ROOM',
    message: 'Unable to leave room of rapport session',
  },
  RAPPORT_NOT_A_NUMBER: {
    code: 'RAPPORT_NOT_A_NUMBER',
    message: 'Parameter needs to be a number',
  },
  RAPPORT_WAIT_FOR_SESSION_CONNECTED: {
    code: 'RAPPORT_WAIT_FOR_SESSION_CONNECTED',
    message: 'Please wait for sessionConnected after calling sessionRequest',
  },
  RAPPORT_NOT_A_STRING: {
    code: 'RAPPORT_NOT_A_STRING',
    message: 'Parameter needs to be a string',
  },
  RAPPORT_NOT_A_POSITION: {
    code: 'RAPPORT_NOT_A_POSITION',
    message: 'Parameter needs to be a position object, requires  {x, y, z} format',
  },
  RAPPORT_NOT_A_2D_POSITION: {
    code: 'RAPPORT_NOT_A_2D_POSITION',
    message: 'Parameter needs to be a position object, requires  {x, y} format',
  },
  RAPPORT_NOT_A_2D_SIZE: {
    code: 'NOT_A_2D_SIZE',
    message: 'Parameter needs to be a size object, requires  {width, height} format',
  },
  RAPPORT_TIMEOUT_EXCEEDED: {
    code: 'RAPPORT_TIMEOUT_EXCEEDED',
    message: 'Timeout attribute cannot exceed account max duration',
  },
  RAPPORT_FAILURE_RENDERER: {
    code: 'RAPPORT_FAILURE_RENDERER',
    message: 'Failed to start rendering',
  },
  RAPPORT_FAILURE_ADD_EFFECTS: {
    code: 'RAPPORT_FAILURE_ADD_EFFECTS',
    message: 'Unable to add effects to renderer',
  },
  RAPPORT_WEBRTC_FAILURE: {
    code: 'RAPPORT_WEBRTC_FAILURE',
    message: 'Failed to start WebRTC connection to Rapport Cloud',
  },
  RAPPORT_UNABLE_TO_PARSE_STRING: {
    code: 'RAPPORT_UNABLE_TO_PARSE_STRING',
    message: 'Unable to parse string into JSON object using JSON.parse',
  },
  RAPPORT_SESSION_IN_PROGRESS: {
    code: 'RAPPORT_SESSION_IN_PROGRESS',
    message: 'Session in progress. Please await previous sessionDisconnect',
  },
  CPI_MALFORMED_SSML: {
    code: 'CPI_MALFORMED_SSML',
    message: 'CPI has received a malformed SSML request',
  },
  CPI_UNKNOWN_ERROR: {
    code: 'CPI_UNKNOWN_ERROR',
    message: 'An unknown CPI error has occurred',
  },
  CPI_MAX_SESSION_TIMEOUT: {
    code: 'CPI_MAX_SESSION_TIMEOUT',
    message: 'CPI has timed out on max session duration',
  },
  CPI_ROOM_DELETED: {
    code: 'CPI_ROOM_DELETED',
    message: 'CPI has deleted room',
  },
  CPI_AUTHORIZATION_FAILURE: {
    code: 'CPI_AUTHORIZATION_FAILURE',
    message: 'CPI has unauthorized AI credentials',
  },
  CPI_AI_SET_SESSION_ID_FAIL: {
    code: 'CPI_AI_SET_SESSION_ID_FAIL',
    message: 'Unable to set AI session id',
  },
  CPI_UNKNOWN_MODULE: {
    code: 'CPI_UNKNOWN_MODULE',
    message: 'Received message for unknown module',
  },
  RAPPORT_FAILURE_CREATE_LIGHT: {
    code: 'RAPPORT_FAILURE_CREATE_LIGHT',
    message: 'Failed to create light',
  },
  RAPPORT_FAILURE_READ_LIGHT: {
    code: 'RAPPORT_FAILURE_READ_LIGHT',
    message: 'Failed to read light',
  },
  RAPPORT_FAILURE_UPDATE_LIGHT: {
    code: 'RAPPORT_FAILURE_UPDATE_LIGHT',
    message: 'Failed to update light',
  },
  RAPPORT_FAILURE_DELETE_LIGHT: {
    code: 'RAPPORT_FAILURE_DELETE_LIGHT',
    message: 'Failed to delete light',
  },
  RAPPORT_UNABLE_TO_FETCH_WASM_FILE: {
    code: 'RAPPORT_UNABLE_TO_FETCH_WASM_FILE',
    message: 'Unable to fetch wasm file',
  },
  RAPPORT_SESSION_DISCONNECTED: {
    code: 'RAPPORT_SESSION_DISCONNECTED',
    message: 'The session was terminated by calling sessionDisconnect',
  },
  RAPPORT_UPXS_SIGNALING_CLOSED: {
    code: 'RAPPORT_UPXS_SIGNALING_CLOSED',
    message: 'UPXS signaling connection closed',
  },
  RAPPORT_UPXS_RTC_CLOSED: {
    code: 'RAPPORT_UPXS_RTC_CLOSED',
    message: 'UPXS RTC connection closed',
  },
  RAPPORT_UPXS_RENDERING_FAILURE: {
    code: 'RAPPORT_UPXS_RENDERING_FAILURE',
    message: 'UPXS rendering initialization failed',
  },
  RAPPORT_MEETING_SESSION_START_FAILURE: {
    code: 'RAPPORT_MEETING_SESSION_START_FAILURE',
    message: 'Meeting session start failed',
  },
  RAPPORT_MEETING_ROOM_SYNC_FAILURE: {
    code: 'RAPPORT_MEETING_ROOM_SYNC_FAILURE',
    message: 'Meeting room synchronization failed',
  },
  RAPPORT_INVALID_SESSION_TYPE: {
    code: 'RAPPORT_INVALID_SESSION_TYPE',
    message: 'Invalid session type',
  },
});
