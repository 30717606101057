import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const loader = new GLTFLoader();

export default function loadGlb(url) {
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      (glb) => resolve(glb),
      () => {},
      (error) => reject(error),
    );
  });
}
