// kebab-case rapport-scene attributes we listen for changes
export default Object.freeze({
  BACKGROUND: 'background',
  ORBITAL_CONTROLS: 'orbital-controls',
  OC_ANGLE: 'oc-angle',
  OC_ZOOM: 'oc-zoom',
  CAMERA_POSITION: 'camera-position',
  CAMERA_LOOK_AT: 'camera-look-at',
  MODEL_LOOK_AT: 'model-look-at',
  FORCE_DEFAULT_CAMERA: 'force-default-camera',
  SIGNAL_INDICATOR: 'signal-indicator',
});
